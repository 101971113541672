<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Calendar</strong></h1>
			</div>
		</div>

		<!-- <div id='container'>
            <ejs-schedule height='550px' width='100%' :selectedDate='selectedDate' :eventSettings='eventSettings'>
                <e-views>
                    <e-view option='Month' showWeekNumber=true readonly=true ></e-view>
                </e-views>
            </ejs-schedule>
        </div> -->

		<div class="row mt-5 justify-content-center">
			<div class="col-12 col-lg-8">
				<table class="table table-striped table-warning">
					<thead>
						<tr>
						<th scope="col">#</th>
						<th scope="col">Month</th>
						<th scope="col">Event</th>
						
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in events" :key="item.number">
							<th scope="row">{{item.number}}</th>
							<td>{{item.month}}</td>
							<td>{{item.event}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</section>




</div>

  
  
</template>

<script>

import {SchedulePlugin, Month} from "@syncfusion/ej2-vue-schedule";

eventsData: [
				{Id: 1, month : 'January', Subject: 'Pongal Celebration',StartTime: new Date(2024, 1, 14, 0, 0),EndTime: new Date(2024, 1, 15, 0, 0)},
				{Id: 2, month : 'March', Subject: 'Youth Leadership',StartTime: new Date(2024, 3, 14, 0, 0),EndTime: new Date(2024, 3, 15, 0, 0)},
				{Id: 3, month : 'April', Subject: 'Tamil New Year Event',StartTime: new Date(2024, 4, 14, 0, 0),EndTime: new Date(2024, 4, 15, 0, 0)},
				{Id: 4, month : 'May', Subject: 'Tamil School Annual Day',StartTime: new Date(2024, 5, 14, 0, 0),EndTime: new Date(2024, 5, 15, 0, 0)},
				{Id: 5, month : 'May', Subject: 'Women’s Day Celebration',StartTime: new Date(2024, 5, 14, 0, 0),EndTime: new Date(2024, 5, 15, 0, 0)},
				{Id: 6, month : 'August', Subject: 'Nila Choru',StartTime: new Date(2024, 8, 15, 0, 0),EndTime: new Date(2024, 8, 16, 0, 0)},
				{Id: 7, month : 'September', Subject: 'Talent Show ',StartTime: new Date(2024, 9, 14, 0, 0),EndTime: new Date(2024, 9, 15, 0, 0)},
				{Id: 8, month : 'October', Subject: 'Celebrity Show',StartTime: new Date(2024, 10, 14, 0, 0),EndTime: new Date(2024, 10, 15, 0, 0)},
				{Id: 9, month : 'November', Subject: 'Volley ball/ Throw ball tournament',StartTime: new Date(2024, 11, 14, 0, 0),EndTime: new Date(2024, 11, 15, 0, 0)},
			];

export default {
    name: "activity",
    components: {},
    data() {
        return {
			
			events: [
				{number: 1, month : 'January', event: 'Pongal Celebration'},
				{number: 2, month : 'March', event: 'Youth Leadership'},
				{number: 3, month : 'April', event: 'Tamil New Year Event'},
				{number: 4, month : 'May', event: 'Tamil School Annual Day'},
				{number: 5, month : 'May', event: 'Women’s Day Celebration'},
				{number: 6, month : 'August', event: 'Nila Choru'},
				{number: 7, month : 'September', event: 'Talent Show '},
				{number: 8, month : 'October', event: 'Celebrity Show'},
				{number: 9, month : 'November', event: 'Volley ball/ Throw ball tournament'},
			],
			selectedDate: new Date(),
			// eventSettings: { dataSource: eventsData },
        
        };
    },
    methods: {
    },
	provide: {
		schedule: [Month]
	},
};

</script>
<style>

.table-warning{
	--bs-table-striped-bg: #c0ae5c !important;
}

</style>