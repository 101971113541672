<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.2" class="header5 cid-u7ancmRRnP" id="header05-1x">
	
			<iframe width="100%" src="https://tamilsneham.smugmug.com/browse/" title="description"></iframe>
	</section>




</div>

  
  
</template>

<script>

export default {
    name: "gallery",
    components: {},
    data() {
        return {
        
        };
    },
    methods: {
    }
};

</script>