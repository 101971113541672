<template>
    <header class="header-global">
  <nav class="navbar navbar-expand-lg fixed-top bg-body-tertiary d-flex justify-content-between" style="background-color: #e3f2fd !important;padding-top:0px;padding-bottom: 0px;">
  <!-- <div class="" style="display: flex;"> -->
            <div class="navbar-brand" style="display: flex;flex-wrap: nowrap;">
                <span class="navbar-logo" >
                  <a>
                    <img src="assets/images/sneham-logo.png" style="height: 5rem;">
                  </a>
                </span>
                <div>
                    <span class="title" style="font-weight: 900;">தமிழ் சிநேகம்</span><br/>
                    <span class="title" style="font-weight: 900;">Tamil Sneham</span><br/>
                    <span class="regTextSM" style="font-size: small;">(A Registered, Non-Profit, Tax-Exempt 501(c)(3) Organization)</span>
                    <span class="regTextXSM" style="font-size: small;">(A Registered, Non-Profit,<br/> Tax-Exempt 501(c)(3) Organization)</span>
                </div>
                
                   
                <!-- <a class="navbar-brand" href="#" style="font-size: xxx-large;font-weight: 700;">தமிழ் சிநேகம்</a> -->
            </div>
          
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav" style="">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <!-- <router-link to="/Home" class="nav-link">Home</router-link> -->
                  <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/Home')">Home</span>
                  <!-- <a class="nav-link active" aria-current="page" href="#">Home</a> -->
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Member
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <!-- <li>
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/Benefits')">Membership Benefits</span>
                    </li> -->
                    <li>
                      <!-- <router-link to="/MembershipRegistration" class="nav-link">Membership Registration</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/MembershipRegistration')">Membership Registration</span>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a href="https://tamilsneham.smugmug.com/browse/" class="nav-link">Gallery</a>
                </li>
                <!-- <li class="nav-item">
                  <router-link to="/Home/#Team" class="nav-link">Team</router-link>
                </li> -->
                <li class="nav-item">
                  <!-- <router-link to="/TamilSchool" class="nav-link">TamilSchool</router-link> -->
                  <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/TamilSchool')">TamilSchool</span>
                </li>
                <li class="nav-item">
                  <!-- <router-link to="/Event" class="nav-link">Event/RSVP</router-link> -->
                  <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/Event')">Events/RSVP</span>
                </li> 
                <li class="nav-item">
                  <!-- <router-link to="/Calendar" class="nav-link" data-bs-toggle="collapse" data-bs-target="#navbarNav">Calendar</router-link> -->
                  <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/Calendar')">Calendar</span>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    About
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <!-- <router-link to="/AboutUs" class="nav-link">Who we are!</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/AboutUs')">Who we are!</span>
                    </li>
                    <li>
                      <!-- <router-link to="/BoardMembers" class="nav-link">Board Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/BoardMembers')">Board Members</span>
                    </li>
                    <li>
                      <!-- <router-link to="/ExecutiveMembers" class="nav-link">Executive Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/ExecutiveMembers')">Executive Members</span>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <!-- <router-link to="/Home/#Contact" class="nav-link">Contact</router-link> -->
                  <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/Home/#Contact')">Contact</span>
                </li> 
                <li v-if="user && (isAdmin || scanAdmin)" class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Admin
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
                    <li v-if="user && isAdmin">
                      <!-- <router-link to="/AboutUs" class="nav-link">Who we are!</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/RSVPList')">RSVP List</span>
                    </li>
                    <li v-if="user && isAdmin">
                      <!-- <router-link to="/BoardMembers" class="nav-link">Board Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/RSVPRegistrationList')">RSVP Reg List</span>
                    </li>
                    <li v-if="user && isAdmin">
                      <!-- <router-link to="/ExecutiveMembers" class="nav-link">Executive Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/SchoolRegistrationList')">School Reg List</span>
                    </li>
                    <li v-if="user && isAdmin">
                      <!-- <router-link to="/ExecutiveMembers" class="nav-link">Executive Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/MembershipList')">Member List</span>
                    </li>
                    <li v-if="user && (isAdmin || scanAdmin)">
                      <!-- <router-link to="/ExecutiveMembers" class="nav-link">Executive Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="route('/EventEntry')">Event Entry</span>
                    </li>
                    <li v-if="user && isAdmin">
                      <!-- <router-link to="/ExecutiveMembers" class="nav-link">Executive Members</router-link> -->
                      <span class="nav-link" style="cursor: pointer;"  data-bs-toggle="collapse" data-bs-target="#navbarNav" @click="logout">Logout</span>
                    </li>
                   
                  </ul>
                </li>
              </ul>
            </div>
  <!-- </div> -->
</nav>
     

        
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import { register } from 'swiper/element/bundle';

import firebase from '../Firebase.js'
import {Vue, ref} from "vue";

register();

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  data() {
    return {
      user: null,
      isAdmin:false,
      scanAdmin:false,
    };
  },
  created() {

    // firebase.auth().signOut().then(() => {
    //     this.user = null;
    //     this.$router.push('/Home');
    //   }).catch((error) => {
    //     console.error(error);
    //   });

    firebase.auth().onAuthStateChanged(async (user) => {

    if(user){
   
        this.user = user;

        await firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
            const userData = doc.data();
            if(userData){
              this.isAdmin = userData.admin;
            }
            

            try{
              if(userData){
                this.scanAdmin = userData.scanadmin
              }
                
            }
            catch(e){

            }

        }).catch((error) => {
            console.error('Error fetching user data:', error);
        });

        }
        
    });
    
  },
  methods: {
    logout() {
      console.log('Logout 1');
      firebase.auth().signOut().then(() => {
        this.user = null;
        console.log('Logout 2');
        this.$router.push('/Home');
      }).catch((error) => {
        console.error(error);
      });
    },
    scrolls(el){
        console.log('Scroll method');
        this.$refs.About.scrollIntoView({ behavior: 'smooth' });
        // document.getElementById(el).scrollIntoView();
    },
    route(url){
      this.$router.push(url);
    }
  },
};
</script>
<style>

.topBar .container{
    max-width: 100% !important;
}

.navbar-brand img {
    height: 100px !important;
}

@media (max-width: 576px) {
  .nav-item .dropdown-menu{
    background-color: transparent;
    border: none;
    padding-left: 35px;
  }

  .nav-item .dropdown-menu .nav-link{
    color: darkgrey;
  }
}
</style>
