<script setup>


</script>

<template>
   <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
            <div class="container pt-lg-md" style="margin-top: 60px;">
                <div class="row">
                    <div class="col-lg-10">
                        <card shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0">

                            <template>
                                <div>
                                    <h3 style="text-align: center;">Event Entry</h3>
                                    <div style="text-align: center;">
                                        <label style="margin-left: 15px;">Select Event<span>*</span></label>
                                      <!-- <base-dropdown class="form-group mb-0 input-group col-lg-6" >
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly required placeholder="Select Event"
                                            labelClasses="col-lg-12" style="background-color: white;" form-control id="rsvp" v-model="selectedRSVPName">
                                            </base-input>
                                            <div class="form-group col-lg-12" v-for="rsvp in rsvpList" :key="rsvp.id" @click="setRSVP(rsvp.name,rsvp.rsvpId)"><a>{{rsvp.name}}</a></div>
                                        </base-dropdown> -->

                                        <div class="input-group mb-3 col-lg-3">
                                        <select class="form-select" ref="rsvpSelect" id="rsvpSelect" aria-label="" v-model="selectedRSVPObject" @change="setRSVP($event)">
                                            <option selected></option>
                                            <option v-for="rsvp in rsvpList" :key="rsvp.id" :value="rsvp.name + '-' + rsvp.rsvpId" >{{ rsvp.name }}</option>
                                        </select>
                                    </div>


                                    </div>
                                  
                                    <h3 style="text-align: center;">{{this.selectedRSVPName}}</h3>
                                   <div id="reader" width="600px"></div>

                                   <div style="text-align: center;">
                                        <base-button v-if="!scanStarted" style="text-align: center;" type="submit" @click="startScan" class="my-4">Start scan</base-button>
                                        <base-button v-if="scanStarted" style="text-align: center;" type="submit" @click="stopScan" class="my-4">Stop scan</base-button>
                                   </div>
                                   

                                   <h3 v-if="validEntry" style="color: green;text-align: center;">Entry Success</h3>
                                   <h3 v-if="!validEntry" style="color: red;text-align: center;">{{errorMessage}}</h3>

                                   <h4 v-if="validEntry" style="color: blue;text-align: center;">{{registration.firstName}} {{registration.lastName}}</h4>
                                   <h4 v-if="validEntry" style="color: blue;text-align: center;">Total Adults - {{registration.adults  }}</h4>
                                   <h4 v-if="validEntry" style="color: blue;text-align: center;">Total Kids - {{ registration.kids }}</h4>

                                   <h4 v-if="validMember" style="color: blue;text-align: center;">{{memberObject.fname}} {{memberObject.lname}}</h4>
                                   <h4 v-if="validMember" style="color: blue;text-align: center;">Total Family member - {{memberObject.familyMembers}}</h4>
                                   <h4 v-if="validMember" style="color: blue;text-align: center;">Status - {{ memberObject.status }}</h4>

                                   <!-- <base-dropdown v-if="validEntry" class="form-group mb-0 input-group">
                                        <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="No of adults"
                                            addon-left-icon="ni  ni-single-02" style="background-color: white;" id="adults" v-model="adults">
                                        </base-input>
                                        <a class="dropdown-item" href="#" @click="setAdults(0)">0</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(1)">1</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(2)">2</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(3)">3</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(4)">4</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(5)">5</a>
                                        <a class="dropdown-item" href="#" @click="setAdults(6)">6</a>
                                    </base-dropdown>
                                    <base-dropdown v-if="validEntry" class="form-group mb-0 input-group">
                                        <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="No of kids"
                                            addon-left-icon="ni ni-single-02" style="background-color: white;" id="kids" v-model="kids">
                                        </base-input>
                                        <a class="dropdown-item" href="#" @click="setKids(0)">0</a>
                                        <a class="dropdown-item" href="#" @click="setKids(1)">1</a>
                                        <a class="dropdown-item" href="#" @click="setKids(2)">2</a>
                                        <a class="dropdown-item" href="#" @click="setKids(3)">3</a>
                                        <a class="dropdown-item" href="#" @click="setKids(4)">4</a>
                                        <a class="dropdown-item" href="#" @click="setKids(5)">5</a>
                                        <a class="dropdown-item" href="#" @click="setKids(6)">6</a>
                                    </base-dropdown> -->

                                    <div style="text-align: center;">
                                        <base-button type="submit" @click="setTotalEntry" class="my-4">Total Entry</base-button>
                                    </div>

                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </div>

        </section>
</template>

<script>

import { Timestamp } from '@google-cloud/firestore';
import firebase from '../Firebase.js'
import {Html5Qrcode} from "html5-qrcode";

let html5QrCode = null;

export default {
    
    data() {
        return {
            rsvpList:[],
            registration:{},
            memberObject:{},
            registrationId:'',
            selectedRSVPName:'',
            selectedRSVPId:'',
            validEntry:false,
            adults:'',
            kids:'',
            errorMessage:'',
            scanStarted:false,
            rsvp:{},
            membership: false,
            validMember: false,
        };
    },
    methods: {
        async loadRsvps(){

            const db = firebase.firestore();
            
            await db.collection('rsvp')
                .where("eventDateTimestamp", ">=", firebase.firestore.Timestamp.fromDate(new Date(this.getNow(true))))
                .get()
                .then(docs => {
                    docs.forEach(doc => {
                        const rsvp = {
                            rsvpId: doc.id,
                            ...doc.data()
                        };
                        this.rsvpList.push(rsvp);
                    });

                    let membership = {"name":"Member Validation","rsvpId": "1000", "id":"1000"};

                    this.rsvpList.push(membership); 
                })
                .catch(error => {
                    console.error('Error fetching RSVP items:', error);
                });

        },
        setAdults(adults){
            this.adults = adults;
        },
        setKids(kids){
            this.kids = kids;
        },
        setRSVP(event){

            let value = event.currentTarget.selectedOptions[0].value;
            let name = event.currentTarget.selectedOptions[0].text;

            console.log(value, name);

            let item = value.split('-');

            this.membership = false;
            this.selectedRSVPName = name;
            this.selectedRSVPId = item[1];
           
            this.errorMessage = '';

            if(this.selectedRSVPId == "1000"){
                this.membership = true;
            }
        },
        startScan() {

            this.errorMessage = '';
            this.validEntry= false;
            this.validMember = false;
            this.registration = '';
            

            const qrCodeSuccessCallback = (decodedText, decodedResult) => {
                /* handle success */
                console.log(decodedText);
                console.log(decodedResult);

                this.entryConfirmation(decodedText);

                this.stopScan();
            };
            const config = { fps: 10, qrbox: { width: 250, height: 250 } };

           // Select back camera or fail with `OverconstrainedError`.
            html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);

            this.scanStarted = true;
        },
        stopScan(){

            this.scanStarted = false;
            html5QrCode.stop().then((ignore) => {
            // QR Code scanning is stopped.
            }).catch((err) => {
            // Stop failed, handle it.
            });

        },
        async entryConfirmation(decodedText){

            const db = firebase.firestore();

            this.registrationId = atob(decodedText);

            console.log(this.registrationId);

            this.validEntry= false;
            this.validMember = false;

            if(this.membership){

                await db.collection('members')
                .doc(this.registrationId)
                .get()
                .then(doc => {
                if (doc.exists) {
                    this.memberObject = { id: doc.id, ...doc.data() };

                    if(this.memberObject != null){
                        this.validMember = true;
                    }
                    else{
                        this.validMember = false;
                        this.errorMessage = 'The membership does not exists !!!';
                    }
                   
                } else {
                    console.log('Registration not found');
                    this.errorMessage = 'Invalid Member QR Code !!!';
                }
                })
                .catch(error => {
                    console.error('Error fetching Registration item:', error);
                    this.errorMessage = 'There is some issue with the QR Code !!!';
                });

            }
            else{
           
            await db.collection('RSVPRegistration')
                .doc(this.registrationId)
                .get()
                .then(doc => {
                if (doc.exists) {
                    this.registration = { id: doc.id, ...doc.data() };

                    if(this.registration.entry == true){
                        this.validEntry = false;
                        this.errorMessage = 'QR Code scanned already !!!';
                        return;
                    }

                    if(this.registration.rsvpId == this.selectedRSVPId){
                        //Success
                        this.validEntry = true;
                        this.errorMessage = '';

                        this.saveEntry();
                    }
                    else{
                        //Failure
                        this.validEntry = false;
                        this.errorMessage = 'Invalid QR Code !!!';
                    }
                } else {
                    console.log('Registration not found');
                    this.errorMessage = 'Invalid QR Code !!!';
                }
                })
                .catch(error => {
                    console.error('Error fetching Registration item:', error);
                    this.errorMessage = 'There is some issue with the QR Code !!!';
                });
            }

        },
        async saveEntry(){

            const db = firebase.firestore();

            const reg =  await db.collection('RSVPRegistration').doc(this.registrationId).update({
                   entry: true,
                   adultEntry:this.registration.adults,
                   kidsEntry:this.registration.kids,
                   entryTime: this.getNow(),
                });

                
                //this.validEntry=false;
                this.scanStarted = false;

                this.setTotalEntry();

                // await db.collection('rsvp')
                // .doc(this.selectedRSVPId)
                // .get()
                // .then(doc => {
                // if (doc.exists) {
                //     this.rsvp = { id: doc.id, ...doc.data() };
                // } else {
                //     console.log('RSVP item not found');
                // }
                // })
                // .catch(error => {
                //     console.error('Error fetching RSVP item:', error);
                // });


                // if(this.rsvp.totalEntry){
                //     this.rsvp.totalEntry++;
                // }
                // else{
                //     this.rsvp.totalEntry = 1;
                // }

                // if(this.rsvp.totalAdultsEntry){
                //     this.rsvp.totalAdultsEntry += this.registration.adults;
                // }
                // else{
                //     this.rsvp.totalAdultsEntry = this.registration.adults;
                // }

                // if(this.rsvp.totalKidsEntry){
                //     this.rsvp.totalKidsEntry += this.registration.kids;
                // }
                // else{
                //     this.rsvp.totalKidsEntry = this.registration.kids;
                // }

                // await db.collection('rsvp')
                //     .doc(this.rsvp.id)
                //     .update(this.rsvp)
                //     .then(() => {
                //         console.log('RSVP updated successfully!');
                //     })
                //     .catch(error => {
                //         console.error('Error updating RSVP:', error);
                //     });

            // this.registration = '';

        },
        async setTotalEntry() {

                const db = firebase.firestore();

                try {

                // Query the members collection based on the email or phone number
                const querySnapshot = await db.collection('RSVPRegistration')
                    .where('rsvpId', '==', this.selectedRSVPId)
                    .where('entry', '==', true)
                    .get();

                if(!querySnapshot.empty){
                
                    let count = 0;
                    let totalAdults = 0;
                    let totalKids = 0;
                    // let totalMembers = 0;
                    // let totalNonMembers = 0;

                    querySnapshot.forEach(async doc => {
                    // Extract the RSVP data from each document
                        count++;

                        const rsvp = {
                            rsvpRegId: doc.id,
                            ...doc.data()
                            };
                        
                            totalAdults += rsvp.adults == "" ? 0 : parseInt(rsvp.adults);
                            totalKids += rsvp.kids == "" ? 0 : parseInt(rsvp.kids);
                            // rsvp.membershipId != '' ? totalMembers++ : totalNonMembers++; 
                        
                        });

                        this.rsvp.totalEntry = count;
                        this.rsvp.totalAdultsEntry = totalAdults;
                        this.rsvp.totalKidsEntry = totalKids;
                        // this.rsvp.totalMember = totalMembers;
                        // this.rsvp.totalNonMember = totalNonMembers;

                        await db.collection('rsvp')
                            .doc(this.selectedRSVPId)
                            .update(this.rsvp)
                            .then(() => {
                                console.log('RSVP updated successfully!');
                            })
                            .catch(error => {
                                console.error('Error updating RSVP:', error);
                            });
                    
                    return true;
                }
                else{
                
                    return false;
                }

                } catch (error) {
                    console.error('Error checking member registration:', error);
                    return false;
                    }
                },
        getNow(isDate){

            const today = new Date();
            const date = today.getFullYear()+'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+String(today.getDate()).padStart(2, '0');
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            
            if(isDate){
                let date1 = today.getFullYear()+'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+String(today.getDate() - 1).padStart(2, '0');
                return date1;
            }
            
            return dateTime;
        },
       
    
    },
    mounted() {
        console.log("Mounted");
        this.loadRsvps();
       
        html5QrCode = new Html5Qrcode("reader");
        },
    };
</script>

<style>

.form-control[readonly] {
    background-color: white !important;
}

</style>
