<template>
    <div style="margin-top: 100px;">


<section data-bs-version="5.1" class="header13 cid-u7ajnbBLV2" id="header13-1t">
  
    <div class="row justify-content-center">
      <div class="col-md-12 mb-5" style="text-align: center;">
        <h1 class="mbr-section-title mbr-fonts-style display-1"><strong>{{activity.activityName}}</strong></h1>
      </div>
    </div>

    <div class="container" v-html="activity.html">
     
    </div>
  
  
</section>
  <!-- <div class="align-center container">
    <div class="row justify-content-center">
      <div class="col-md-12 mb-5" style="text-align: center;">
        <h1 class="mbr-section-title mbr-fonts-style display-1"><strong></strong></h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <img src="assets/images/6.jpg"  title="">
      </div>
    </div>
  </div>
</section>

<section data-bs-version="5.1" class="article07 cid-u7ajyCZ90X" id="article07-1u">
  

  
  
  <div class="container">
    <div class="row justify-content-center">
      <div class="card col-md-12 col-lg-10">
        <div class="card-wrapper">
          <h3 class="card-title mbr-fonts-style mbr-white mt-3 mb-4 display-2">
            <strong>Brief:</strong>
          </h3>
          <div class="row card-box align-left">
            <div class="item features-without-image col-12">
              <div class="item-wrapper">
                <h4 class="mbr-section-subtitle mbr-fonts-style mb-3 display-5"></h4>
                <p class="mbr-text mbr-fonts-style display-7">To emphasize the importance of community service as a vehicle to "give back to the community", Tamil Sneham has formed a pool of young students and their parents who volunteer their time and talent to support local charities in their mission to help the poor and homeless in our community. Our volunteers work at Feeding America Tampa Bay regularly on the first Saturday of every month, helping the Charity to sort donated food and other items.
<br>
<br>This project helps fulfill a mission of the organization to make our children aware of the poor and homeless in our community and to help meet their need. They also earn school credits for community service required as part of their academic requirements, not to mention the camaraderie they enjoy with other volunteers with whom they develop friendships.
<br>
<br>
<br>For additional details :
<br>
<br>Contact Sundar :
<br>
<br>write to contact@tamilsneham.org</p>
              </div>
            </div>
            <div class="item features-without-image col-12">
              <div class="item-wrapper">
                <h4 class="mbr-section-subtitle mbr-fonts-style mb-3 display-5"></h4>
                <p class="mbr-text mbr-fonts-style display-7"></p>
              </div>
            </div>
            <div class="item features-without-image col-12">
              <div class="item-wrapper">
                <h4 class="mbr-section-subtitle mbr-fonts-style mb-3 display-5"></h4>
                <p class="mbr-text mbr-fonts-style display-7"></p>
              </div>
            </div>
            <div class="item features-without-image col-12">
              <div class="item-wrapper">
                <h4 class="mbr-section-subtitle mbr-fonts-style mb-3 display-5"></h4>
                <p class="mbr-text mbr-fonts-style display-7"></p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</section> -->

<section data-bs-version="5.1" class="gallery1 mbr-gallery cid-u7amulHHzm" id="gallery01-1w">
    
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 content-head">
                <div class="mb-5">
                    <h3 class="mbr-section-title mbr-fonts-style align-center m-0 display-2"><strong>Gallery</strong></h3>
                </div>
            </div>
        </div>
        
        <div class="row mbr-gallery">
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery01.jpg"  data-slide-to="0" data-bs-slide-to="0" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery02.jpg"  data-slide-to="1" data-bs-slide-to="1" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery03.jpg"  data-slide-to="2" data-bs-slide-to="2" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery04.jpg"  data-slide-to="3" data-bs-slide-to="3" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery10.jpg"  data-slide-to="4" data-bs-slide-to="4" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery05.jpg"  data-slide-to="5" data-bs-slide-to="5" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery06.jpg"  data-slide-to="6" data-bs-slide-to="6" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-3 item gallery-image">
                <div class="item-wrapper mb-3" data-toggle="modal" data-bs-toggle="modal" data-target="#u7y81jGePS-modal" data-bs-target="#u7y81jGePS-modal">
                    <img class="w-100" src="assets/images/gallery07.jpg"  data-slide-to="7" data-bs-slide-to="7" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS">
                    <div class="icon-wrapper">
                        <span class="mobi-mbri mobi-mbri-search mbr-iconfont mbr-iconfont-btn"></span>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="modal mbr-slider" tabindex="-1" role="dialog" aria-hidden="true" id="u7y81jGePS-modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="carousel slide" data-pause="false" data-bs-pause="false" id="lb-u7y81jGePS" data-interval="5000" data-bs-interval="5000">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="assets/images/gallery01.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery02.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery03.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery04.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery10.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery05.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery06.jpg" >
                                </div>
                                <div class="carousel-item">
                                    <img class="d-block w-100" src="assets/images/gallery07.jpg" >
                                </div>
                            </div>
                            <ol class="carousel-indicators">
                                <li data-slide-to="0" data-bs-slide-to="0" class="active" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="1" data-bs-slide-to="1" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="2" data-bs-slide-to="2" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="3" data-bs-slide-to="3" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="4" data-bs-slide-to="4" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="5" data-bs-slide-to="5" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="6" data-bs-slide-to="6" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                                <li data-slide-to="7" data-bs-slide-to="7" data-target="#lb-u7y81jGePS" data-bs-target="#lb-u7y81jGePS"></li>
                            </ol>
                            <a role="button" href="" class="close" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close">
                            </a>
                            <a class="carousel-control-prev carousel-control" role="button" data-slide="prev" data-bs-slide="prev" href="#lb-u7y81jGePS">
                                <span class="mobi-mbri mobi-mbri-arrow-prev" aria-hidden="true"></span>
                                <span class="sr-only visually-hidden">Previous</span>
                            </a>
                            <a class="carousel-control-next carousel-control" role="button" data-slide="next" data-bs-slide="next" href="#lb-u7y81jGePS">
                                <span class="mobi-mbri mobi-mbri-arrow-next" aria-hidden="true"></span>
                                <span class="sr-only visually-hidden">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</div>

  
  
</template>

<script>

import firebase from '../Firebase.js'

export default {
    name: "activity",
    components: {},
    data() {
        return {

            activity:{},
            active: true,
        
        };
    },
    methods: {

        async fetchActivity(activityId){

            const db = firebase.firestore();

            await db.collection('activity')
                .doc(activityId)
                .get()
                .then(doc => {
                if (doc.exists) {
                    this.activity = { id: doc.id, ...doc.data() };
                } else {
                    console.log('Activity item not found');
                }
                })
                .catch(error => {
                console.error('Error fetching Activity item:', error);
                });

            if(this.activity.status != '1'){
                this.active = true;
            }
            else{
                this.active = false;
            }

        }

    },
    mounted(){

        if(this.$route.params.id != '' && this.$route.params.id != undefined){
            this.fetchActivity(this.$route.params.id);
        }

       

    }
};

</script>