<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  }
};
</script>
<style>
@import  "../node_modules/@syncfusion/ej2/bootstrap5.css";


/* .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.8em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    border-left: 0.5em solid transparent !important;
    margin-top: 15px;
    margin-right: 0.255em !important;
} */
</style>
