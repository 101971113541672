<template>
    <div style="margin-top: 100px;">

       
        <section>

            <div id="carouselExampleAutoplaying" class="carousel slide container" data-bs-ride="carousel">
                <div class="carousel-inner" style="max-height: 70vh;">
                    <div v-for="(item,index) in homePageImages" :key="item.url" class="carousel-item" :class="[index == 0 ? 'active' : '']">
                        <!-- <div class="carousel-title ">
                            <router-link :to="'/RSVPRegistration/' + item.rsvpId" class="nav-link"><h3 style="background-color: grey;">{{item.name}}</h3></router-link>
                        </div> -->
                        <img :src="item.url" class="d-block w-100" >
                        <!-- <div class="carousel-caption ">
                            <router-link :to="'/RSVPRegistration/' + item.rsvpId" class="nav-link"><h5>Click here for RSVP</h5></router-link>
                        </div> -->
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

        </section>
        <section>
            <div class="container px-4 text-center container topWidget">
                <div class="col-lg-12 col-sm-12" style="display: flex;flex-wrap: wrap;justify-content: space-evenly;">
                    <div class="mb-3">
                        <router-link to="/MembershipRegistration" class="nav-link"><button type="button" class="btn btn-primary">Become a Member</button></router-link>
                    </div>
                    <div class="mb-3">
                        <router-link to="/Donation" class="nav-link"><button type="button" class="btn btn-primary">Donations</button></router-link>
                    </div>
                    <div class="mb-3">
                        <a href="https://forms.gle/rbDqZGddmmQMsP3E9"><button type="button" class="btn btn-secondary">Join as a Volunteer</button></a>
                    </div>
                </div>
            </div>
        </section>
        
<section ref="AboutUs" id="AboutUs">
    <!-- <h1 class="display-5 fw-bold">About Us</h1> -->
  
    <div class="px-4 text-center container" style="display: flex; flex-flow: wrap;justify-content: center">
   
    <div class="col-sm-12 col-lg-3 px-4 leftWidget">
        <div class="card mb-3">
            <router-link to="/MembershipRegistration" class="nav-link"><img src="assets/images/becomeamember.jpg" class="card-img-top"></router-link>
        </div>
        <div class="card mb-3">
            <router-link to="/Donation" class="nav-link"><img src="assets/images/Donate.png" class="card-img-top"></router-link>
        </div>
        <div class="card mb-3">
            <a href="https://forms.gle/rbDqZGddmmQMsP3E9"><img src="assets/images/Volunteer.jpg" class="card-img-top"></a>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 mx-auto">
        <p>
					<strong>Building Friendship and Community in Tampa Bay: The Story of Tamil Sneham</strong> <br/><br/>

<strong>Rooted in Friendship:</strong>  Tamil Sneham builds a community in Tampa Bay with friendships at its core. 

Tamil Sneham, which translates to “Tamil Friendship”, is a non-profit organization has been serving the Tampa Bay Area since 2007. Our mission is to foster friendships and celebrate the social and cultural heritage of people with Indian roots.  <br/><br/>

Originally focussed on the Tamil community, Tamil Sneham has grown to welcome anyone who shares their values. It has extended to others through its many educational, leadership and recreational activities for the youth of the community. Tamil Sneham is now an inclusive organization open to to all who share its mission.<br/><br/>

<strong>From Gathering to Growth:</strong> It all started in 2007 when a group of friends envisioned a space for fun and connection among Tamils in and around Tampa. Our first event, a family picnic in a county park, drew over 100 people and set the stage for future gatherings.<br/><br/>

<strong>Beyond Fun:</strong> Tamil Sneham organizes a variety of events to engage the Tamil community in and around Tampa. Movie screenings, talent shows, seasonal celebration and music concerts provide entertainment, while the  ‘Raagam Sneham’ music group offers a platform for local musicians and vocalists to showcase their talents. For the sporty crowd, “Sports Sneham” organizes men volleyball and women throw ball tournaments every year.<br/><br/>

As Tamil Sneham continued to flourish it added more activities to meet the needs of the community. Noteworthy among them are the Youth Leadership Program (YLP) for the middle and high school students and monthly volunteer opportunities that give back to the local community. <br/><br/>

<strong>Preserving Heritage Through Language:</strong> Nurturing a sense of cultural pride and awareness in our children is a cornerstone of Tamil Sneham's mission. We believe Tamil language is the heart of our heritage, connecting us to our roots and fellow Tamils in onshore and offshore. <br/><br/>

To help families maintain this connection, Tamil Sneham coordinates Tamil language classes. These classes, led by dedicated volunteers, provide children of Tamil descent a foundation in their native language. We equip teachers with resources and encourage families to use Tamil at home.  Fun competitions further motivate children to learn and celebrate their heritage.<br/><br/>

Tamil Sneham was registered (Doc. No. 07000004600) as a non-profit corporation in the State of Florida on May 7, 2007 and was granted Federal tax-exempt status, under section 501 (c) (4) of the Internal Revenue Service Code, on August 31, 2010 (EIN 65- 1301498).<br/><br/>

				</p>
    </div>
    <div class="col-sm-12 col-lg-3"> 
        <div class="card mb-3" >
            <iframe width="100%"  src="https://www.youtube.com/embed/jeDPDTcjRkk?si=HeUfedHaPapJoxMx?autoplay=1&mute=1" title="YouTube video player" frameborder="0"  autoplay=1
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <!-- <div class="card mb-3" >
            <iframe width="100%"  src="https://www.youtube.com/embed/jeDPDTcjRkk?si=HeUfedHaPapJoxMx?autoplay=1&mute=1" title="YouTube video player" frameborder="0"  autoplay=1
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> -->
        <div class="card" >
            <div class="fb-page" 
                data-tabs="timeline,events,messages"
                data-href="http://www.facebook.com/tampatamil.sneham"
                data-width="380" 
                data-hide-cover="false">
            </div>
        </div>
    </div>
  </div>
</section>

<section ref="Sponsors" id="Sponsors">
    <div class="container mb-5">
        <h2 class="pb-2 border-bottom">Platimun Sponsors</h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/PlatinumSponser1.png" class="card-img-top">
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/PlatinumSponser2.jpg" class="card-img-top">
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/PlatinumSponser3.jpg" class="card-img-top">
                </div>
            </div>
        </div>
    </div>
    <div class="container mb-5">
        <h2 class="pb-2 border-bottom">Gold Sponsors</h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/GoldSponser1.jpg" class="card-img-top">
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/GoldSponser2.jpg" class="card-img-top">
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <h2 class="pb-2 border-bottom">Silver Sponsors</h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
                <div class="card shadow-sm">
                    <img src="assets/images/SilverSponser1.png" class="card-img-top">
                </div>
            </div>
        </div>
    </div>

</section>

<section data-bs-version="5.1" class="form5 cid-tJS9pBcTSa" id="Contact" ref="Contact">
    
    
    <div class="container">
        <h2 class="pb-2 border-bottom">Contact Form</h2>
        <!-- <div class="mbr-section-head mb-5">
            <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                <strong>Contact Form</strong></h3>
            <h4 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-4 display-7">Please feel free to contact us for any queries
</h4>
        </div> -->
        <div class="row justify-content-center mt-4">
            <div class="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                <!-- <form action="" method="POST" class="mbr-form form-with-styler" data-form-title="Form Name"> -->
                    <input type="hidden" name="email" data-form-email="true" value="Mg1X68ZxgJBGKrr58lrk75rlJ0Z0xt+jNvlUVjYtwFV7+SK0VwjwB2txpiD9HXywONGC1mXM6ke8v+gHt7cSOp8ZMvM9iE+viuI/R1A9Ypjfj9+yxFVNH5d7J746OeVt">
                    <div class="row">
                        <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Thanks for filling out the form!</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                            Oops...! some problem!
                        </div>
                    </div>
                    <div class="dragArea row">
                        <div class="col-md col-sm-12 form-group mb-3" data-for="name">
                            <input type="text" name="name" placeholder="Name" data-form-field="name" v-model="name" class="form-control" value="" id="name-form02-6">
                        </div>
                        <div class="col-md col-sm-12 form-group mb-3" data-for="email">
                            <input type="email" name="email" placeholder="Email" data-form-field="email" v-model="email" class="form-control" value="" id="email-form02-6">
                        </div>
                        <div class="col-12 form-group mb-3" data-for="phone">
                            <input type="tel" name="phone" placeholder="Phone" data-form-field="phone" v-model="phone" class="form-control" value="" id="phone-form02-6">
                        </div>
                        <div class="col-12 form-group mb-3" data-for="textarea">
                            <textarea name="textarea" placeholder="Message" data-form-field="textarea" v-model="message" class="form-control" id="textarea-form02-6"></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn" style="text-align: center">
                            <button class="btn btn-primary display-7" @click="sendMail">Send message</button>
                        </div>
                    </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" class="contacts2 map1 cid-tLdYHD757A" id="contacts02-9">

    

   
<div class="container">
    <h2 class="pb-2 border-bottom">Contact</h2>
    <!-- <div class="mbr-section-head mb-5">
        <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
            <strong>Contacts</strong>
        </h3>
        
    </div> -->
    <div class="justify-content-center mt-4" style="display: flex;flex-wrap: wrap;">
        <div class="card col-12 col-md-6" style="padding:10px;border-radius:30px;">
            <div >
            <div class="card-wrapper" style="text-align:center;font-size:20px;">
                <h5 class="cardTitle animate__animated animate__delay-1s animate__fadeIn" ><strong style=" font-weight: bold;">Get in touch</strong></h5>
                <br />
                <ul class="list mbr-fonts-style display-7" style="list-style-type: none;padding-left: 0;">
                    <li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">
                        <span style=" line-height: 1; font-weight: 400;">Phone: 8137310144</span><br>
                    </li>
                    <li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">WhatsApp: 8137310144</li>
                    <li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">Email:<span>&nbsp;</span><a href="mailto:contact@tamilsneham.org" class="text-primary animate__animated animate__delay-1s animate__fadeIn" style=" color: rgb(43, 122, 105) !important; text-decoration: none; font-style: normal; font-weight: 400; cursor: pointer; line-height: 1; position: relative;">contact@tamilsneham.org</a></li>
                    <li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;"><br></li><li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">Address:</li><li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">Tamil Sneham,</li>
                    <li class="mbr-text item-wrap animate__animated animate__delay-1s animate__fadeIn" style="font-style: normal; line-height: 1.7; text-align: center;">P.O. Box 47463, Tampa, FL - 33647</li>
                </ul>
            </div>
        </div>
        </div>
        <div class="col-12 col-md-6" style="padding:10px;">
            <div class="map-wrapper" style="height: 100%;min-height:350px;">
            <div class="google-map" style="height: 100%;"><iframe frameborder="0" style="border:0; width:100%;height:100%;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56299.78255705648!2d-82.39074699934137!3d28.12406194009107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2b652939d5223%3A0x9b9704b240cf558b!2sTampa%2C%20FL%2033647%2C%20USA!5e0!3m2!1sen!2sin!4v1711031917635!5m2!1sen!2sin" allowfullscreen=""></iframe></div>
        </div>
        </div>
       
    </div>
</div>
</section>


    </div>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, listAll } from "firebase/storage";
import firebase from '../Firebase.js';

export default {
    name: "home",
    components: {},
    data() {
        return {
            param:'',
            homePageImages: [],
            activityList:[],
            rsvpList:[],
            name:'',
            email:'',
            phone:'',
            message:'',
        };
    },
    methods: {
    scrolls(el){
        // console.log('Scroll method');
        // this.$refs.About.scrollIntoView({ behavior: 'smooth' });
        // document.getElementById(el).scrollIntoView();
    },
    async sendMail(){

        const db = firebase.firestore();

        await db.collection('email').add({
            from: 'contact@tamilsneham.org',
            to: 'contact@tamilsneham.org',
            subject: 'Enquiry',
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message,
            mailType: 5,
        });



    },
    fetchactivityList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('activity')
        .where('status', '==', '1')
        .orderBy('activityDateTimestamp', 'desc')
        .get()
        .then(querySnapshot => {
          const activityList = [];
          querySnapshot.forEach(doc => {
            // Extract the RSVP data from each document
            const activity = {
              activityId: doc.id,
              ...doc.data()
            };
            activityList.push(activity);
          });
          this.activityList = activityList;
        })
        .catch(error => {
          console.error(error);
        });
    },
    async loadRsvps(){

        const db = firebase.firestore();

        await db.collection('rsvp')
        .where('active', '==', true)
        .orderBy('eventDateTimestamp', 'asc')
        .get()
        .then(docs => {
            docs.forEach(doc => {
                const rsvp = {
                    rsvpId: doc.id,
                    ...doc.data()
                };
                this.rsvpList.push(rsvp);
                if(rsvp.active && this.rsvpId == ''){
                    this.rsvpId = rsvp.rsvpId;
                }
            });
        })
        .catch(error => {
            console.error('Error fetching RSVP items:', error);
        });

    },
    },
    async mounted(){

        console.log("Mounted");

        this.loadRsvps();

        this.fetchactivityList();

        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        console.log('Width = ', vw);

        const storage = getStorage();
      
        // Create a reference under which you want to list
        const listRef = ref(storage);

        listAll(listRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {

                    if(folderRef.name == 'homePage'){

                        listAll(folderRef)
                            .then((files) => {

                                console.log(files);

                                files.items.forEach((file) => {
                                    this.homePageImages.push({url : 'https://firebasestorage.googleapis.com/v0/b/tamilsneham-prod.appspot.com/o/homePage%2F' + file.name + '?alt=media&token=a8395b3b-4498-49f2-979b-3539f1835a55'});
                                });
                            })
                            .catch((error1) => {

                            });

                    }
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                // All the items under listRef.
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });

        

    }

};

</script>

<style>                                         
    section{
        padding:30px 0 30px 0;
    }

    .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        }

    .carousel-title {
        position: absolute;
        right: 15%;
        top: 1.25rem;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }

   

</style>