<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Membership Benefits</strong></h1>
				
				
			</div>
		</div>
		<div class="row mt-5 justify-content-center">
			<div class="col-12 col-lg-8">
				<img src="assets/images/benefits.png"  style="max-width: 100%;" title="">
			</div>
		</div>
	</div>
</section>




</div>

  
  
</template>

<script>

export default {
    name: "activity",
    components: {},
    data() {
        return {
        
        };
    },
    methods: {
    }
};

</script>