<template>
  <section class="container" style="margin-top: 100px !important;">
    
      <h2>{{ isEditing ? 'Edit Activity' : 'Create Activity' }}</h2>
      <form class="form">
        <div class="dragArea row">
            <div class="col-md-6 col-sm-12 form-group mb-3" data-for="activityName">
                <base-input class="col-lg-12" form-control label="Activity Name" labelClasses="col-lg-12" :error="activityNameError"
                id="activity-name" v-model="activityName" required></base-input>
            </div>
            <div class="col-md-6 col-sm-12 form-group mb-3" data-for="activityDate">
                <base-input  class="col-lg-12" label="Activity Date" form-control labelClasses="col-lg-12" :error="activityDateError"
                type="date" id="activity-date" v-model="activityDate" required></base-input>
            </div>
            <div class="col-md-6 col-sm-12 form-group mb-3" data-for="activityImage">
              <!-- <div class="e-float-input upload-area"> -->
                  <!-- <input type="text" id="activityImage" name="activityImage" readonly="" data-required-message="* Select any file" required="" data-msg-containerid="uploadError">  -->
                  <ejs-uploader ref="uploadObj" id='defaultfileupload' cssClass="specific" name="UploadFiles"
                    :dropArea = "dropElement" :removing= "onFileRemove" :sequentialUpload='false' :autoUpload='false' :buttons="buttons" ></ejs-uploader>
              <!-- </div>    -->
              <div id="uploadError"></div>
            </div>
            <div class="col-md-6 col-sm-12 form-group mb-3" data-for="activityStatus">
                <base-checkbox  label="Status" form-control labelClasses="col-lg-12" checked="true" input="check"
                  id="status" v-model="status"> &nbsp;&nbsp;Status</base-checkbox>
            </div>
            

            <ejs-richtexteditor ref="richTextObject" :height="600" :value="value" :toolbarSettings="toolbarSettings" :quickToolbarSettings='quickToolbarSettings' :enableTabKey="enableTabKey"></ejs-richtexteditor>
            
        </div>

        <br>
        <br>
        <div class="dragArea">
          <base-button class="mb-3 col-lg-3" type="primary" style="float:right;" @click="closeActivity">Cancel</base-button>
          <base-button class="mb-3 col-lg-3" type="primary" style="float:right;margin-right:20px;" @click="submitActivity">{{ isEditing ? 'Update Activity' : 'Create Activity' }}</base-button>
        </div>
      </form>

   
  </section>
</template>

<script>
import firebase from '../Firebase.js';
import { UploaderComponent } from '@syncfusion/ej2-vue-inputs';
import { getStorage, ref, uploadBytes, listAll } from "firebase/storage";
import { FileInfo } from '@syncfusion/ej2-vue-inputs/uploader';
import { RichTextEditorComponent, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table, FileManager, EmojiPicker, Video, Audio, FormatPainter, PasteCleanup} from "@syncfusion/ej2-vue-richtexteditor";
import { createElement, isNullOrUndefined, detach, EventHandler } from '@syncfusion/ej2-base';

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');


export default {

  components: {
    'ejs-richtexteditor': RichTextEditorComponent,
    'ejs-uploader': UploaderComponent,
  },
  data() {
    return {
      fileList: [],
      filesDetails: [],
      parentElement: '',
      progressbarContainer: '',
      activityList: [],
      showPopup: false, // Flag to show/hide the popup
      isEditing: false, // Flag to determine if the popup is in edit mode
      nameError: '',
      eventDateError: '',
      lastDateError: '',
      instructionError: '',
      inactiveMessageError:'',
      activityNameError:'',
      activity:{},
      activityId:'',
      activityName:'',
      activityDate:'',
      activityImage:'',
      activityDateTimestamp: '',
      html:'',
      activityDateError:'',
      activityImageError:'',
      RSVPTypeError:'',
      isValid: true,
      theme: theme,
      hostUrl: 'https://www.test.com/',
      enableTabKey : true,
      myCodeMirror: '',
      fileManagerSettings: {
          enable: true,
          path: '/Pictures/Food',
          ajaxSettings: {
              url: this.hostUrl + 'api/FileManager/FileOperations',
              getImageUrl: this.hostUrl + 'api/FileManager/GetImage',
              uploadUrl: this.hostUrl + 'api/FileManager/Upload',
              downloadUrl: this.hostUrl + 'api/FileManager/Download' 
          }
      },
      quickToolbarSettings: {
          table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles'],
          showOnRightClick: true,
      },
      toolbarSettings: {
          items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'SuperScript', 'SubScript', '|',
          'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
          'LowerCase', 'UpperCase', '|',
          'Formats', 'Alignments', '|', 'NumberFormatList', 'BulletFormatList', '|',
          'Outdent', 'Indent', '|', 'CreateLink', 'Image', 'FileManager', 'Video', 'Audio', 'CreateTable', '|', 'FormatPainter', 'ClearFormat',
          '|', 'EmojiPicker', 'Print', '|',
          'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
      },
      buttons: {
        browse: 'Choose file',
        upload: 'Upload',
        },
      dropElement: '.control-fluid',
      change: (args) => {
            // this.isAuto = args.checked;
            this.$refs.uploadObj.clearAll();
        },
        changed: (args) => {
            // this.isSequential = args.checked;
            this.$refs.uploadObj.clearAll();
        },
      extensions: '.jpg, .png',
      
    };
  },
  provide: {
    richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table, FileManager, EmojiPicker, Video, Audio, FormatPainter, PasteCleanup]
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'activityList' property

    // if(this.$route.params.id != '' && this.$route.params.id != undefined){

    //         this.fetchActivity(this.$route.params.id);
    //     }
  },
  methods: {
    onFileRemove: function (args) {
        args.postRawFile = false;
    },
    async fetchActivity(activityId){

      const db = firebase.firestore();

      await db.collection('activity')
          .doc(activityId)
          .get()
          .then(doc => {
          if (doc.exists) {
              let activity = { id: doc.id, ...doc.data() };

              this.activityId = activityId;
              this.activityName = activity.activityName;
              this.activityDate = activity.activityDate;

          } else {
              console.log('Activity item not found');
          }
          })
          .catch(error => {
          console.error('Error fetching Activity item:', error);
          });

      if(this.activity.status != '1'){
          this.active = true;
      }
      else{
          this.active = false;
      }

      },
    uploadFile: function(args) {
        this.$refs.uploadObj.upload([this.filesDetails[this.fileList.indexOf(args.currentTarget.parentElement)]], true);
    },
    closeActivity() {
      this.activity = {}; // Reset the RSVP object
      this.$router.push('/ActivityList');
    },
    getNow(isDate){

      const today = new Date();

      const date = today.getFullYear() +'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
      const shortDate = today.getFullYear().toString().slice(-2) +''+ String(today.getMonth()+1).padStart(2, '0') +''+ String(today.getDate()).padStart(2, '0');
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date +' '+ time;

      if(isDate){
          return dateTime;
      }

      return parseInt(shortDate +''+ today.getMinutes() +''+ today.getMilliseconds());
    },
    async submitActivity() {
      // Save the RSVP to Firestore
      const db = firebase.firestore();

      this.nameError = '';
      this.activityDateError = '';
      this.activityImageError = '';
      this.isValid = true;

      if (this.activityName == '') { this.activityNameError = 'Activity Name required'; this.isValid = false }
      if (this.activityDate == '') { this.activityDateError = 'Activity Date required'; this.isValid = false }
      // if (this.activityImage == '') { this.activityImageError = 'Activity Image required'; this.isValid = false }
      
      this.activityDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(this.activityDate));

      let file = this.$refs.uploadObj.getFilesData()[0];

      this.html = this.$refs.richTextObject.getHtml();

      if (!this.isValid) { return; }

      let fileName = this.getNow(false).toString() + '.' + file.name.split('.')[1];


      const storage = getStorage();
      const storageRef = ref(storage, 'activity/' + fileName);

      let fileURL = 'https://firebasestorage.googleapis.com/v0/b/tamilsneham-prod.appspot.com/o/activity%2F' + fileName + '?alt=media&token=a8395b3b-4498-49f2-979b-3539f1835a55'
      // const spaceRef = ref(storage, 'images/space.jpg');

      await uploadBytes(storageRef, file.rawFile).then((snapshot) => {

        console.log('Uploaded a blob or file!');
      });

      
      let activity = { 

        activityName : this.activityName,
        activityDate : this.activityDate,
        html : this.html,
        fileURL : fileURL
      }

      if (this.isEditing) {
        // Update existing RSVP
        db.collection('activity')
          .doc(this.activityId)
          .update(this.activity)
          .then(() => {
            console.log('Activity updated successfully!');
            this.closeActivity();
          })
          .catch(error => {
            console.error('Error updating Activity:', error);
          });
      } else {
        // Create new RSVP
        db.collection('activity')
          .add(activity)
          .then(() => {
            console.log('Activity created successfully!');
            this.closeActivity();
          })
          .catch(error => {
            console.error('Error creating Activity:', error);
          });
      }
    },
    setFreeEvent(value) {

      if(typeof(value) == 'object'){return;}

      // this.activity.freeEvent = value;

    },
    setInactive(value){
      if(typeof(value) == 'object'){return;}

      // this.activity.active = value;
    },
    getDate(){
        let date = '';
        date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
        return date += '/' + ((new Date()).getFullYear().toString());
    },
  }

};
</script>

<style>

.uploader-preview .e-file-select-wrap {
      display: none;
  }

.table {
  background-color: white !important;
}

.popup {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup h2 {
  margin-top: 0;
}
</style>