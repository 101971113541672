<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="margin-top: 100px !important;">
               
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1 class="display-3  text-white">Tampa Tamil sneham
                                    <br/><span>Florida Tamil Community !!!</span>
                                </h1>
                                <br/><br/>
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-5">
                                            <div class=" card">
                                                <div class="card-body ">
                                                    <template>
                                                        <!-- <div class="text-center text-muted mb-4">
                                    <small>Or sign in with credentials</small>
                                </div> -->
                                                        <form role="form" @submit.prevent="login">
                                                            <base-input alternative
                                                                        class="mb-3"
                                                                        placeholder="Email"
                                                                        id="email" v-model="email" required >
                                                            </base-input>
                                                            <base-input alternative
                                                                        type="password"
                                                                        placeholder="Password"
                                                                        id="password" v-model="password" required>
                                                            </base-input>
                                                            <div class="text-center">
                                                                <base-button type="submit" @click="login" class="my-4">Sign In</base-button>
                                                            </div>
                                                            <!-- <button class="submit-btn" type="submit">Login</button> -->
                                                            <p class="error" v-if="error">{{ error }}</p>
                                                        </form>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
    </div>
</template>

<script>

import firebase from '../Firebase.js'

export default {
    name: "home",
    components: {},
    data() {
        return {
        email: '',
        password: '',
        error: null,
        };
    },
  methods: {
    async login() {
      try {
        // Sign in the user with Firebase Authentication

        console.log('Login ');

        const result = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);

        console.log('Login1 ', result);

        // Redirect the user to the registration form page
        // this.$router.push('/RSVPRegistrationList');
      } catch (error) {
        this.error = error.message;
      }
    },
  },


};
</script>
