<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Board Members</strong></h1>
				
				
			</div>
		</div>
		
		<h2 class="pb-2 border-bottom">Board of Directors</h2>
        <div class="d-flex justify-content-around flex-wrap">
            <div class="team-item-width">
                <img src="assets/images/BoardOfDirectors/Deva.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Deva</strong></h5>
                <p class="text-muted" style="text-align: center;" >Director</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/BoardOfDirectors/Muthukumar.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Muthu Kumar</strong></h5>
                <p class="text-muted" style="text-align: center;" >Director</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/BoardOfDirectors/Selva.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Selva</strong></h5>
                <p class="text-muted" style="text-align: center;" >Director</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/BoardOfDirectors/Sundar.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Sundar</strong></h5>
                <p class="text-muted" style="text-align: center;" >Director</p>
            </div>
        </div>
		
	</div>
</section>




</div>

  
  
</template>

<script>

export default {
    name: "activity",
    components: {},
    data() {
        return {
        
        };
    },
    methods: {
    }
};

</script>