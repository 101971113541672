<script>

</script>


<template>
    <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
         <div class="pt-lg-md" style="margin-top: 60px;">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <!-- <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0"> -->
                            <h1 class="heading">RSVP Registration List</h1>
                            <div class="table-responsive" id="mainDiv" style="min-height: 60vh;">
                              <div class="mb-3">
                                <!-- <base-dropdown class="col-lg-3" >
                                  <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly required placeholder="Select RSVP"
                                  labelClasses="col-lg-12" style="background-color: white;" form-control id="rsvpId" v-model="selectedRSVPName">
                                  </base-input>
                                  <div class="form-group col-lg-12" v-for="rsvp in rsvpList" :key="rsvp.id" @click="setRSVP(rsvp.name,rsvp.rsvpId, rsvp.RSVPType)"><a>{{rsvp.name}}</a></div>
                                </base-dropdown> -->
                                    <div class="input-group mb-3 col-lg-3">
                                        <select class="form-select" ref="rsvpSelect" id="rsvpSelect" aria-label="" v-model="selectedRSVPObject" @change="setRSVP($event)">
                                            <option selected></option>
                                            <option v-for="rsvp in rsvpList" :key="rsvp.id" :value="rsvp.name + '-' + rsvp.rsvpId + '-' + rsvp.RSVPType" >{{ rsvp.name }}</option>
                                        </select>
                                    </div>

                                    <button type="button" class="btn btn-primary btn-lg px-4 me-sm-3" @click="sendBulkEmail">Send Bulk Reminder</button>

                              </div>
                             
                              <div v-if="showfilters" class="d-flex" style="flex-flow: wrap;">
                                <base-input class="mb-3 col-lg-3" placeholder="Filter by name" form-control
                                    addon-left-icon="ni ni-circle-08" id="filter-name" v-model="filters.name.value">
                                </base-input>
                                <base-input class="mb-3 col-lg-3" placeholder="Filter by email" form-control
                                    addon-left-icon="ni ni-circle-08" id="filter-email" v-model="filters.email.value">
                                </base-input>
                                <base-input class="mb-3 col-lg-3" placeholder="Filter by phone" form-control
                                    addon-left-icon="ni ni-circle-08" id="filter-phone" v-model="filters.phone.value">
                                </base-input>
                               
                                
                              </div>

                              <ejs-grid ref='registrationList' id='registrationList' :dataSource="rsvpRegList" :allowFiltering='true' :filterSettings='filterSettings' :allowSorting='true' :allowPdfExport='true'  :allowExcelExport='true' 
                                :toolbar='toolbar' :pdfExportComplete='pdfExportComplete' :commandClick='commandClick' :allowPaging="true" :pageSettings='pageSettings' :rowDataBound='rowDataBound' :toolbarClick='toolbarClick'  :enableHover='false' :enableHeaderFocus='true' :autoFit='true'>
                                  <e-columns>
                                      <e-column v-if="selectedRSVPType != 'Volley Ball'" field='firstName' headerText='First Name'></e-column>
                                      <e-column v-if="selectedRSVPType != 'Volley Ball'" field='lastName' headerText='Last Name'></e-column>
                                      <e-column v-if="selectedRSVPType != 'Volley Ball'" field='email' headerText='Email'></e-column>
                                      <e-column v-if="selectedRSVPType != 'Volley Ball'" field='phoneNumber' headerText='Phone Number'></e-column>

                                      <e-column v-if="selectedRSVPType == 'Women\’s Event'" field='participantNameWomens' headerText='Participant'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Volley Ball'" field='tournament' headerText='Tournament'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Volley Ball'" field='teamNameVolley' headerText='Team Name'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Volley Ball'" field='captainNameVolley' headerText='Captain Name'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Volley Ball'" field='emailVolley' headerText='Email'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Volley Ball'" field='phoneNumberVolley' headerText='Phone Number'></e-column>

                                      <e-column v-if="selectedRSVPType == 'General Event'" field='adults' headerText='Adults'></e-column>
                                      <e-column v-if="selectedRSVPType == 'General Event'" field='kids' headerText='Kids'></e-column>
                                      <e-column v-if="selectedRSVPType == 'YLP Event'" field='studentGrade' headerText='Student Grade'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Talent Event'" field='participants' headerText='Participants'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Talent Event'" field='ageGroup' headerText='Age Group'></e-column>
                                      <e-column v-if="selectedRSVPType == 'Talent Event'" field='competition' headerText='Competition'></e-column>
                                      <e-column field='membershipId' headerText='Member Id'></e-column>
                                      <e-column v-if='false == true' field='rsvpRegId' headerText='RSVP REG Id'></e-column>
                                      <!-- <e-column field='rsvpName' headerText='RSVP'></e-column> -->
                                      <!-- <e-column field='paypalId' headerText='Paypal ID'></e-column> -->
                                      <e-column field='paidAmount' headerText='Paid Amount'></e-column>
                                      <e-column headerText='Send' :commands='commands'></e-column>
                                      <!-- <e-column field='paidStatus' headerText='Paid Status'></e-column> -->
                                  </e-columns>
                              </ejs-grid>
                               
                                <!-- <v-table class="vehicle-table table table-striped align-middle" :data="rsvpRegList" :filters="filters" :currentPage.sync="currentPage" 
                                  :pageSize="10"  @totalPagesChanged="totalPages = $event">
                                  <thead slot="head">
                                    <tr>
                                      <v-th sortKey="firstName">First Name</v-th>
                                      <v-th sortKey="lastName">Last Name</v-th>
                                      <v-th sortKey="email">Email</v-th>
                                      <v-th sortKey="phone">Phone Number</v-th>
                                      <th>Adults</th>
                                      <th>Kids</th>
                                      <v-th sortKey="rsvpName">RSVP</v-th>
                                      <th>Paypal ID</th>
                                      <th>Paid Amount</th>
                                      <th>Paid Status</th>
                                    </tr>
                                  </thead>
                                  <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-for="rsvpReg in displayData" :key="rsvpReg.rsvpRegId" :style="getStyle(rsvpReg)">
                                      <td>{{ rsvpReg.firstName }}</td>
                                      <td>{{ rsvpReg.lastName }}</td>
                                      <td>{{ rsvpReg.email }}</td>
                                      <td>{{ rsvpReg.phoneNumber }}</td>
                                      <td>{{ rsvpReg.adults }}</td>
                                      <td>{{ rsvpReg.kids }}</td>
                                      <td>{{ rsvpReg.rsvpName }}</td>
                                      <td>{{ rsvpReg.paypalId }}</td>
                                      <td>{{ rsvpReg.paidAmount }}</td>
                                      <td>{{ rsvpReg.paidStatus }}</td>
                                    </tr>
                                  </tbody>
                                </v-table>
                                <smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages"/> -->
                            </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import firebase from '../Firebase.js';
const QRCode = require('qrcode-svg');
import { GridPlugin, VirtualScroll, Sort, GridComponent, Column, ColumnsDirective, PdfExport, ExcelExport, Page, Toolbar, Filter, FilterType, CommandColumn } from "@syncfusion/ej2-vue-grids";
// import "../../node_modules/@syncfusion/ej2/bootstrap5.css"

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default {
  components: {
    'ejs-grid' : GridComponent,
    'e-columns' : ColumnsDirective,
    'e-column' : ColumnsDirective,
  },
  data() {
    return {
      theme: theme,
      pageSettings: { pageCount: 10},
      toolbar: ['ExcelExport', 'PdfExport'],
      tooltip: {
        enable: true,
      },
      commands: [{  buttonOption: { iconCss: 'e-icons e-send', isPrimary: true, cssClass: 'e-flat email'} },],
      zoom:
        {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
        },
      legend: {enableHighlight : true, position: 'Top'},
      filterSettings: { type: 'Menu' },
      filters: {
        name: { value: '', keys: ['firstName', 'lastName'] },
        email: { value: '', keys: ['email'] },
        phone: { value: '', keys: ['phoneNumber'] },
        rsvp: { value: '', keys: ['rsvpName'] },
        
      },
      rsvpRegList: [],
      currentPage:1,
      totalPages:0,
      records:[],
      rsvpList:[],
      selectedRSVPName:'',
      selectedRSVPType:'',
      selectedRSVPObject:'',
      rsvpId:'',
      showfilters: false,
    };
  },
  provide: {
    grid: [Sort, VirtualScroll, PdfExport, ExcelExport, Page, Toolbar, Filter, CommandColumn]
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'rsvpRegList' property
    this.loadRsvps();
    //this.fetchRSVPRegList();
  },
  methods: {
    async loadRsvps(){

        const db = firebase.firestore();

        await db.collection('rsvp')
          .get()
          .then(docs => {
              docs.forEach(doc => {
                  const rsvp = {
                      rsvpId: doc.id,
                      ...doc.data()
                  };
                  this.rsvpList.push(rsvp);
                  if(rsvp.active && this.rsvpId == ''){
                      this.rsvpId = rsvp.rsvpId;
                      this.selectedRSVPName = rsvp.name;
                      this.selectedRSVPType = rsvp.RSVPType;
                      this.fetchRSVPRegList();
                  }
              });
          })
          .catch(error => {
              console.error('Error fetching RSVP items:', error);
          });

    },
    toggleFilters(){
      this.showfilters = !this.showfilters;
    },
    getStyle(rsvpReg){

      let style = (rsvpReg.entry ? 'background-color:lightgreen;' : '') + (rsvpReg.membershipId != '' ? 'color:blue;' : '');

      return style;

    },
    setRSVP(event){

        let value = event.currentTarget.selectedOptions[0].value;
        let name = event.currentTarget.selectedOptions[0].text;

        console.log(value, name);

        let item = value.split('-');

        this.selectedRSVPName = name;
        this.selectedRSVPType = item[2];
        this.rsvpId = item[1];

        this.successmessage='';

        this.fetchRSVPRegList();
    },
    async fetchRSVPRegList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('RSVPRegistration')
        .where("rsvpId", "==", this.rsvpId)
        .get()
        .then(querySnapshot => {
          const rsvpRegList = [];
          querySnapshot.forEach(doc => {
          // Extract the RSVP data from each document
              const rsvp = {
                rsvpRegId: doc.id,
                ...doc.data()
              };
              rsvpRegList.push(rsvp);
              });
              this.rsvpRegList = rsvpRegList;
              this.records = JSON.parse(JSON.stringify(this.rsvpRegList));
          })
          .catch(error => {
          console.error(error);
        });
    },
    rowDataBound: function(args) {

        if (args.data['entry']) {
            args.row.classList.add('entryBackgroud');
        } 
        
        if (args.data['membershipId']) {
            args.row.classList.add('blueText');
        } 
    },
    async commandClick(args) {
        const db = firebase.firestore();

        let command = args.target.ej2_instances[0].properties.cssClass;

        if(command.includes('email')){

          const querySnapshot = await db.collection('RSVPRegistration')
              .doc(args.rowData.rsvpRegId)
              .get();

          if(!querySnapshot.empty){
              let reg = querySnapshot.data();

              let registrationId = args.rowData.rsvpRegId;

              // Generate a QR code SVG string
              const qrCode = new QRCode({
              content: btoa(registrationId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
              container: 'svg',
              join: true
              }).svg();

              let emailTo = reg.email;
              let userName = reg.firstName + ' ' + reg.lastName;

              await db.collection('email').add({
                  from: 'contact@tamilsneham.org',
                  subject: 'RSVP Confirmation',
                  RSVPType: 'General Event',
                  email: emailTo,
                  adults: reg.adults,
                  kids: reg.kids,
                  adultsFee: '',
                  kidsFee: '',
                  rsvpName: reg.rsvpName,
                  rsvpEventDate: this.formatDate(reg.rsvpEventDate),
                  qrCode: qrCode,
                  registrationId: registrationId,
                  userName: userName,
                  firstNameSport: reg.firstNameSport  == undefined ? '' : reg.firstNameSport,
                  lastNameSport : reg.lastNameSport  == undefined ? '' : reg.lastNameSport,
                  emailSports: reg.emailSports  == undefined ? '' : reg.emailSports,
                  phoneNumberSports: reg.phoneNumberSports  == undefined ? 0 : reg.phoneNumberSports,
                  firstNameStudent: reg.firstNameStudent  == undefined ? '' : reg.firstNameStudent,
                  lastNameStudent : reg.lastNameStudent  == undefined ? '' : reg.lastNameStudent,
                  studentGrade: reg.studentGrade  == undefined ? '' : reg.studentGrade,
                  parentName: reg.parentName  == undefined ? '' : reg.parentName,
                  emailYLP: reg.emailYLP  == undefined ? '' : reg.emailYLP,
                  phoneNumberYLP: reg.phoneNumberYLP  == undefined ? 0 : reg.phoneNumberYLP,
                  competition: reg.competition  == undefined ? '' : reg.competition,
                  firstNameTalent: reg.firstNameTalent  == undefined ? '' : reg.firstNameTalent,
                  lastNameTalent: reg.lastNameTalent  == undefined ? '' : reg.lastNameTalent,
                  ageGroup: reg.ageGroup  == undefined ? '' : reg.ageGroup,
                  parentNameTalent: reg.parentNameTalent  == undefined ? '' : reg.parentNameTalent,
                  emailTalent: reg.emailTalent  == undefined ? '' : reg.emailTalent,
                  phoneNumberTalent: reg.phoneNumberTalent  == undefined ? 0 : reg.phoneNumberTalent,
                  participants: reg.participants  == undefined ? '' : reg.participants,
                  entryFeeTalentEvent : reg.entryFeeTalentEvent == undefined ? 0 : reg.entryFeeTalentEvent,
                  entryFeeYLPEventMember: reg.entryFeeYLPEventMember == undefined ? 0 : reg.entryFeeYLPEventMember,
                  entryFeeYLPEventNonmember: reg.entryFeeYLPEventNonmember == undefined ? 0 : reg.entryFeeYLPEventNonmember,
                  teamNameVolley : reg.teamNameVolley  == undefined ? '' : reg.teamNameVolley,
                  tournament : reg.tournament  == undefined ? '' : reg.tournament,
                  captainNameVolley : reg.captainNameVolley  == undefined ? '' : reg.captainNameVolley,
                  emailVolley : reg.emailVolley  == undefined ? '' : reg.emailVolley,
                  phoneNumberVolley : reg.phoneNumberVolley  == undefined ? 0 : reg.phoneNumberVolley,
                  entryFeeVolleyBall : reg.entryFeeVolleyBall == undefined ? 0 : reg.entryFeeVolleyBall,
                  totalPaid: reg.paidAmount,
                  mailType: 2,
              });
             
              return true;
          }    

          // await db.collection('policyData')
          //       .doc(args.rowData.policyId)
          //       .update({approved: true});

          //   this.showEmailPopup = true;
            
          //   this.selectedFileURL = args.rowData.policyURL;
            // this.sendEmail();

        }
    },
    async sendBulkEmail(){

      const db = firebase.firestore();

     await db.collection('RSVPRegistration')
          .where('rsvpId', '==', this.rsvpId)
          .get()
          .then(querySnapshot => {
            const rsvpRegList = [];
            querySnapshot.forEach(doc => {
            // Extract the RSVP data from each document
                const rsvp = {
                  rsvpRegId: doc.id,
                  ...doc.data()
                };

                this.sendReminderEmail(rsvp);
                //rsvpRegList.push(rsvp);


                });


                this.rsvpRegList = rsvpRegList;
                this.records = JSON.parse(JSON.stringify(this.rsvpRegList));
            })
            .catch(error => {
              console.error(error);
            });

    },
    async sendReminderEmail(rsvpObj){
      
        const db = firebase.firestore();

        let registrationId = rsvpObj.rsvpRegId;

        // Generate a QR code SVG string
        const qrCode = new QRCode({
        content: btoa(registrationId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
        container: 'svg',
        join: true
        }).svg();

        let emailTo = rsvpObj.email;
        let userName = rsvpObj.firstName + ' ' + rsvpObj.lastName;

        console.log(userName + '---' + emailTo);

        
          await db.collection('email').add({
              from: 'contact@tamilsneham.org',
              subject: 'RSVP Confirmation',
              RSVPType: 'General Event',
              email: emailTo,
              adults: rsvpObj.adults,
              kids: rsvpObj.kids,
              adultsFee: '',
              kidsFee: '',
              rsvpName: rsvpObj.rsvpName,
              rsvpEventDate: this.formatDate(rsvpObj.rsvpEventDate),
              qrCode: qrCode,
              registrationId: registrationId,
              userName: userName,
              firstNameSport: rsvpObj.firstNameSport  == undefined ? '' : rsvpObj.firstNameSport,
              lastNameSport : rsvpObj.lastNameSport  == undefined ? '' : rsvpObj.lastNameSport,
              emailSports: rsvpObj.emailSports  == undefined ? '' : rsvpObj.emailSports,
              phoneNumberSports: rsvpObj.phoneNumberSports  == undefined ? 0 : rsvpObj.phoneNumberSports,
              firstNameStudent: rsvpObj.firstNameStudent  == undefined ? '' : rsvpObj.firstNameStudent,
              lastNameStudent : rsvpObj.lastNameStudent  == undefined ? '' : rsvpObj.lastNameStudent,
              studentGrade: rsvpObj.studentGrade  == undefined ? '' : rsvpObj.studentGrade,
              parentName: rsvpObj.parentName  == undefined ? '' : rsvpObj.parentName,
              emailYLP: rsvpObj.emailYLP  == undefined ? '' : rsvpObj.emailYLP,
              phoneNumberYLP: rsvpObj.phoneNumberYLP  == undefined ? 0 : rsvpObj.phoneNumberYLP,
              competition: rsvpObj.competition  == undefined ? '' : rsvpObj.competition,
              firstNameTalent: rsvpObj.firstNameTalent  == undefined ? '' : rsvpObj.firstNameTalent,
              lastNameTalent: rsvpObj.lastNameTalent  == undefined ? '' : rsvpObj.lastNameTalent,
              ageGroup: rsvpObj.ageGroup  == undefined ? '' : rsvpObj.ageGroup,
              parentNameTalent: rsvpObj.parentNameTalent  == undefined ? '' : rsvpObj.parentNameTalent,
              emailTalent: rsvpObj.emailTalent  == undefined ? '' : rsvpObj.emailTalent,
              phoneNumberTalent: rsvpObj.phoneNumberTalent  == undefined ? 0 : rsvpObj.phoneNumberTalent,
              participants: rsvpObj.participants  == undefined ? '' : rsvpObj.participants,
              entryFeeTalentEvent : rsvpObj.entryFeeTalentEvent == undefined ? 0 : rsvpObj.entryFeeTalentEvent,
              entryFeeYLPEventMember: rsvpObj.entryFeeYLPEventMember == undefined ? 0 : rsvpObj.entryFeeYLPEventMember,
              entryFeeYLPEventNonmember: rsvpObj.entryFeeYLPEventNonmember == undefined ? 0 : rsvpObj.entryFeeYLPEventNonmember,
              teamNameVolley : rsvpObj.teamNameVolley  == undefined ? '' : rsvpObj.teamNameVolley,
              tournament : rsvpObj.tournament  == undefined ? '' : rsvpObj.tournament,
              captainNameVolley : rsvpObj.captainNameVolley  == undefined ? '' : rsvpObj.captainNameVolley,
              emailVolley : rsvpObj.emailVolley  == undefined ? '' : rsvpObj.emailVolley,
              phoneNumberVolley : rsvpObj.phoneNumberVolley  == undefined ? 0 : rsvpObj.phoneNumberVolley,
              entryFeeVolleyBall : rsvpObj.entryFeeVolleyBall == undefined ? 0 : rsvpObj.entryFeeVolleyBall,
              totalPaid: rsvpObj.paidAmount,
              mailType: 2,
          });
    },
    formatDate(date) {

      if(date == undefined) return '';

      // const options = { day: "2-digit", month: "short", year: "numeric" };
      // const formattedDate = new Date(date).toLocaleDateString(
      //   "en-US",
      //   options
      // );

      const formattedDate = new Date(date).toUTCString();

      // Split the formatted date into day, month, and year parts
      const [weekday, day, month, year, time, type] = formattedDate.split(" ");

      // Convert the month abbreviation to uppercase
      const capitalizedMonth = month.toUpperCase();

      // Set the formatted date with uppercase month abbrevia tion and desired format
      return `${day} ${capitalizedMonth} ${year}`;
    },
    async sendDuplicateEmail(){

        // Get a reference to the Firestore database
        const db = firebase.firestore();

        this.registrationId = '24011920407';

        // Generate a QR code SVG string
        const qrCode = new QRCode({
        content: btoa(this.registrationId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
        container: 'svg',
        join: true
        }).svg();

        let emailTo = 'prakash4me@gmail.com';
        let userName = '';

        if(this.rsvp.RSVPType == 'General Event'){
        emailTo = this.email;
        userName = this.firstName + ' ' + this.lastName;
        }
        if(this.rsvp.RSVPType == 'Sport Event'){
        emailTo = this.emailSports;
        userName = this.firstNameSport + ' ' + this.lastNameSport;
        }
        if(this.rsvp.RSVPType == 'Volley Ball'){
        emailTo = this.emailVolley;
        userName = this.teamNameVolley;
        }
        if(this.rsvp.RSVPType == 'YLP Event'){
        emailTo = this.emailYLP;
        userName = this.firstNameStudent + ' ' + this.lastNameStudent;
        }
        if(this.rsvp.RSVPType == 'Talent Event'){
        emailTo = this.emailTalent;
        userName = this.firstNameTalent + ' ' + this.lastNameTalent;
        }


        await db.collection('email').add({
            from: 'contact@tamilsneham.org',
            subject: 'RSVP Confirmation',
            RSVPType: this.rsvp.RSVPType,
            email: emailTo,
            adults: this.adults,
            kids: this.kids,
            adultsFee: this.paidAdultPrice,
            kidsFee: this.paidKidsPrice,
            rsvpName: this.rsvp.name,
            rsvpEventDate: this.formatDate(this.rsvp.eventDate),
            qrCode: qrCode,
            registrationId: this.registrationId,
            userName: userName,
            firstNameSport: this.firstNameSport,
            lastNameSport : this.lastNameSport,
            emailSports: this.emailSports,
            phoneNumberSports: this.phoneNumberSports,
            firstNameStudent: this.firstNameStudent,
            lastNameStudent : this.lastNameStudent,
            studentGrade: this.studentGrade,
            parentName: this.parentName,
            emailYLP: this.emailYLP,
            phoneNumberYLP: this.phoneNumberYLP,
            competition: this.competition,
            firstNameTalent: this.firstNameTalent,
            lastNameTalent: this.lastNameTalent,
            ageGroup: this.ageGroup,
            parentNameTalent: this.parentNameTalent,
            emailTalent: this.emailTalent,
            phoneNumberTalent: this.phoneNumberTalent,
            participants: this.participants,
            entryFeeTalentEvent : this.rsvp.entryFeeTalentEvent == undefined ? 0 : this.rsvp.entryFeeTalentEvent,
            entryFeeYLPEventMember: this.rsvp.entryFeeYLPEventMember == undefined ? 0 : this.rsvp.entryFeeYLPEventMember,
            entryFeeYLPEventNonmember: this.entryFeeYLPEventNonmember == undefined ? 0 : this.rsvp.entryFeeYLPEventNonmember,
            teamNameVolley : this.teamNameVolley,
            tournament : this.tournament,
            captainNameVolley : this.captainNameVolley,
            emailVolley : this.emailVolley ,
            phoneNumberVolley : this.phoneNumberVolley,
            entryFeeVolleyBall : this.rsvp.entryFeeVolleyBall == undefined ? 0 : this.rsvp.entryFeeVolleyBall,
            totalPaid: this.getTotalCost().toString(),
            mailType: 2,
        });

    },
    getNow(isDate){

        const today = new Date();
        const date = today.getFullYear()+'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date +' '+ time;

        if(isDate)
        {
          return date;
        }
        return dateTime;
    },
    toolbarClick(args) {
        switch (args.item.id) {
           
            case 'registrationList_excelexport':
              
                this.$refs.registrationList.excelExport(this.getExcelExportProperties());
                break;
            case 'registrationList_pdfexport':

                if(this.selectedRSVPType == 'General Type'){
                  // this.$refs.registrationList.getColumns()[6].visible = false;
                  // this.$refs.registrationList.getColumns()[9].visible = false;
                }
                else if(this.selectedRSVPType == 'General Type'){

                }
                else if(this.selectedRSVPType == 'General Type'){

                }
                else if(this.selectedRSVPType == 'General Type'){

                }
              

               
                this.$refs.registrationList.pdfExport(this.getPdfExportProperties());
                break;
        }
        
    },
    pdfExportComplete(args) {

      if(this.selectedRSVPType == 'General Type'){
        // this.$refs.registrationList.getColumns()[6].visible = true;
        // this.$refs.registrationList.getColumns()[9].visible = true;
      }
      else if(this.selectedRSVPType == 'General Type'){

      }
      else if(this.selectedRSVPType == 'General Type'){

      }
      else if(this.selectedRSVPType == 'General Type'){

      }

    },
    getDate(){
        let date = '';
        date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
        return date += '/' + ((new Date()).getFullYear().toString());
    },
    getExcelExportProperties: function() {
        return {
            header: {
                headerRows: 7,
                rows: [
                    {
                        index: 1,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 5, value: this.selectedRSVPName, style: { fontColor: '#C25050', fontSize: 25, hAlign: 'Center', bold: true } }
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 2, value: 'Tamil sneham', style: { fontColor: '#C67878', fontSize: 15, bold: true } },
                            { index: 5, value: 'DATE', style: { fontColor: '#C67878', bold: true }, width: 150 }
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, colSpan: 2, value: 'Tampa' },
                            { index: 5, value: this.getNow(false), width: 150 }
                        ]
                    },
                    // {
                    //     index: 5,
                    //     cells: [
                    //         { index: 1, colSpan: 2, value: 'Tel +1 888.936.8638 Fax +1 919.573.0306' },
                    //         { index: 4, value: 'CUSOTMER ID', style: { fontColor: '#C67878', bold: true } },
                    //         { index: 5, value: 'TERMS', width: 150, style: { fontColor: '#C67878', bold: true } }
                    //     ]
                    // },
                    // {
                    //     index: 6,
                    //     cells: [
                    //         { index: 4, value: 564 },
                    //         { index: 5, value: 'Net 30 days', width: 150 }
                    //     ]
                    // }
                ]
            },

            footer: {
                footerRows: 5,
                rows: [
                    /* tslint:disable-next-line:max-line-length */
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] },
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] }
                ]
            },
            
            fileName: "exceldocument.xlsx"
        };
    },
    getPdfExportProperties: function() {
        return {
          pageOrientation: 'Landscape',
            header: {
                fromTop: 0,
                height: 120,
                contents: [
                    {
                        type: 'Text',
                        value: this.selectedRSVPName,
                        position: { x: 280, y: 0 },
                        style: { textBrushColor: '#C25050', fontSize: 25 },
                    },
                    {
                        type: 'Text',
                        value: 'PRINT DATE',
                        position: { x: 500, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: this.getNow(false),
                        position: { x: 600, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: 'Tamil sneham',
                        position: { x: 20, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 20 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '2501 Aerial Center Parkway',
                    //     position: { x: 20, y: 65 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                    // {
                    //     type: 'Text',
                    //     value: 'Tel +1 888.936.8638 Fax +1 919.573.0306',
                    //     position: { x: 20, y: 80 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                ]
            },
            footer: {
                fromBottom: 50,
                height: 100,
                contents: [
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 250, y: 20 },
                        style: { textBrushColor: '#C67878', fontSize: 14 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '! Visit Again !',
                    //     position: { x: 300, y: 45 },
                    //     style: { textBrushColor: '#C67878', fontSize: 14 }
                    // }
                ]
            },
            
            fileName: "pdfdocument.pdf"
        };
    } 
  },
};
</script>

<style>
    .table{
        background-color:white !important;
    }

    .blueText td{
      color: blue !important;
    }

    .entryBackgroud{
      background-color: lightgreen;
    }
</style>