<script setup>


</script>

<template>
    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-default">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row ">
                <div class="col-lg-10">
                    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                        class="border-0">

                        <template>
                            <form class="form">
                                <div class="d-flex" style="flex-flow: wrap;">
                                    <base-input class="mb-3 col-lg-6" form-control label="Name" labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" id="name" v-model="rsvp.name" required></base-input>

                                <base-input  class="mb-3 col-lg-6" label="Event Date" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" type="date" id="event-date" v-model="rsvp.eventDate"
                                    required></base-input>

                                <base-input  class="mb-3 col-lg-6" label="Last Date" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" type="date" id="last-date" v-model="rsvp.lastDate"
                                    required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Entry Fee for Adults (Member)" labelClasses="col-lg-12"
                                    form-control addon-left-icon="ni ni-circle-08" type="number"
                                    id="entry-fee-adults-member" v-model="rsvp.entryFeeAdultsMember" required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Entry Fee for Kids (Member)" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" type="number" id="entry-fee-kids-member"
                                    v-model="rsvp.entryFeeKidsMember" required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Entry Fee for Adults (Non-member)" labelClasses="col-lg-12"
                                    form-control addon-left-icon="ni ni-circle-08" type="number"
                                    id="entry-fee-adults-nonmember" v-model="rsvp.entryFeeAdultsNonmember"
                                    required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Entry Fee for Kids (Non-member)" labelClasses="col-lg-12"
                                    form-control addon-left-icon="ni ni-circle-08" type="number"
                                    id="entry-fee-kids-nonmember" v-model="rsvp.entryFeeKidsNonmember"
                                    required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Kids Age From" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" type="number" id="kids-age-from"
                                    v-model="rsvp.kidsAgeFrom" required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Kids Age To" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-circle-08" type="number" id="kids-age-to"
                                    v-model="rsvp.kidsAgeTo" required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Contact Number 1" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-mobile-button" type="tel" id="contact-number-1"
                                    v-model="rsvp.contactNumber1" required></base-input>
                                <base-input  class="mb-3 col-lg-6" label="Contact Number 2" form-control labelClasses="col-lg-12"
                                    addon-left-icon="ni ni-mobile-button" type="tel" id="contact-number-2"
                                    v-model="rsvp.contactNumber2" required></base-input>
                                    <base-button class="mb-3 col-lg-6" type="primary" @click="submitRSVP()">Save RSVP</base-button>
                                </div>
                                
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>

    </section>
</template>

<script>

import firebase from '../Firebase.js'

export default {

    data() {
        return {
            rsvp: {
                name: '',
                eventDate: '',
                lastDate: '',
                entryFeeAdultsMember: 0,
                entryFeeKidsMember: 0,
                entryFeeAdultsNonmember: 0,
                entryFeeKidsNonmember: 0,
                kidsAgeFrom: 0,
                kidsAgeTo: 0,
                contactNumber1: '',
                contactNumber2: ''
            }
        };
    },
    methods: {
        submitRSVP() {
            // Add the RSVP data to Firestore
            const db = firebase.firestore();

            db.collection('rsvp')
                .add(this.rsvp)
                .then(() => {
                console.log('RSVP saved successfully!');
                // Reset the form after successful submission
                this.resetForm();
                })
                .catch(error => {
                console.error('Error saving RSVP:', error);
                });
        },
        resetForm() {
            // Reset the form fields
            this.rsvp = {
                name: '',
                eventDate: '',
                lastDate: '',
                entryFeeAdultsMember: 0,
                entryFeeKidsMember: 0,
                entryFeeAdultsNonmember: 0,
                entryFeeKidsNonmember: 0,
                kidsAgeFrom: 0,
                kidsAgeTo: 0,
                contactNumber1: '',
                contactNumber2: ''
            };
        }
    },
};
</script>

<style scoped></style>
