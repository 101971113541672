<template>
  <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
    <div class="pt-lg-md" style="margin-top: 60px;">
      <div class="row justify-content-end col-lg-11">
        <div style="display: flex;justify-content: end;">
          <base-button class="mb-3" type="primary" @click="openCreatePopup()">Add RSVP</base-button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <!-- <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0"> -->
          <h1 class="heading">RSVP List</h1>
          <!-- <ejs-grid ref='rsvpList' id='rsvpList' :dataSource="rsvpList" :allowFiltering='true' :filterSettings='filterSettings' :allowSorting='true' :allowPdfExport='true'  :allowExcelExport='true'
            :toolbar='toolbar' :pdfExportComplete='pdfExportComplete' :allowPaging="true" :pageSettings='pageSettings' :rowDataBound='rowDataBound' :toolbarClick='toolbarClick'  :enableHover='false' :enableHeaderFocus='true' :autoFit='true'>
              <e-columns>
                  <e-column field='name' headerText='Name'></e-column>
                  <e-column field='eventDate' headerText='Event Date'></e-column>
                  <e-column field='lastDate' headerText='Last Date'></e-column>
                  <e-column field='lastDate' headerText='Entry Fee'></e-column>
                  <e-column field='kidsAgeFrom' headerText='Kids Age Range'></e-column>
                  <e-column field='totalReg' headerText='RSVPs'></e-column>
                  <e-column field='totalEntry' headerText='Entries'></e-column>
              </e-columns>
          </ejs-grid> -->
          <div class="table-responsive ">
            <table class="vehicle-table table table-striped align-middle">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Event Date</th>
                  <th>Last Date</th>
                  <th>Entry Fee</th>
                  <th>Kids Age Range</th>
                  <th>RSVPs</th>
                  <th>Entries</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rsvp in rsvpList" :key="rsvp.rsvpId" :style="!rsvp.active ? 'color:red' : ''" >
                  <td>{{ rsvp.name }}</td>
                  <td>{{ rsvp.rsvpId }}</td>
                  <td>{{ rsvp.eventDate }}</td>
                  <td>{{ rsvp.lastDate }}</td>
                  <td>Member - Adults: {{ rsvp.entryFeeAdultsMember }}, Kids: {{ rsvp.entryFeeKidsMember }}<br/>
                  Non Member - Adults: {{ rsvp.entryFeeAdultsNonmember }}, Kids: {{ rsvp.entryFeeKidsNonmember }}</td>
                  <td>{{ rsvp.kidsAgeFrom }} - {{ rsvp.kidsAgeTo }}</td>
                  <td>RSVPs: {{ rsvp.totalReg }} <br/>
                      Members : {{ rsvp.totalMember }} | Non Members : {{ rsvp.totalNonMember }}<br/>
                      Adults : {{ rsvp.totalAdults }} |  Kids : {{ rsvp.totalKids }}<br/>
                  </td>
                  <td>Entries: {{ rsvp.totalEntry }} <br/>
                      Adults : {{ rsvp.totalAdultsEntry }} |  Kids : {{ rsvp.totalKidsEntry }}<br/>
                  </td>
                  <td>
                    <base-button class="mb-3 col-lg-5" type="primary" style="background-color: transparent;color:blue;border:none;font-size: x-large;" @click="openEditPopup(rsvp)" size="sm" iconOnly
                      icon="e-icons e-edit"></base-button>


                    <base-button class="mb-3 col-lg-5" type="danger" style="background-color: transparent;color:red;border:none;font-size: x-large;" @click="deleteItem(rsvp.rsvpId)" size="sm" iconOnly
                      icon="e-icons e-trash"></base-button>


                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- </card> -->
        </div>
      </div>
    </div>
    <!-- Create and Edit RSVP popup -->
    <modal :show="showPopup" @close="closePopup" type="notice">
      <h2>{{ isEditing ? 'Edit RSVP' : 'Create RSVP' }}</h2>
      <form class="form">
        <div class="d-flex" style="flex-flow: wrap;">
          
          <base-input class="mb-3 col-lg-6 base-input" form-control label="Name" labelClasses="col-lg-12" :error="nameError"
             id="name" v-model="rsvp.name" required></base-input>

          <div class="col-lg-6 base-input">
              <label style="margin-left: 15px;">RSVP Type</label><br/>
              <!-- <base-dropdown class="form-group mb-0 input-group " style="background-color: transparent;">
                  <base-input slot="title" type="default" class="dropdown-toggle mb-3" readonly alternative required placeholder="Select"
                    form-control style="background-color: white;" id="rsvpType" v-model="rsvp.RSVPType" :error="RSVPTypeError">
                  </base-input>
                  <a class="dropdown-item" href="#" @click="setEvent('General Event')">General Event</a>
                  <a class="dropdown-item" href="#" @click="setEvent('Women’s Event')">Women’s Event</a>
                  <a class="dropdown-item" href="#" @click="setEvent('Sport Event')">Sport Event</a>
                  <a class="dropdown-item" href="#" @click="setEvent('Volley Ball')">Volley Ball</a>
                  <a class="dropdown-item" href="#" @click="setEvent('YLP Event')">YLP Event</a>
                  <a class="dropdown-item" href="#" @click="setEvent('Talent Event')">Talent Event</a>
              </base-dropdown> -->

              <div class="input-group mb-3 col-lg-3">
                  <select class="form-select" ref="rsvpType" id="rsvpType" aria-label="" v-model="RSVPType" @change="setEvent()">
                      <option value="General Event">General Event</option>
                      <option value="Women’s Event">Women’s Event</option>
                      <option value="Sport Event">Sport Event</option>
                      <option value="Volley Ball">Volley Ball</option>
                      <option value="YLP Event">YLP Event</option>
                      <option value="Talent Event">Talent Event</option>
                  </select>
              </div>


          </div>

          <base-input class="mb-3 col-lg-6 base-input" label="Event Date" form-control labelClasses="col-lg-12"
            :error="eventDateError"  type="date" id="event-date" v-model="rsvp.eventDate"
            required></base-input>

          <base-input class="mb-3 col-lg-6 base-input" label="Last Date" form-control labelClasses="col-lg-12" :error="lastDateError"
             type="date" id="last-date" v-model="rsvp.lastDate" required></base-input>
          <div class="mb-3 col-lg-6 form-group input-group has-label alternative">
            <label class="col-lg-12">Free Event</label>
            <base-switch :value="rsvp.freeEvent" @input="setFreeEvent">Free Event</base-switch>
          </div>
          <div class="mb-3 col-lg-6 form-group input-group has-label alternative">
            <label class="col-lg-12">Membership Not Applicable</label>
            <base-switch :value="rsvp.memberShipNA" @input="setMembershipApplicable">Membership Not Applicable</base-switch>
          </div>

          <base-input v-if="rsvp.RSVPType == 'Sport Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-sport-Event" v-model="rsvp.entryFeeSportEvent" required></base-input>

            <base-input v-if="rsvp.RSVPType == 'Volley Ball'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-volley-Ball" v-model="rsvp.entryFeeVolleyBall" required></base-input>

          <base-input v-if="rsvp.RSVPType == 'Talent Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-talent-Event" v-model="rsvp.entryFeeTalentEvent" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-Event-member" v-model="rsvp.entryFeeAdultsMember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'YLP Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-YLP-Event-member" v-model="rsvp.entryFeeYLPEventMember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'Women\’s Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-Womens-Event-member" v-model="rsvp.entryFeeWomensEventMember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Kids (Member)" form-control
            labelClasses="col-lg-12"  type="number" id="entry-fee-kids-member"
            v-model="rsvp.entryFeeKidsMember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Non-member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-Event-nonmember" v-model="rsvp.entryFeeAdultsNonmember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'YLP Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Non-member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-YLP-Event-nonmember" v-model="rsvp.entryFeeYLPEventNonmember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'Women\’s Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Event (Non-member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-Womens-Event-nonmember" v-model="rsvp.entryFeeWomensEventNonmember" required></base-input>

          <base-input v-if="!rsvp.freeEvent && rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Entry Fee for Kids (Non-member)"
            labelClasses="col-lg-12" form-control  type="number"
            id="entry-fee-kids-nonmember" v-model="rsvp.entryFeeKidsNonmember" required></base-input>

          <base-input v-if="rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Kids Age From" form-control labelClasses="col-lg-12"
             type="number" id="kids-age-from" v-model="rsvp.kidsAgeFrom"
            required></base-input>
          <base-input v-if="rsvp.RSVPType == 'General Event'" class="mb-3 col-lg-6 base-input" label="Kids Age To" form-control labelClasses="col-lg-12"
             type="number" id="kids-age-to" v-model="rsvp.kidsAgeTo"
            required></base-input>
          <base-input class="mb-3 col-lg-12 base-input" label="Instructions" form-control labelClasses="col-lg-12"
            id="instructions" :error="instructionError" required>
            <textarea class="form-control" name="name" rows="4" v-model="rsvp.instructions" cols="80"
              placeholder="Instructions..."></textarea>
          </base-input>
         
          <div class="mb-3 col-lg-6 form-group input-group has-label alternative base-input">
            <label class="col-lg-12">Active</label>
            <base-switch :value="rsvp.active" @input="setInactive">Active</base-switch>
          </div>
          <base-input v-if="!rsvp.active" class="mb-3 col-lg-12 base-input" label="Inactive Message" form-control labelClasses="col-lg-12"
            id="inactiveMessage" :error="inactiveMessageError" required>
            <textarea class="form-control" name="name" rows="4" v-model="rsvp.inactiveMessage" cols="80"
              placeholder="Inactive message..."></textarea>
          </base-input>

        </div>
        <div style="display: flex;justify-content: space-evenly;">
          <base-button class="mb-3 col-lg-5 base-input" type="primary" @click="submitRSVP">{{ isEditing ? 'Update RSVP' : 'Create RSVP' }}</base-button>
          <base-button class="mb-3 col-lg-5 base-input" type="primary" @click="closePopup">Cancel</base-button>
        </div>
      </form>

    </modal>

  </section>
</template>

<script>
import firebase from '../Firebase.js'
import Modal from '../components/Modal.vue';
import { GridPlugin, VirtualScroll, Sort, GridComponent, Column, ColumnsDirective, PdfExport, ExcelExport, Page, Toolbar, Filter, FilterType } from "@syncfusion/ej2-vue-grids";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');


export default {

  components: {
    'ejs-grid' : GridComponent,
    'e-columns' : ColumnsDirective,
    'e-column' : ColumnsDirective,
    Modal,
  },
  data() {
    return {
      theme: theme,
      pageSettings: { pageCount: 10},
      toolbar: ['ExcelExport', 'PdfExport'],
      tooltip: {
        enable: true,
      },
      zoom:
        {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
        },
      legend: {enableHighlight : true, position: 'Top'},
      filterSettings: { type: 'Menu' },
      entryFeeTemplate: function () {
          return {
              template: Vue.component('columnTemplate', {
                  template: `<div>Member - Event: </div>`,
                  data: function () { return { data: {} }; }
              })
          }
      },
      rsvpList: [],
      showPopup: false, // Flag to show/hide the popup
      isEditing: false, // Flag to determine if the popup is in edit mode
      nameError: '',
      eventDateError: '',
      lastDateError: '',
      instructionError: '',
      inactiveMessageError:'',
      RSVPTypeError:'',
      isValid: true,
      RSVPType:'',
      rsvp: {
        name: '',
        eventDate: '',
        eventDateTimestamp: '',
        lastDate: '',
        freeEvent: false,
        entryFeeAdultsMember: 0,
        entryFeeKidsMember: 0,
        entryFeeAdultsNonmember: 0,
        entryFeeKidsNonmember: 0,
        kidsAgeFrom: 0,
        kidsAgeTo: 0,
        instructions: '',
        contactNumber2: '',
        active:true,
        RSVPType:'General Event',
        memberShipNA: false
      }
    };
  },
  provide: {
    grid: [Sort, VirtualScroll, PdfExport, ExcelExport, Page, Toolbar, Filter]
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'rsvpList' property
    this.fetchRSVPList();
  },
  methods: {
    fetchRSVPList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('rsvp')
        .orderBy('eventDateTimestamp', 'desc')
        .get()
        .then(querySnapshot => {
          const rsvpList = [];
          querySnapshot.forEach(doc => {
            // Extract the RSVP data from each document
            const rsvp = {
              rsvpId: doc.id,
              ...doc.data()
            };
            rsvpList.push(rsvp);
          });
          this.rsvpList = rsvpList;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setEvent(){

      this.rsvp.RSVPType = this.RSVPType;

    },
    openCreatePopup() {
      this.isEditing = false; // Set the popup mode to create
      this.rsvp.RSVPType = 'General Event';
      this.RSVPType = 'General Event';
      this.showPopup = true; // Show the popup
    },
    openEditPopup(rsvp) {
      this.isEditing = true; // Set the popup mode to edit
      this.rsvp = { ...rsvp }; 
      this.RSVPType = this.rsvp.RSVPType;// Copy the RSVP object to the editing form
      this.showPopup = true; // Show the popup
    },
    closePopup() {
      this.showPopup = false; // Hide the popup
      this.isEditing = false; // Reset the edit mode
      this.rsvp = {}; // Reset the RSVP object
    },
    submitRSVP() {
      // Save the RSVP to Firestore
      const db = firebase.firestore();

      this.nameError = '';
      this.eventDateError = '';
      this.lastDateError = '';
      this.instructionError = '';
      this.isValid = true;

      if (this.rsvp.name == '') { this.nameError = 'Event Name required'; this.isValid = false }
      if (this.rsvp.eventDate == '') { this.eventDateError = 'Event Date required'; this.isValid = false }
      if (this.rsvp.lastDate == '') { this.lastDateError = 'Last Date required'; this.isValid = false }
      if (this.rsvp.instructions == '') { this.instructionError = 'Instructions required'; this.isValid = false }
      if (this.rsvp.RSVPType == '') { this.RSVPTypeError = 'RSVP Type required'; this.isValid = false }

      if (!this.isValid) { return; }

      this.rsvp.eventDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(this.rsvp.eventDate));

      if (this.isEditing) {
        // Update existing RSVP
        db.collection('rsvp')
          .doc(this.rsvp.rsvpId)
          .update(this.rsvp)
          .then(() => {
            console.log('RSVP updated successfully!');
            this.closePopup();
            this.fetchRSVPList();
          })
          .catch(error => {
            console.error('Error updating RSVP:', error);
          });
      } else {
        // Create new RSVP
        db.collection('rsvp')
          .add(this.rsvp)
          .then(() => {
            console.log('RSVP created successfully!');
            this.closePopup();
            this.fetchRSVPList();
          })
          .catch(error => {
            console.error('Error creating RSVP:', error);
          });
      }
    },
    setFreeEvent(value) {

      if(typeof(value) == 'object'){return;}

      this.rsvp.freeEvent = value;

      console.log(this.rsvp.freeEvent, this.rsvp.RSVPType);

    },
    setMembershipApplicable(value){
      if(typeof(value) == 'object'){return;}

      this.rsvp.memberShipNA = value;
    },
    setInactive(value){
      if(typeof(value) == 'object'){return;}

      this.rsvp.active = value;
    },
    async deleteItem(rsvpId) {

      const db = firebase.firestore();

      await db.collection('rsvp')
        .doc(rsvpId)
        .delete()
        .then(() => {
          console.log('RSVP deleted successfully!');
        })
        .catch(error => {
          console.error('Error deleting RSVP:', error);
        });

        this.fetchRSVPList();

    },
    rowDataBound: function(args) {

      if (args.data['entry']) {
          args.row.classList.add('entryBackgroud');
      } 

      if (args.data['membershipId']) {
          args.row.classList.add('blueText');
      } 
    },
    toolbarClick(args) {
        switch (args.item.id) {
           
            case 'registrationList_excelexport':
              
                this.$refs.registrationList.excelExport(this.getExcelExportProperties());
                break;
            case 'registrationList_pdfexport':
                this.$refs.registrationList.getColumns()[6].visible = false;
                this.$refs.registrationList.getColumns()[9].visible = false;
                this.$refs.registrationList.pdfExport(this.getPdfExportProperties());
                break;
        }
        
    },
    pdfExportComplete(args) {
      this.$refs.registrationList.getColumns()[6].visible = true;
      this.$refs.registrationList.getColumns()[9].visible = true;
    },
    getDate(){
        let date = '';
        date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
        return date += '/' + ((new Date()).getFullYear().toString());
    },
    getExcelExportProperties: function() {
        return {
            header: {
                headerRows: 7,
                rows: [
                    {
                        index: 1,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 5, value: this.selectedRSVPName, style: { fontColor: '#C25050', fontSize: 25, hAlign: 'Center', bold: true } }
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 2, value: 'Tamil sneham', style: { fontColor: '#C67878', fontSize: 15, bold: true } },
                            { index: 5, value: 'DATE', style: { fontColor: '#C67878', bold: true }, width: 150 }
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, colSpan: 2, value: 'Tampa' },
                            { index: 5, value: this.getNow(false), width: 150 }
                        ]
                    },
                    // {
                    //     index: 5,
                    //     cells: [
                    //         { index: 1, colSpan: 2, value: 'Tel +1 888.936.8638 Fax +1 919.573.0306' },
                    //         { index: 4, value: 'CUSOTMER ID', style: { fontColor: '#C67878', bold: true } },
                    //         { index: 5, value: 'TERMS', width: 150, style: { fontColor: '#C67878', bold: true } }
                    //     ]
                    // },
                    // {
                    //     index: 6,
                    //     cells: [
                    //         { index: 4, value: 564 },
                    //         { index: 5, value: 'Net 30 days', width: 150 }
                    //     ]
                    // }
                ]
            },

            footer: {
                footerRows: 5,
                rows: [
                    /* tslint:disable-next-line:max-line-length */
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] },
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] }
                ]
            },
            
            fileName: "exceldocument.xlsx"
        };
    },
    getPdfExportProperties: function() {
        return {
          pageOrientation: 'Landscape',
            header: {
                fromTop: 0,
                height: 120,
                contents: [
                    {
                        type: 'Text',
                        value: this.selectedRSVPName,
                        position: { x: 280, y: 0 },
                        style: { textBrushColor: '#C25050', fontSize: 25 },
                    },
                    {
                        type: 'Text',
                        value: 'PRINT DATE',
                        position: { x: 500, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: this.getNow(false),
                        position: { x: 600, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: 'Tamil sneham',
                        position: { x: 20, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 20 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '2501 Aerial Center Parkway',
                    //     position: { x: 20, y: 65 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                    // {
                    //     type: 'Text',
                    //     value: 'Tel +1 888.936.8638 Fax +1 919.573.0306',
                    //     position: { x: 20, y: 80 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                ]
            },
            footer: {
                fromBottom: 50,
                height: 100,
                contents: [
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 250, y: 20 },
                        style: { textBrushColor: '#C67878', fontSize: 14 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '! Visit Again !',
                    //     position: { x: 300, y: 45 },
                    //     style: { textBrushColor: '#C67878', fontSize: 14 }
                    // }
                ]
            },
            
            fileName: "pdfdocument.pdf"
        };
    } 
  }

};
</script>

<style>
.table {
  background-color: white !important;
}

.popup {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup h2 {
  margin-top: 0;
}

.modal-dialog{
  max-width: 50%;
}

@media (max-width: 576px) {

  .modal-dialog{
    max-width: 100% !important;
  }
}
  @media (min-width: 576px) {

    .modal-dialog{
      max-width: 50% !important;
    }
  
}

.base-input{
  padding: 10px;
}
</style>