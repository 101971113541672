<template>
    <div>

        <section data-bs-version="5.2" class="header5 cid-u7ancmRRnP" id="header05-1x">
	
          <div class="px-4 pt-5 my-5 text-center border-bottom">
    <h1 class="display-4 fw-bold">சிநேகம் தமிழ் பாடசாலை</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">It is a part of Tamil Sneham's mission to impart in our children a sense of awareness and pride in our heritage so that they can grow up upholding the virtues of our culture in our adopted country. Language is an important component of our culture and serves as a bond between members of our community who share the same linguistic heritage. We encourage all parents to make their children conversant in their native language so that it can serve as a medium of communication with them. Toward this end, our organization is coordinating the study of Tamil language at  <strong> University Area Community Development Center 14013 N 22nd St, Tampa, FL 33613 </strong> taught by volunteer- teachers. These classes are intended for children of parents who are interested in teaching their children a basic knowledge of the Tamil language. We provide the syllabus and teaching aids to the teachers and conduct competitions among children on their knowledge of the language.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
        <h1 style="color: #e91e63;">Registration for the year 2024-2025 is closed. Thanks for your interest.</h1>
        <!-- <base-button type="button" class="btn btn-primary btn-lg px-4 me-sm-3" @click="register">Register</base-button> -->
        <!-- <button type="button" class="btn btn-primary btn-lg px-4 me-sm-3" @onclick="">Register</button> -->
        <!-- <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button> -->
      </div>
    </div>
    <div class="overflow-hidden" style="max-height: 30vh;">
      <div class="container px-5">
        <img src="assets/images/TSP_Top.jpg" class="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy">
      </div>
    </div>
  </div>
</section>

<section data-bs-version="5.2" class="people03 cid-u6OB2yRgYB" id="Team" ref="Team">
	
  <div class="container">
		<h2 class="pb-2 border-bottom">School Board</h2>
        <div class="d-flex justify-content-around flex-wrap">
            <div class="team-item-width">
                <img src="/assets/images/Mani.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>MANIMARAN (PRINCIPAL)</strong></h5>
            </div>
        </div>
       
	</div>
  <div class="container" style="padding-top: 100px;">
		<h2 class="pb-2 border-bottom">Vice Principals</h2>
        <div class="d-flex justify-content-around flex-wrap">
            <div class="team-item-width">
              <img src="/assets/images/Aarthi Gopalasamy.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>ARTHI GOPALASAMY</strong></h5>
            </div>
            <div class="team-item-width">
              <img src="/assets/images/Bhuvana Maharajan.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>BHUVANA MAHARAJAN</strong></h5>
            </div>
        </div>
	</div>
	<div class="container" style="padding-top: 100px;">
		<h2 class="pb-2 border-bottom">Meet the Teachers</h2>
        <div class="d-flex justify-content-around flex-wrap">
            <div v-for="(item, index) in teacherImages" :key="item.url" class="team-item-width">
                <img :src="item.url" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>{{item.name}}</strong></h5>
                <!-- <p class="text-muted" style="text-align: center;" >President</p> -->
            </div>
        </div>
	</div>
</section>



</div>

  
  
</template>

<script>

import { getStorage, ref, listAll } from "firebase/storage";
import { register } from "swiper/element";

export default{
  data() {
    return {
      teacherImages: []
    };
  },
  methods: {
    register(){
      this.$router.push('/SchoolRegistration');
    },
    async fetchImages() {
      try {
        // Make API call to fetch images from Google Drive
        //https://drive.google.com/drive/folders/1kf--c7FQlEJ7H2XTbguzmUaAF8wQoZ7i?usp=sharing
        // const response = await fetch('https://www.googleapis.com/drive/v3/files?q=mimeType="image/jpeg"&key=AIzaSyC-DVZE3y9SFPO00aUrY2X7oWClfjVOetk');
        const response = await fetch('https://drive.google.com/drive/folders/1kf--c7FQlEJ7H2XTbguzmUaAF8wQoZ7i?usp=sharing&key=AIzaSyC-DVZE3y9SFPO00aUrY2X7oWClfjVOetk');
        const data = await response.json();
        
        // Extract image data from API response
        this.images = data.files;
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }
  },
  async mounted(){

    const storage = getStorage();
      
        // Create a reference under which you want to list
        const listRef = ref(storage);

        listAll(listRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {

                    if(folderRef.name == 'teachers'){

                        listAll(folderRef)
                            .then((files) => {

                                files.items.forEach((file) => {
                                    this.teacherImages.push({name: file.name.split('.')[0], url : 'https://firebasestorage.googleapis.com/v0/b/tamilsneham-prod.appspot.com/o/teachers%2F' + file.name + '?alt=media&token=a8395b3b-4498-49f2-979b-3539f1835a55'});
                                });
                            })
                            .catch((error1) => {

                            });

                    }
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });

  }

};

</script>