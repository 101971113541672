<script setup>

const kidsAge = 18;

</script>

<template> 
   <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
            <div class="container pt-lg-md">
                <div class="row">
                    <div class="mb-3 col-lg-5">
                        <card shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0">
                            <template>
                                <div>
                                    
                                    <label style="margin-left: 15px;">Select Event<span>*</span></label>
                                    <!-- <base-dropdown class="form-group mb-0 input-group col-lg-12" >
                                        <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly required placeholder="Select Event"
                                        labelClasses="col-lg-12" style="background-color: white;" form-control id="registrationType" v-model="selectedRSVPName">
                                        </base-input>
                                        <div class="form-group col-lg-12" v-for="rsvp in rsvpList" :key="rsvp.id" @click="setRSVP(rsvp.name,rsvp.rsvpId)"><a>{{rsvp.name}}</a></div>
                                    </base-dropdown> -->

                                    <div class="input-group mb-3">
                                        <select class="form-select" ref="rsvpSelect" id="rsvpSelect" aria-label="" v-model="selectedRSVPId" @change="setRSVP($event)">
                                            <option selected></option>
                                            <option v-for="rsvp in rsvpList" :key="rsvp.id"  :value="rsvp.rsvpId">{{ rsvp.name }}</option>
                                        </select>
                                    </div>

                                    <h3>{{this.selectedRSVPName}}</h3>
                                    <!-- <p><strong>Name:</strong> {{ rsvp.name }}</p> -->
                                    <p></p>
                                    <p><strong>Event Date:</strong> {{ formatDate(rsvp.eventDate) }}</p>
                                    <p><strong>RSVP closing Date:</strong> {{ formatDate(rsvp.lastDate) }}</p>
                                    <div v-if="rsvp.RSVPType == 'General Event'">
                                        <p v-if="this.rsvp.freeEvent"><strong>Entry Free</strong></p>
                                        <p v-if="!this.rsvp.freeEvent"><strong>Entry (Member):</strong> Adults: $ {{ rsvp.entryFeeAdultsMember }}, Kids: $ {{ rsvp.entryFeeKidsMember }}</p>
                                        <p v-if="!this.rsvp.freeEvent"><strong>Entry (Non-member):</strong> Adults: $ {{ rsvp.entryFeeAdultsNonmember }}, Kids: $ {{ rsvp.entryFeeKidsNonmember }}</p>
                                        <p><strong>Kids Age Range:</strong> {{ rsvp.kidsAgeFrom }} - {{ rsvp.kidsAgeTo }}</p>
                                       
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Women\’s Event'">
                                        <p><strong>Entry (Member):</strong> ${{ rsvp.entryFeeWomensEventMember }}</p>
                                        <p><strong>Entry (Non-Member):</strong> ${{ rsvp.entryFeeWomensEventNonmember }}</p>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Sport Event'">
                                        <p><strong>Entry :</strong> ${{ rsvp.entryFeeSportEvent }}</p>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'YLP Event'">
                                        <p><strong>Entry (Member):</strong> ${{ rsvp.entryFeeYLPEventMember }}</p>
                                        <p><strong>Entry (Non-Member):</strong> ${{ rsvp.entryFeeYLPEventNonmember }}</p>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Talent Event'">
                                        <p><strong>Entry :</strong> ${{ rsvp.entryFeeTalentEvent }}</p>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Volley Ball'">
                                        <p><strong>Entry :</strong> ${{ rsvp.entryFeeVolleyBall }}</p>
                                    </div>
                                   
                                    <p><strong>Instructions:</strong> 
                                        <span v-html="rsvp.instructions"></span></p>

                                        <h3 v-if="!rsvp.active" style="color: red;"><span v-html="rsvp.inactiveMessage"></span></h3>
                                </div>
                            </template>
                        </card>
                    </div>
                    <div class="col-lg-5" > 
                        <card shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0" >

                            <template>
                                <!-- <base-button type="submit" @click="setTotalRegistered" class="my-4">Import Data</base-button> -->
                            
                                <form class="form" aria-disabled="true">
                                    <div v-if="!rsvp.memberShipNA">
                                        <div v-if="rsvpId != ''">
                                            <p><strong>Are you a Member?</strong></p>
                                            <base-input alternative class="mb-3" placeholder="Membership Id" form-control style="width: 50%;float: left;"
                                                id="user-MembershipId" v-model="userMembershipId" :error="membershipIdError">
                                            </base-input>
                                            <base-button class="mb-3 col-lg-5" style="float: right;max-width: 41.66667%;height: 46px;" type="primary" @click="validateMemberId()">Validate</base-button>
                                        </div>
                                        <div v-if="rsvpId != ''" style="clear: both;">
                                            <p style="cursor: pointer;color: blue;" @click="forgotMemberId()">Forgot your Membership Id? </p>
                                        </div>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'General Event'">
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="First name" form-control
                                        id="first-name" v-model="firstName" :error="firstNameError">
                                        </base-input>
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Last name" form-control
                                            id="last-name" v-model="lastName" :error="lastNameError">
                                        </base-input>
                                        <!-- <p class="warning" v-if="vehicleNumberExists">Vehicle number already exists.</p> -->
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Email" 
                                             id="email" v-model="email" :error="emailError">
                                        </base-input>
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Phone number" form-control
                                             id="phone-number" v-model="phoneNumber"
                                            :error="phoneNumberError">
                                        </base-input>
                                        <!-- <label style="color: red;">Please provide phone number without any special characters</label> -->
                                        <p v-if="this.otpId && !this.member" style="color: green;">{{this.memberAlert() }}</p>
                                        <p v-if="this.otpId && this.member" style="color: green;">Membership discount applied </p>

                                        <div v-if="this.otpId && !this.member">
                                            <base-input alternative class="mb-3" style="width: 50%;float: left;" placeholder="OTP"
                                                 id="otp" v-model="otp" :error="otpError">
                                            </base-input>
                                            <base-button class="mb-3 col-lg-5" style="float: right;max-width: 41.66667%;height: 46px;" type="primary" @click="validateOTP()">Verify OTP</base-button>
                                        </div>
                                        
                                        <p style="margin-bottom: 0;">No of Adults </p>
                                        <div class="input-group mb-3">
                                            <select class="form-select" ref="adults" id="adults" aria-label="" v-model="adults" @change="setAdults()">
                                                <!-- <option selected></option> -->
                                                <option v-for="(id,index) in 5" :key="id" :selected="[ index == 0 ? 'selected' : '']" :value="index">{{ index }}</option>
                                            </select>
                                        </div>
                                        <!-- <p style="color: red;">* Kids Age should be between {{ rsvp.kidsAgeFrom }} - {{ rsvp.kidsAgeTo }}</p> -->
                                        <p style="margin-bottom: 0;">Kid 1 Age </p>
                                        <!-- <base-dropdown :style="{'pointer-events': kid1Enabled, 'opacity': kid1Opacity}" class="form-group mb-0 input-group" id="ddKid1Age">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Kid 1 Age"
                                                 style="background-color: white;" id="kid1Age" v-model="kid1Age">
                                            </base-input>
                                            <a class="dropdown-item" href="#" v-for="(item, index) in kidsAge" :key="index" @click="setKid1(item)">{{ item }}</a>
                                        </base-dropdown> -->
                                        <div class="input-group mb-3">
                                            <select class="form-select" ref="kid1Age" id="kid1Age" :style="{'pointer-events': kid1Enabled, 'opacity': kid1Opacity}" aria-label="" v-model="kid1Age" @change="setKid1()">
                                                <!-- <option selected></option> -->
                                                <option v-for="(item, index) in kidsAge" :key="item"  :value="item">{{ item }}</option>
                                            </select>
                                        </div>
                                        <p style="margin-bottom: 0;">Kid 2 Age </p>
                                        <!-- <base-dropdown :style="{'pointer-events': kid2Enabled, 'opacity': kid2Opacity}" class="form-group mb-0 input-group" id="ddKid2Age">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Kid 2 Age"
                                                 style="background-color: white;" id="kid2Age" v-model="kid2Age">
                                            </base-input>
                                            <a class="dropdown-item" href="#" v-for="(item, index) in kidsAge" :key="index" @click="setKid2(item)">{{ item }}</a>
                                        </base-dropdown> -->
                                        <div class="input-group mb-3">
                                            <select class="form-select" ref="kid2Age" id="kid2Age" :style="{'pointer-events': kid2Enabled, 'opacity': kid2Opacity}" aria-label="" v-model="kid2Age" @change="setKid2()">
                                                <!-- <option selected></option> -->
                                                <option v-for="(item, index) in kidsAge" :key="item"  :value="item">{{ item }}</option>
                                            </select>
                                        </div>

                                        <h6 v-if="!this.rsvp.freeEvent">Adult : $ {{ this.member ? rsvp.entryFeeAdultsMember : rsvp.entryFeeAdultsNonmember }} * {{ adults }}</h6>
                                        <h6 v-if="!this.rsvp.freeEvent">Kid : $ {{ this.member ? rsvp.entryFeeKidsMember : rsvp.entryFeeKidsNonmember }} * {{ kids }}</h6>
                                        <h3 v-if="!this.rsvp.freeEvent">Total Cost: $ {{ getTotalCost() }}</h3>

                                        
                                        <!-- <div class="text-center">
                                            <base-button type="submit" @click="submitForm" class="my-4">Save</base-button>
                                        </div> -->
                                        <base-button v-if="this.rsvp.freeEvent" class="mb-3 col-lg-6" type="primary" @click="saveRSVP()">Save RSVP</base-button>
                                        <!-- <h4>{{ successmessage}}</h4> -->
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Women\’s Event'">
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="First name" form-control
                                        id="first-name" v-model="firstNameWomens" :error="firstNameWomensError">
                                        </base-input>
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Last name" form-control
                                            id="last-name" v-model="lastNameWomens" :error="lastNameWomensError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Participant name" form-control
                                        id="participant-name" v-model="participantNameWomens" :error="participantNameWomensError">
                                        </base-input>
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Email" 
                                             id="email" v-model="emailWomens" :error="emailWomensError">
                                        </base-input>
                                        <base-input :disabled="member ? true : false" alternative class="mb-3" placeholder="Phone number" form-control
                                             id="phone-number" v-model="phoneNumberWomens"
                                            :error="phoneNumberWomensError">
                                        </base-input>

                                        <p style="margin-bottom: 10px;"><strong>No of Adults  :  1</strong></p>
                                        <!-- <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="No of adults"
                                                 style="background-color: white;" id="adults" v-model="adultsWomens">
                                            </base-input>
                                            <a class="dropdown-item" href="#" @click="setAdultsWomens(1)">1</a>
                                        </base-dropdown> -->
                                       
                                        <div style="clear: both;">
                                            <h3>Event entry : $ {{ getTotalCost() }}</h3>
                                        </div>
                                        
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Sport Event'">
                                        <base-input alternative class="mb-3" placeholder="First name" form-control
                                        id="first-name-sport" v-model="firstNameSport" :error="firstNameSportError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Last name" form-control
                                            id="last-name-sport" v-model="lastNameSport" :error="lastNameSportError">
                                        </base-input>
                                        <!-- <p class="warning" v-if="vehicleNumberExists">Vehicle number already exists.</p> -->
                                        <base-input alternative class="mb-3" placeholder="Email"
                                             id="email-sport" v-model="emailSports" :error="emailSportsError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Phone number" form-control
                                             id="phone-number-sport" v-model="phoneNumberSports"
                                            :error="phoneNumberSportsError">
                                        </base-input>

                                        <h3>Event entry : $ {{ rsvp.entryFeeSportEvent }}</h3>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Volley Ball'">
                                        <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Tournament"
                                                 style="background-color: white;" id="tournament" v-model="tournament" :error="tournamentVolleyError">
                                            </base-input>
                                            <a class="dropdown-item" href="#" @click="setTournament('Volleyball')">Volleyball</a>
                                            <!-- <a class="dropdown-item" href="#" @click="setTournament('Throwball')">Throwball</a> -->
                                        </base-dropdown>
                                        <base-input alternative class="mb-3" placeholder="Team name" form-control
                                        id="team-name-volley" v-model="teamNameVolley" :error="teamNameVolleyError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Captain name" form-control
                                            id="captain-name-volley" v-model="captainNameVolley" :error="captainNameVolleyError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Email" @blur="validateVolleyEmail"
                                             id="email-volley" v-model="emailVolley" :error="emailVolleyError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Phone number" form-control @blur="validateVolleyPhoneNumber"
                                             id="phone-number-volley" v-model="phoneNumberVolley"
                                            :error="phoneNumberVolleyError">
                                        </base-input>

                                        <h3>Event entry : $ {{ rsvp.entryFeeVolleyBall }}</h3>
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'YLP Event'">
                                        <base-input alternative class="mb-3" placeholder="First name" form-control
                                        id="first-name-student" v-model="firstNameStudent" :error="firstNameStudentError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Last name" form-control
                                            id="last-name-student" v-model="lastNameStudent" :error="lastNameStudentError">
                                        </base-input>
                                        <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Student Grade"
                                                 style="background-color: white;" id="StudentGrade" v-model="studentGrade" :error="studentGradeError">
                                            </base-input>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Grade 6')">Grade 6</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Grade 7')">Grade 7</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Grade 8')">Grade 8</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Freshmen')">Freshmen</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Sophomore')">Sophomore</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Junior')">Junior</a>
                                            <a class="dropdown-item" href="#" @click="setStudentGrade('Senior')">Senior</a>
                                        </base-dropdown>
                                        <base-input alternative class="mb-3" placeholder="Parent Name" form-control
                                            id="parent-name" v-model="parentName" :error="parentNameError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Email" @blur="validateYLPEmail"
                                             id="email-YLP" v-model="emailYLP" :error="emailYLPError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Phone number" @blur="validateYLPPhoneNumber" form-control
                                             id="phone-number-YLP" v-model="phoneNumberYLP"
                                            :error="phoneNumberYLPError">
                                        </base-input>

                                        <p v-if="this.otpId && !this.member" style="color: green;">{{this.memberAlert() }}</p>
                                        <p v-if="this.otpId && this.member" style="color: green;">Membership discount applied </p>

                                        <div v-if="this.otpId && !this.member">
                                            <base-input alternative class="mb-3" style="width: 50%;float: left;" placeholder="OTP"
                                                 id="otp-YLP" v-model="otpYLP" :error="otpYLPError">
                                            </base-input>
                                            <base-button class="mb-3 col-lg-5" style="float: right;max-width: 41.66667%;height: 46px;" type="primary" @click="validateYLPOTP()">Verify OTP</base-button>
                                        </div>
                                        <div style="clear: both;">
                                            <h3>Event entry : $ {{ getTotalCost() }}</h3>
                                        </div>
                                        
                                    </div>
                                    <div v-if="rsvp.RSVPType == 'Talent Event'" >
                                        <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Choose competition"
                                                 style="background-color: white;" id="competition" v-model="competition" :error="competitionError">
                                            </base-input>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Chess')">Chess</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Drawing')">Drawing</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('English Writing')">English Writing</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Tamil Writing')">Tamil Writing</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Thirukkural Recital')">Thirukkural Recital</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Tamil Speech')">Tamil Speech</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Cooking')">Cooking</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Kolam Potti')">Kolam Potti</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Solo Singing')">Solo Singing</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Dancing')">Dancing</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Fancy Dress')">Fancy Dress/Fashion Parade</a>
                                            <!-- <a class="dropdown-item" href="#" @click="setCompetiton('Reels')">Reels</a>
                                            <a class="dropdown-item" href="#" @click="setCompetiton('Dum Charades/Connections')">Dum Charades/Connections</a> -->
                                        </base-dropdown>
                                        <base-input alternative class="mb-3" placeholder="First name" form-control
                                        id="first-name-talent" v-model="firstNameTalent" :error="firstNameTalentError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Last name" form-control
                                            id="last-name-talent" v-model="lastNameTalent" :error="lastNameTalentError">
                                        </base-input>
                                        <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Age Group"
                                                 style="background-color: white;" id="ageGroup" v-model="ageGroup" :error="ageGroupError">
                                            </base-input>
                                            <a v-if="competition == 'Dancing' || competition == 'Solo Singing' || competition == 'Fancy Dress'" class="dropdown-item" href="#" @click="setAgeGroup('Elementary')">Elementary</a>
                                            <a v-if="competition == 'Dancing' || competition == 'Solo Singing' && competition != 'Fancy Dress'" class="dropdown-item" href="#" @click="setAgeGroup('Middle and High School')">Middle and High School</a>
                                            <a v-if="competition != 'Dancing' && competition != 'Solo Singing' && competition != 'Fancy Dress' && competition != 'Cooking' && competition != 'Kolam Potti' && competition != 'Dum Charades/Connections'" class="dropdown-item" href="#" @click="setAgeGroup('Elementary 1-3 grades')">Elementary 1-3 grades</a>
                                            <a v-if="competition != 'Dancing' && competition != 'Solo Singing' && competition != 'Fancy Dress' && competition != 'Cooking' && competition != 'Kolam Potti' && competition != 'Dum Charades/Connections'" class="dropdown-item" href="#" @click="setAgeGroup('Elementary 4-5 grades')">Elementary 4-5 grades</a>
                                            <a v-if="competition != 'Dancing' && competition != 'Solo Singing' && competition != 'Fancy Dress' && competition != 'Cooking' && competition != 'Kolam Potti' && competition != 'Dum Charades/Connections'" class="dropdown-item" href="#" @click="setAgeGroup('Middle school')">Middle school</a>
                                            <a v-if="competition != 'Dancing' && competition != 'Solo Singing' && competition != 'Fancy Dress' && competition != 'Cooking' && competition != 'Kolam Potti' && competition != 'Dum Charades/Connections'" class="dropdown-item" href="#" @click="setAgeGroup('High school')">High school</a>
                                            <a v-if="competition == 'Solo Singing' || competition == 'Cooking' || competition == 'Kolam Potti' || competition == 'Dum Charades/Connections'" class="dropdown-item" href="#" @click="setAgeGroup('Adults')">Adults</a>
                                            <a v-if="competition == 'Dancing'" class="dropdown-item" href="#" @click="setAgeGroup('Adults/Mixed')">Adults/Mixed</a>
                                            <a v-if="competition == 'Fancy Dress'" class="dropdown-item" href="#" @click="setAgeGroup('Mixed')">Mixed</a>
                                        </base-dropdown>
                                        <base-input alternative class="mb-3" placeholder="Parent Name" form-control
                                            id="parent-name-talent" v-model="parentNameTalent">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Email" 
                                             id="email-talent" v-model="emailTalent" :error="emailTalentError">
                                        </base-input>
                                        <base-input alternative class="mb-3" placeholder="Phone number" form-control
                                             id="phone-number-talent" v-model="phoneNumberTalent"
                                            :error="phoneNumberTalentError">
                                        </base-input>
                                        <base-dropdown class="form-group mb-0 input-group">
                                            <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="No of Participants"
                                                 style="background-color: white;" id="participants" v-model="participants">
                                            </base-input>
                                            <a class="dropdown-item" href="#" @click="setParticipants('1')">1</a>
                                            <a v-if="competition == 'Dum Charades/Connections' || competition == 'Reels'" class="dropdown-item" href="#" @click="setParticipants('2')">2</a>
                                            <a v-if="competition == 'Dancing' || competition == 'Dum Charades/Connections' || competition == 'Reels' || competition == 'Fancy Dress'" class="dropdown-item" href="#" @click="setParticipants('3')">3</a>
                                            <a v-if="competition == 'Dancing' || competition == 'Fancy Dress'" class="dropdown-item" href="#" @click="setParticipants('4')">4</a>
                                            <a v-if="competition == 'Dancing' || competition == 'Fancy Dress'" class="dropdown-item" href="#" @click="setParticipants('5')">5</a>
                                            <a v-if="competition == 'Dancing'" class="dropdown-item" href="#" @click="setParticipants('6')">6</a>
                                        </base-dropdown>

                                        <div style="clear: both;">
                                            <h3>Event entry : $ {{ getTotalCost() }}</h3>
                                        </div>

                                       
                                    </div>
                                    <!-- <base-button type="submit" @click="sendRSVP" class="my-4">Send RSVP</base-button> -->
                                    <div id="paypal-button-container"></div>
                                </form> 
                            </template>
                        </card>
                    </div>
                </div>
            </div>
            <modal :show="showPopup" @close="closePopup" type="notice">
                <h2>{{ successmessage }}</h2>

                <base-button class="mb-3 col-lg-6" type="primary" @click="closePopup()">Ok</base-button>

            </modal>
            <modal :show="showForgotPopup" @close="closeForgotPopup" type="notice">
                <p style="font-weight: 600;">Please provide your registered Email</p>

                <base-input alternative class="mb-3" placeholder="Email" type="email"
                     id="forgot-email" v-model="forgotEmail" :error="forgotEmailError">
                </base-input>

                <div>
                    <base-button style="float: right;" class="mb-3 col-lg-3" type="primary" @click="sendForgotEmail()">Send</base-button>
                    <base-button style="float: right;margin-right: 20px;" class="mb-3 col-lg-3" type="primary" @click="closeForgotPopup()">Cancel</base-button>
                </div>
                
                

            </modal>
            <!-- <modal :show="showMemberAlert" @close="closeMemberAlert" type="notice">
                <h3>{{ membershipAlert }}</h3>

                <br>
                <div>
                    <base-button style="float: right;width: 150px;margin-right: 20px;" class="mb-3 col-lg-6" type="primary" @click="closeMemberAlert()">Non Member</base-button>
                    <base-button style="float: right;width: 150px;margin-right: 20px;" class="mb-3 col-lg-6" type="primary" @click="goToMembership()">Member</base-button>      
                </div>
                

            </modal> -->
        </section>
</template>

<script>

import firebase from '../Firebase.js'
const QRCode = require('qrcode-svg');
import Modal from '../components/Modal.vue';
const otpGenerator = require('otp-generator');

export default {
    components: {
        // 'ejs-dialog': DialogPlugin,
        // 'ejs-button' : ButtonPlugin
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            freeEvent: false,
            adults: '',
            kids: '',
            kid1:0,
            kid2:0,
            kid1Age:'',
            kid2Age:'',
            kid1Enabled:'unset',
            kid2Enabled:'unset',
            kid1Opacity:'100%',
            kid2Opacity:'100%',
            firstNameError: '',
            lastNameError: '',
            phoneNumberError:'',
            vehicleModelError:'',
            emailError:'',
            otpError:'',
            isValid:true,
            successmessage:'',
            rsvpId: '', //'d8XVcmFVxKBcr8w854Jx',
            rsvp: {},
            membershipId:'',
            membershipEmail:'',
            member:false,
            registrationId:'',
            rsvpList:[],
            selectedRSVPName:'',
            selectedRSVPId:'',
            payPalInit:false,
            otpId:'',
            otp:'',
            firstNameSport:'',
            lastNameSport:'',
            emailSports:'',
            phoneNumberSports:'',
            firstNameStudent:'',
            lastNameStudent:'',
            studentGrade:'',
            parentName:'',
            emailYLP:'',
            phoneNumberYLP:'',
            otpYLP:'',
            otpYLPError:'',
            teamNameVolley:'',
            tournament:'',
            captainNameVolley:'',
            emailVolley:'',
            phoneNumberVolley:'',
            studentGradeError: '',
            parentNameError: '',
            emailSportsError:'',
            emailYLPError:'',
            phoneNumberSportsError:'',
            phoneNumberYLPError:'',
            tournamentVolleyError:'',
            teamNameVolleyError:'',
            captainNameVolleyError:'',
            emailVolleyError:'',
            phoneNumberVolleyError:'',
            competition:'',
            firstNameTalent:'',
            lastNameTalent:'',
            ageGroup:'',
            parentNameTalent:'',
            emailTalent:'',
            phoneNumberTalent:'',
            participants:'',
            competitionError:'',
            ageGroupError:'',
            firstNameTalentError:'',
            lastNameTalentError:'',
            parentNameTalentError:'',
            emailTalentError:'',
            phoneNumberTalentError:'',
            active:false,
            paidAdultPrice:0,
            paidKidsPrice:0,
            showPopup: false,
            showForgotPopup: false,
            forgotEmail: '',
            forgotEmailError:'',
            showMemberAlert: true,
            userMembershipId: '',
            membershipIdError: '',
            hasMembership: false,
            forgotenMembershipId: '',
            membershipAlert: 'The membership for the year 2023 has expired as of Dec 31, 2023. Please click "Member" to activate your membership for the year 2024 and avail free entry for this event or click "Non Member" to proceed with RSVP for the event.',
            firstNameWomens:'',
            lastNameWomens:'',
            emailWomens:'',
            phoneNumberWomens:'',
            adultsWomens:'',
            firstNameWomensError:'',
            lastNameWomensError:'',
            participantNameWomens:'',
            participantNameWomensError:'',
            emailWomensError:'',
            phoneNumberWomensError:'',
            // target: "#target",
            // width: '335px',
            // isModal: true,
            // header: 'Dialog',
            // content: 'This is a Dialog with button and primary button.',
            // buttons: [{ click: this.dlgButtonClick, buttonModel: { content: 'OK', isPrimary: true } },
            // { click: this.dlgButtonClick, buttonModel: { content: 'Cancel' }}]
        };
    },
    methods: {
        async loadRsvps(){

        const db = firebase.firestore();

            await db.collection('rsvp')
                .where("eventDateTimestamp", ">=", firebase.firestore.Timestamp.fromDate(new Date(this.getNow(true))))
                .get()
                .then(docs => {
                    docs.forEach(doc => {
                        const rsvp = {
                            rsvpId: doc.id,
                            ...doc.data()
                        };
                        this.rsvpList.push(rsvp);
                    });
                })
                .catch(error => {
                    console.error('Error fetching RSVP items:', error);
                });

        },
        setRSVP(event, id){
            this.selectedRSVPName = event.currentTarget.selectedOptions[0].text;
            this.selectedRSVPId = event.currentTarget.selectedOptions[0].value;
            this.rsvpId = event.currentTarget.selectedOptions[0].value;
            
            this.cleanAndFetch();
        },
        cleanAndFetch(){
            this.membershipId = '';
            this.membershipEmail = '',
            this.member = false,
            this.showPopup = false;
            this.showForgotPopup = false;
            this.forgotEmail = '';
            this.forgotEmailError = '';
            this.showMemberAlert = false,
            this.successmessage='';
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.phoneNumber = '';
            this.adults = '';
            this.kids = '';
            this.kid1 = 0;
            this.kid2 = 0;
            this.kid1Age = '';
            this.kid2Age = '';
            this.otpId='';
            this.member=false;
            this.firstNameSport = '';
            this.lastNameSport = '';
            this.emailSports = '';
            this.phoneNumberSports = '';
            this.firstNameStudent = '';
            this.lastNameStudent = '';
            this.studentGrade = '';
            this.parentName = '';
            this.emailYLP = '';
            this.phoneNumberYLP = '';
            this.otpYLP  = '';
            this.otpYLPError = '';
            this.firstNameSportError = '';
            this.lastNameSportError = '';
            this.firstNameStudentError = '';
            this.lastNameStudentError = '';
            this.studentGradeError = '';
            this.parentNameError = '';
            this.emailSportsError = '';
            this.emailYLPError = '';
            this.teamNameVolley = '',
            this.tournament = '',
            this.captainNameVolley = '',
            this.emailVolley = '',
            this.phoneNumberVolley = '',
            this.phoneNumberSportsError = '';
            this.phoneNumberYLPError = '';
            this.tournamentVolleyError = '',
            this.teamNameVolleyError = '',
            this.captainNameVolleyError ='',
            this.emailVolleyError = '',
            this.phoneNumberVolleyError = '',
            this.competition = '';
            this.firstNameTalent = '';
            this.lastNameTalent = '';
            this.ageGroup = '';
            this.parentNameTalent = '';
            this.emailTalent = '';
            this.phoneNumberTalent = '';
            this.participants = '';
            this.competitionError = '';
            this.ageGroupError = '';
            this.firstNameTalentError = '';
            this.lastNameTalentError = '';
            this.parentNameTalentError = '';
            this.emailTalentError = '';
            this.phoneNumberTalentError = '';
            this.userMembershipId = '';
            this.membershipIdError = '';
            this.forgotenMembershipId = '';
            this.hasMembership = false;
            this.participantNameWomens = '';
            this.firstNameWomens = '';
            this.lastNameWomens = '';
            this.emailWomens = '';
            this.phoneNumberWomens = '';
            this.participantNameWomens = '';
            this.firstNameWomensError = '';
            this.lastNameWomensError = '';
            this.emailWomensError = '';
            this.phoneNumberWomensError = '';
            this.participantNameWomensError = '';

            this.isValid = true;

            this.fetchRSVP();
        },
        memberAlert(){

            let maskedEmail = this.emailMask(this.membershipEmail);

            return 'Membership identified, please check your Registered Email ' + maskedEmail + ' for a OTP and use it to get the member discount ';
        },
        emailMask(email) {
            var maskedEmail = email.replace(/([^@\.])/g, '*').split('')
            var previous = ''
            let j = 0
            for (let i = 0; i < maskedEmail.length; i++) {
                if (i <= 1 || previous === '.') {
                    maskedEmail[i] = email[i]
                } else if (maskedEmail[i] === '@') {
                    j = i - 1
                    maskedEmail[i] = email[j] + email[i] + email[i + 1]
                }
                previous = email[i]
            }
            return maskedEmail.join('')
        },
        async fetchRSVP() {
            // Get a reference to the Firestore database
            const db = firebase.firestore();

            await db.collection('rsvp')
                .doc(this.rsvpId)
                .get()
                .then(doc => {
                if (doc.exists) {
                    this.rsvp = { id: doc.id, ...doc.data() };
                } else {
                    console.log('RSVP item not found');
                }
                })
                .catch(error => {
                console.error('Error fetching RSVP item:', error);
                });

            if(this.rsvp.active){
                this.active = true;
                if(!this.rsvp.freeEvent && !this.payPalInit){
                    this.initPayPalButton();
                }
            }
            else{
                this.active = false;
            }
            
           
        },
        async saveIntermediateRSVP(err){

            const db = firebase.firestore();

            await db.collection('RSVPIncompleteRegistration').add({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phoneNumber: this.phoneNumber,
                    adults: this.adults,
                    kids: this.kids,
                    rsvpId: this.rsvp.id,
                    membershipId: this.membershipId,
                    rsvpName: this.selectedRSVPName,
                    rsvpEventDate: this.rsvp.eventDate,
                    adultFee: this.paidAdultPrice,
                    kidsFee: this.paidKidsPrice,
                    totalFee: this.getTotalCost().toString(),
                    error: err,
                });

        },
        sendRSVP(){
            let details = {};
            this.saveRSVP(details);

        },
        async saveRSVP(details){

            // Get a reference to the Firestore database
            const db = firebase.firestore();

            let reg = null;
            let firstName = '';
            let lastName = '';
            let emailTo = '';
            let phoneNo = '';
            let participantNameWomens = '';

            if(this.rsvp.RSVPType == 'General Event'){
                emailTo = this.email;
                phoneNo = this.phoneNumber;
                firstName = this.firstName;
                lastName = this.lastName;
             }
             if(this.rsvp.RSVPType == 'Women\’s Event'){
                emailTo = this.emailWomens;
                phoneNo = this.phoneNumberWomens;
                firstName = this.firstNameWomens;
                lastName = this.lastNameWomens;
                participantNameWomens = this.participantNameWomens;
                this.adults = this.adultsWomens;
             }
             if(this.rsvp.RSVPType == 'Sport Event'){
                emailTo = this.emailSports;
                phoneNo = this.phoneNumberSports;
                firstName = this.firstNameSport;
                lastName = this.lastNameSport;
             }
             if(this.rsvp.RSVPType == 'Volley Ball'){
                emailTo = this.emailVolley;
                phoneNo = this.phoneNumberVolley;
                firstName = this.teamNameVolley;
                lastName = this.captainNameVolley;
             }
             if(this.rsvp.RSVPType == 'YLP Event'){
                emailTo = this.emailYLP;
                phoneNo = this.phoneNumberYLP;
                firstName = this.firstNameStudent;
                lastName = this.lastNameStudent;
             }
             if(this.rsvp.RSVPType == 'Talent Event'){
                emailTo = this.emailTalent;
                phoneNo = this.phoneNumberTalent;
                firstName = this.firstNameTalent;
                lastName = this.lastNameTalent;
             }

            if(!this.rsvp.freeEvent){
                reg = await db.collection('RSVPRegistration').add({
                    firstName: firstName,
                    lastName: lastName,
                    email: emailTo,
                    phoneNumber: phoneNo,
                    adults: this.adults,
                    kids: this.kids,
                    rsvpId: this.rsvp.id,
                    paypalId: details.purchase_units[0].payments.captures[0].id,
                    payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                    payerEmail:details.payer.email_address,
                    paidDateTime: details.create_time,
                    paidStatus: details.status,
                    paidAmount: details.purchase_units[0].amount.value,
                    membershipId: this.membershipId,
                    rsvpName: this.selectedRSVPName,
                    rsvpEventDate: this.rsvp.eventDate,
                    firstNameSport: this.firstNameSport,
                    lastNameSport: this.lastNameSport,
                    emailSports: this.emailSports,
                    phoneNumberSports: this.phoneNumberSports,
                    firstNameStudent: this.firstNameStudent,
                    lastNameStudent: this.lastNameStudent,
                    studentGrade: this.studentGrade,
                    parentName: this.parentName,
                    emailYLP: this.emailYLP,
                    phoneNumberYLP: this.phoneNumberYLP,
                    competition: this.competition,
                    firstNameTalent: this.firstNameTalent,
                    lastNameTalent: this.lastNameTalent,
                    ageGroup: this.ageGroup,
                    parentNameTalent: this.parentNameTalent,
                    emailTalent: this.emailTalent,
                    phoneNumberTalent: this.phoneNumberTalent,
                    participants: this.participants,
                    teamNameVolley : this.teamNameVolley,
                    tournament : this.tournament,
                    captainNameVolley : this.captainNameVolley,
                    emailVolley : this.emailVolley ,
                    phoneNumberVolley : this.phoneNumberVolley,
                    participantNameWomens : participantNameWomens,
                });
            } 
            else{
                reg =  await db.collection('RSVPRegistration').add({
                    firstName: firstName,
                    lastName: lastName,
                    email: emailTo,
                    phoneNumber: phoneNo,
                    adults: this.adults,
                    kids: this.kids,
                    rsvpId: this.rsvp.id,
                    rsvpName: this.selectedRSVPName,
                    paypalId: '',
                    payerName: '',
                    payerEmail: '',
                    paidDateTime: '',
                    paidStatus: '',
                    paidAmount: '',
                    rsvpEventDate: this.rsvp.eventDate,
                    firstNameSport: this.firstNameSport,
                    lastNameSport: this.lastNameSport,
                    emailSports: this.emailSports,
                    phoneNumberSports: this.phoneNumberSports,
                    firstNameStudent: this.firstNameStudent,
                    lastNameStudent: this.lastNameStudent,
                    studentGrade: this.studentGrade,
                    parentName: this.parentName,
                    emailYLP: this.emailYLP,
                    phoneNumberYLP: this.phoneNumberYLP,
                    competition: this.competition,
                    firstNameTalent: this.firstNameTalent,
                    lastNameTalent: this.lastNameTalent,
                    ageGroup: this.ageGroup,
                    parentNameTalent: this.parentNameTalent,
                    emailTalent: this.emailTalent,
                    phoneNumberTalent: this.phoneNumberTalent,
                    participants: this.participants,
                    teamNameVolley : this.teamNameVolley,
                    tournament : this.tournament,
                    captainNameVolley : this.captainNameVolley,
                    emailVolley : this.emailVolley ,
                    phoneNumberVolley : this.phoneNumberVolley,
                });

            }

            this.registrationId = reg.id;

            this.setTotalRegistered();

            this.sendEmail();

            // Clear the form inputs
            this.firstName = '';
            this.lastName = '';
           
            this.phoneNumber = '';
            this.adults = '';
            this.kids = '';
            this.kid1 = 0;
            this.kid2 = 0;
            this.kid1Age = '';
            this.kid2Age = '';
            this.otpId='';
            this.member=false;
            this.firstNameSport = '';
            this.lastNameSport = '';
            this.emailSports = '';
            this.phoneNumberSports = '';
            this.firstNameStudent = '';
            this.lastNameStudent = '';
            this.studentGrade = '';
            this.parentName = '';
            this.emailYLP = '';
            this.phoneNumberYLP = '';
            this.otpYLP  = '';
            this.teamNameVolley = '',
            this.tournament = '',
            this.captainNameVolley = '',
            this.emailVolley = '',
            this.phoneNumberVolley = '',
            this.otpYLPError = '';
            this.firstNameSportError = '';
            this.lastNameSportError = '';
            this.firstNameStudentError = '';
            this.lastNameStudentError = '';
            this.studentGradeError = '';
            this.parentNameError = '';
            this.emailSportsError = '';
            this.emailYLPError = '';
            this.phoneNumberSportsError = '';
            this.phoneNumberYLPError = '';
            this.competition = '';
            this.competitionError = '';
            this.ageGroupError = '';
            this.firstNameTalent = '';
            this.lastNameTalent = '';
            this.ageGroup = '';
            this.parentNameTalent = '';
            this.emailTalent = '';
            this.phoneNumberTalent = '';
            this.participants = '';
            this.firstNameTalentError = '';
            this.lastNameTalentError = '';
            this.parentNameTalentError = '';
            this.emailTalentError = '';
            this.phoneNumberTalentError = '';
            this.tournamentVolleyError = '';
            this.teamNameVolleyError = '',
            this.captainNameVolleyError ='',
            this.emailVolleyError = '',
            this.phoneNumberVolleyError = '',
            this.successmessage = 'The Registration is successful, confirmation mail has been sent to ' + emailTo;
            this.showPopup = true;
            this.showMemberAlert = false;
            this.email = '';
            this.userMembershipId = '';
            this.forgotenMembershipId = '';
            this.membershipIdError = '';
            this.hasMembership = false;
            this.firstNameWomens = '';
            this.lastNameWomens ='';
            this.emailWomens = '';
            this.phoneNumberWomens = '';
            this.participantNameWomens = '';
            this.firstNameWomensError = '';
            this.lastNameWomensError ='';
            this.emailWomensError = '';
            this.phoneNumberWomensError = '';
            this.participantNameWomensError = '';
            
        },
        async sendEmail(){

             // Get a reference to the Firestore database
             const db = firebase.firestore();

             // Generate a QR code SVG string
             const qrCode = new QRCode({
                content: btoa(this.registrationId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
                container: 'svg',
                join: true
             }).svg();

             let emailTo = '';
             let userName = '';
             let participantNameWomens = '';

             if(this.rsvp.RSVPType == 'General Event'){
                emailTo = this.email;
                userName = this.firstName + ' ' + this.lastName;
             }
             if(this.rsvp.RSVPType == 'Women\’s Event'){
                emailTo = this.emailWomens;
                userName = this.firstNameWomens + ' ' + this.lastNameWomens;
                participantNameWomens = this.participantNameWomens;
             }
             if(this.rsvp.RSVPType == 'Sport Event'){
                emailTo = this.emailSports;
                userName = this.firstNameSport + ' ' + this.lastNameSport;
             }
             if(this.rsvp.RSVPType == 'Volley Ball'){
                emailTo = this.emailVolley;
                userName = this.teamNameVolley;
             }
             if(this.rsvp.RSVPType == 'YLP Event'){
                emailTo = this.emailYLP;
                userName = this.firstNameStudent + ' ' + this.lastNameStudent;
             }
             if(this.rsvp.RSVPType == 'Talent Event'){
                emailTo = this.emailTalent;
                userName = this.firstNameTalent + ' ' + this.lastNameTalent;
             }
             

             await db.collection('email').add({
                    from: 'contact@tamilsneham.org',
                    subject: 'RSVP Confirmation',
                    RSVPType: this.rsvp.RSVPType,
                    email: emailTo,
                    adults: this.adults,
                    kids: this.kids,
                    adultsFee: this.paidAdultPrice,
                    kidsFee: this.paidKidsPrice,
                    rsvpName: this.rsvp.name,
                    rsvpEventDate: this.formatDate(this.rsvp.eventDate),
                    qrCode: qrCode,
                    registrationId: this.registrationId,
                    userName: userName,
                    participantNameWomens: participantNameWomens,
                    firstNameSport: this.firstNameSport,
                    lastNameSport : this.lastNameSport,
                    emailSports: this.emailSports,
                    phoneNumberSports: this.phoneNumberSports,
                    firstNameStudent: this.firstNameStudent,
                    lastNameStudent : this.lastNameStudent,
                    studentGrade: this.studentGrade,
                    parentName: this.parentName,
                    emailYLP: this.emailYLP,
                    phoneNumberYLP: this.phoneNumberYLP,
                    competition: this.competition,
                    firstNameTalent: this.firstNameTalent,
                    lastNameTalent: this.lastNameTalent,
                    ageGroup: this.ageGroup,
                    parentNameTalent: this.parentNameTalent,
                    emailTalent: this.emailTalent,
                    phoneNumberTalent: this.phoneNumberTalent,
                    participants: this.participants,
                    entryFeeTalentEvent : this.rsvp.entryFeeTalentEvent == undefined ? 0 : this.rsvp.entryFeeTalentEvent,
                    entryFeeYLPEventMember: this.rsvp.entryFeeYLPEventMember == undefined ? 0 : this.rsvp.entryFeeYLPEventMember,
                    entryFeeYLPEventNonmember: this.entryFeeYLPEventNonmember == undefined ? 0 : this.rsvp.entryFeeYLPEventNonmember,
                    teamNameVolley : this.teamNameVolley,
                    tournament : this.tournament,
                    captainNameVolley : this.captainNameVolley,
                    emailVolley : this.emailVolley ,
                    phoneNumberVolley : this.phoneNumberVolley,
                    entryFeeVolleyBall : this.rsvp.entryFeeVolleyBall == undefined ? 0 : this.rsvp.entryFeeVolleyBall,
                    totalPaid: this.getTotalCost().toString(),
                    mailType: 2,
                });
            
        },
        
        forgotMemberId(){
            this.showForgotPopup = true;
        },
        async sendForgotEmail(){
            
            const db = firebase.firestore();

            let querySnapshot = null;

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.forgotEmail)) {

                querySnapshot = await db.collection('members')
                .where('email', '==', this.forgotEmail.toLowerCase())
                .where('status', '==', '1')
                .get();

                if(!querySnapshot.empty){
                    this.forgotenMembershipId = querySnapshot.docs[0].data().member_id;
                    // this.membershipEmail = querySnapshot.docs[0].data().email;

                    this.sendMembershipId();
                }
                else{
                    this.forgotEmailError = "The given Email doesnot exists in the system";
                }
            }
            else{
                this.forgotEmailError = "Please enter a valid email address";
            }
            

        },
        async sendMembershipId(){

            const db = firebase.firestore();

            await db.collection('email').add({
                from: 'contact@tamilsneham.org',
                subject: 'Membership Id',
                email: this.forgotEmail,
                member_id: this.forgotenMembershipId,
                mailType: 4,
                
            });

            this.closeForgotPopup();
            this.successmessage = "Retreived Membership Id has been sent to your registered Email.";
            this.showPopup = true;

        },
        calculateYLPCost(){
            let finalYLPPrice = this.rsvp.entryFeeYLPEventNonmember ? this.rsvp.entryFeeYLPEventNonmember : 0;

            if (this.member) {
                finalYLPPrice = this.rsvp.entryFeeYLPEventMember;
            }

            return finalYLPPrice;

        },
        calculateTalentCost(){
            let finalTalentPrice = this.rsvp.entryFeeTalentEvent ? this.rsvp.entryFeeTalentEvent : 0;

            if(this.participants == ''){
                finalTalentPrice = 0;
            }
            else{
                finalTalentPrice = finalTalentPrice * parseInt(this.participants);
            }
            
            return finalTalentPrice;

        },
        calculateCost() {
            // Calculate cost based on the number of participants

            if(this.rsvp.RSVPType == 'General Event'){
                let finalAdultPrice = this.rsvp.entryFeeAdultsNonmember ? this.rsvp.entryFeeAdultsNonmember : 0;
                let finalKidsPrice = this.rsvp.entryFeeKidsNonmember ? this.rsvp.entryFeeKidsNonmember: 0;

                if (this.member) {
                    // eslint-disable-next-line no-const-assign
                    finalAdultPrice = this.rsvp.entryFeeAdultsMember;
                    // eslint-disable-next-line no-const-assign
                    finalKidsPrice = this.rsvp.entryFeeKidsMember;
                }
                
                this.paidAdultPrice = finalAdultPrice;
                this.paidKidsPrice = finalKidsPrice;
                
                return finalAdultPrice * this.adults + finalKidsPrice * this.kids;
            }
            else if(this.rsvp.RSVPType == 'Women\’s Event'){
                let finalAdultPrice = this.rsvp.entryFeeWomensEventNonmember ? this.rsvp.entryFeeWomensEventNonmember : 0;

                if (this.member) {
                    // eslint-disable-next-line no-const-assign
                    finalAdultPrice = this.rsvp.entryFeeWomensEventMember;
                    
                }

                this.paidAdultPrice = finalAdultPrice;

                return finalAdultPrice;
            }
            
        },
        async checkMemberExistence(email, phoneNumber) {

            const db = firebase.firestore();

            try {

                console.log('Email:', email);
                console.log('Phone', phoneNumber);

            // Query the members collection based on the email or phone number
            const querySnapshot = await db.collection('members')
                .where('email', '==', email == "" ? "_____" : email.toLowerCase())
                .where('status', '==', '1')
                .get();
            
            const querySnapshot1 = await db.collection('members')
                .where('telephone', '==', phoneNumber == "" ? "000000" : phoneNumber )
                .where('status', '==', '1')
                .get();

            if(!querySnapshot.empty || !querySnapshot1.empty){

                if(!querySnapshot.empty){
                    this.membershipId = querySnapshot.docs[0].data().member_id;
                    this.membershipEmail = querySnapshot.docs[0].data().email;
                }
                else{
                    this.membershipId = querySnapshot1.docs[0].data().member_id;
                    this.membershipEmail = querySnapshot1.docs[0].data().email;
                }

                if(this.membershipId != ''){
                    const snap = await db.collection('RSVPRegistration')
                        .where('membershipId', '==', this.membershipId)
                        .where('rsvpId', '==', this.rsvpId)
                        .get();

                        if(snap.empty){
                            return true;
                        }
                        else{
                            this.membershipId = '';
                            this.membershipEmail = '';
                            return false;
                        }
                }

                return true;
            }
            else{
                return false;
            }

            // return !querySnapshot.empty || !querySnapshot1.empty; // Return true if a matching member document is found, false otherwise


            } catch (error) {
            console.error('Error checking member existence:', error);
            return false;
            }
        },
        getNow(isDate, minutes){

            const today = new Date();

            if(minutes > 0){today.setMinutes(today.getMinutes() + 5); }

            const date = today.getFullYear() +'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;

            if(isDate){
                return date;
            }
            return dateTime;
        },
        async verifyAndSendOTP(memberEmail, membershipId){
            
            if(this.otpId == ''){   
                const db = firebase.firestore();

                var result = await db.collection('otp').add({
                        timestamp: firebase.firestore.Timestamp.fromDate(new Date(this.getNow(false, 5))),
                        email:memberEmail,
                        membershipId: membershipId
                    });

                if(!result.empty){
                    this.otpId = result.id;
                    
                }
            }

        },
        async validateMemberId(){

            const db = firebase.firestore();

            console.log(this.userMembershipId);
            
            const result = await db.collection('members')
                .where('member_id', '==', this.userMembershipId == "" ? 1111 : parseInt(this.userMembershipId))
                .get();

            if(!result.empty){

                let member = result.docs[0].data();

                let isRegistered = await this.checkRegistration(member.email);

                if(!isRegistered){
                    this.hasMembership = true;
                    this.member = true;
                    this.membershipId = this.userMembershipId;

                    if(this.rsvp.RSVPType == 'General Event'){
                        this.firstName = member.fname;
                        this.lastName = member.lname;
                        this.email = member.email;
                        this.phoneNumber = member.telephone;
                    }
                    else if(this.rsvp.RSVPType == 'Women\’s Event'){
                        this.firstNameWomens = member.fname;
                        this.lastNameWomens = member.lname;
                        this.emailWomens = member.email;
                        this.phoneNumberWomens = member.telephone;
                    }
                   
                    this.membershipIdError = '';
                }
                else{
                    this.hasMembership = false;
                    this.member = false;

                    if(this.rsvp.RSVPType == 'General Event'){
                        this.firstName = '';
                        this.lastName = '';
                        this.email = '';
                        this.phoneNumber = '';
                    }
                    else if(this.rsvp.RSVPType == 'Women\’s Event'){
                        this.firstNameWomens = '';
                        this.lastNameWomens = '';
                        this.emailWomens = '';
                        this.phoneNumberWomens = '';
                    }

                    this.membershipIdError = "This Member Id has been used already";
                }

            }
            else{
                this.hasMembership = false;
                this.member = false;
                this.member = false;
               
                if(this.rsvp.RSVPType == 'General Event'){
                    this.firstName = '';
                    this.lastName = '';
                    this.email = '';
                    this.phoneNumber = '';
                }
                else if(this.rsvp.RSVPType == 'Women\’s Event'){
                    this.firstNameWomens = '';
                    this.lastNameWomens = '';
                    this.emailWomens = '';
                    this.phoneNumberWomens = '';
                }

                this.membershipIdError = "Membership does not exists";
            }

        },
        async validateOTP(){

            const db = firebase.firestore();
            
            var result =await db.collection('otp')
                .doc(this.otpId)
                .get();

            if(!result.empty){
                if(this.otp == result.data().otp){
                    if(result.data().timestamp.toDate() >= new Date(this.getNow(false, 0)))
                    {
                        this.member = true;
                        this.otpError = '';
                    }
                    else{
                        this.member = false;
                        this.otpError = 'OTP Expired';
                    }
                }
                else{
                    this.otpError = 'Invalid OTP';
                }
            }
            else{
                this.otpError = 'Invalid OTP';
            }
        },
        async validateYLPOTP(){

            const db = firebase.firestore();

            var result =await db.collection('otp')
                .doc(this.otpId)
                .get();

            if(!result.empty){
                if(this.otpYLP == result.data().otp){
                    if(result.data().timestamp.toDate() >= new Date(this.getNow(false, 0)))
                    {
                        this.member = true;
                        this.otpYLPError = '';
                    }
                    else{
                        this.member = false;
                        this.otpYLPError = 'OTP Expired';
                    }
                }
                else{
                    this.otpYLPError = 'Invalid OTP';
                }
            }
            else{
                this.otpYLPError = 'Invalid OTP';
            }
            },
        async checkRegistration(email) {

            const db = firebase.firestore();

            try {

            // Query the members collection based on the email or phone number
            const querySnapshot = await db.collection('RSVPRegistration')
                .where('email', '==', email)
                .where('rsvpId', '==', this.rsvpId)
                .get();

            if(!querySnapshot.empty){
                this.emailError = 'This email has been used already for Registration';
                this.isValid=false;
                return true;
            }
            else{

                this.emailError = '';
                this.isValid=true;
                return false;
            }

            // return !querySnapshot.empty || !querySnapshot1.empty; // Return true if a matching member document is found, false otherwise


            } catch (error) {
            console.error('Error checking member registration:', error);
            return false;
            }
        },
        async setTotalRegistered() {

            const db = firebase.firestore();

            try {

            // Query the members collection based on the email or phone number
            const querySnapshot = await db.collection('RSVPRegistration')
                .where('rsvpId', '==', this.rsvpId)
                .get();

            if(!querySnapshot.empty){
              
                let count = 0;
                let totalAdults = 0;
                let totalKids = 0;
                let totalMembers = 0;
                let totalNonMembers = 0;

                querySnapshot.forEach(async doc => {
                // Extract the RSVP data from each document
                    count++;

                    const rsvp = {
                        rsvpRegId: doc.id,
                        ...doc.data()
                        };
                    
                        totalAdults += rsvp.adults == "" ? 0 : parseInt(rsvp.adults);
                        totalKids += rsvp.kids == "" ? 0 : parseInt(rsvp.kids);
                        rsvp.membershipId != '' ? totalMembers++ : totalNonMembers++; 
                    
                    });

                    this.rsvp.totalReg = count;
                    this.rsvp.totalAdults = totalAdults;
                    this.rsvp.totalKids = totalKids;
                    this.rsvp.totalMember = totalMembers;
                    this.rsvp.totalNonMember = totalNonMembers;

                    await db.collection('rsvp')
                        .doc(this.rsvp.id)
                        .update(this.rsvp)
                        .then(() => {
                            console.log('RSVP updated successfully!');
                        })
                        .catch(error => {
                            console.error('Error updating RSVP:', error);
                        });
                
                return true;
            }
            else{
              
                return false;
            }
         
            } catch (error) {
                console.error('Error checking member registration:', error);
                return false;
                }
            },

        async validateEmailAndPhoneNumber(email, phoneNumber) {

            //const registeredAlready = await this.checkRegistration(email);

            //if(!registeredAlready){
            const memberExists = await this.checkMemberExistence(email, phoneNumber);

            memberExists = false; //Temporary block of Member verification

            if (memberExists) {
                // Perform additional actions if the member exists
                console.log('Member exists');
                //this.member = true;
                this.verifyAndSendOTP(this.membershipEmail, this.membershipId);

            } else {
                // Handle the case where the member does not exist
                console.log('Member not exists');
                this.otpId = '';
                this.member = false;
            }
            //}

            
        },
        getTotalCost(){

            if(this.rsvp.RSVPType == "General Event")
                return this.calculateCost();

            if(this.rsvp.RSVPType == "Women\’s Event")
                return this.calculateCost();

            if(this.rsvp.RSVPType == "Sport Event")
                return this.rsvp.entryFeeSportEvent;

            if(this.rsvp.RSVPType == "Volley Ball")
                return this.rsvp.entryFeeVolleyBall;

            if(this.rsvp.RSVPType == 'YLP Event')
                return this.calculateYLPCost();

            if(this.rsvp.RSVPType == 'Talent Event')
                return this.calculateTalentCost();

        },
        closePopup(){

            this.showPopup = false;
        },
        closeForgotPopup(){

            this.showForgotPopup = false;
        },
        closeMemberAlert(){
            this.showMemberAlert = false;
        },
        goToMembership(){
            this.$router.push('/MembershipRegistration');
        },
        initPayPalButton() {
            // eslint-disable-next-line no-undef

            this.payPalInit = true;

            paypal
                .Buttons({
                createOrder: (data, actions) => {
                
                    // if(!this.isValid){return;}

                    this.firstNameError = '';
                    this.lastNameError = '';
                    this.phoneNumberError = '';
                    this.emailError = '';

                    this.firstNameWomensError = '';
                    this.lastNameWomensError = '';
                    this.phoneNumberWomensError = '';
                    this.emailWomensError = '';
                    this.participantNameWomensError = '';

                    this.firstNameSportError = '';
                    this.lastNameSportError = '';
                    this.phoneNumberSportsError = '';
                    this.emailSportsError = '';

                    this.tournamentVolleyError = '',
                    this.teamNameVolleyError = '',
                    this.captainNameVolleyError ='',
                    this.emailVolleyError = '',
                    this.phoneNumberVolleyError = '',

                    this.firstNameStudentError = '';
                    this.lastNameStudentError = '';
                    this.studentGradeError = '';
                    this.parentNameError = '';
                    this.phoneNumberYLPError = '';
                    this.emailYLPError = '';

                    this.competitionError = '';
                    this.ageGroupError = '';
                    this.firstNameTalentError = '';
                    this.lastNameTalentError = '';
                    this.phoneNumberTalentError = '';
                    this.emailTalentError = '';

                    this.isValid = true;

                    if(this.rsvp.RSVPType == 'General Event'){
                        if(this.firstName == ''){this.firstNameError = 'First name required'; this.isValid=false}
                        if(this.lastName == ''){this.lastNameError = 'Last name required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumber)){this.phoneNumberError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumber == ''){this.phoneNumberError = 'Phone number is required'; this.isValid=false}
                        if(!this.validateEmail(this.email)){this.emailError = 'Please enter a valid email address'; this.isValid=false}
                    }
                    if(this.rsvp.RSVPType == 'Women\’s Event'){
                        if(this.firstNameWomens == ''){this.firstNameWomensError = 'First name required'; this.isValid=false}
                        if(this.lastNameWomens == ''){this.lastNameWomensError = 'Last name required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumberWomens)){this.phoneNumberWomensError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumberWomens == ''){this.phoneNumberWomensError = 'Phone number is required'; this.isValid=false}
                        if(!this.validateEmail(this.emailWomens)){this.emailWomensError = 'Please enter a valid email address'; this.isValid=false}
                        //if(this.emailWomens == ''){this.emailWomensError = 'Email is required'; this.isValid=false}
                        if(this.participantNameWomens == ''){this.participantNameWomensError = 'Participant name is required'; this.isValid=false}
                    }
                    if(this.rsvp.RSVPType == 'Sport Event'){
                        if(this.firstNameSport == ''){this.firstNameSportError = 'First name required'; this.isValid=false}
                        if(this.lastNameSport == ''){this.lastNameSportError = 'Last name required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumberSports)){this.phoneNumberSportsError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumberSports == ''){this.phoneNumberSportsError = 'Phone number is required'; this.isValid=false}
                        if(!this.validateEmail(this.emailSports)){this.emailSportsError = 'Please enter a valid email address'; this.isValid=false}
                        //if(this.emailSports == ''){this.emailSportsError = 'Email is required'; this.isValid=false}
                    }
                    if(this.rsvp.RSVPType == 'Volley Ball'){
                        if(this.tournament == ''){this.tournamentVolleyError = 'Tournament required'; this.isValid=false}
                        if(this.teamNameVolley == ''){this.teamNameVolleyError = 'Team name required'; this.isValid=false}
                        if(this.captainNameVolley == ''){this.captainNameVolleyError = 'Captain name required'; this.isValid=false}
                        if(!this.validateEmail(this.emailVolley)){this.emailVolleyError = 'Please enter a valid email address'; this.isValid=false}
                        //if(this.emailVolley == ''){this.emailVolleyError = 'Email is required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumberVolley)){this.phoneNumberVolleyError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumberVolley == ''){this.phoneNumberVolleyError = 'Phone number is required'; this.isValid=false}
                    }
                    if(this.rsvp.RSVPType == 'YLP Event'){
                        if(this.firstNameStudent == ''){this.firstNameStudentError = 'First name required'; this.isValid=false}
                        if(this.lastNameStudent == ''){this.lastNameStudentError = 'Last name required'; this.isValid=false}
                        if(this.studentGrade == ''){this.studentGradeError = 'Student Grade required'; this.isValid=false}
                        if(this.parentName == ''){this.parentNameError = 'Parent name required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumberYLP)){this.phoneNumberYLPError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumberYLP == ''){this.phoneNumberYLPError = 'Phone number is required'; this.isValid=false}
                        if(!this.validateEmail(this.emailYLP)){this.emailYLPError = 'Please enter a valid email address'; this.isValid=false}
                        //if(this.emailYLP == ''){this.emailYLPError = 'Email is required'; this.isValid=false}
                    }
                    if(this.rsvp.RSVPType == 'Talent Event'){
                        if(this.competition == ''){this.competitionError = 'Competition required'; this.isValid=false}
                        if(this.ageGroup == ''){this.ageGroupError = 'Age Group required'; this.isValid=false}
                        if(this.firstNameTalent == ''){this.firstNameTalentError = 'First name required'; this.isValid=false}
                        if(this.lastNameTalent == ''){this.lastNameTalentError = 'Last name required'; this.isValid=false}
                        if(!this.validatePhoneNumber(this.phoneNumberTalent)){this.phoneNumberTalentError = 'Please enter a valid Phone number without any special characters'; this.isValid=false}
                        // if(this.phoneNumberTalent == ''){this.phoneNumberTalentError = 'Phone number is required'; this.isValid=false}
                        if(!this.validateEmail(this.emailTalent)){this.emailTalentError = 'Please enter a valid email address'; this.isValid=false}
                        //if(this.emailTalent == ''){this.emailTalentError = 'Email is required'; this.isValid=false}
                    }

                    if(!this.isValid){return;}

                    this.saveIntermediateRSVP('Partial');

                    return actions.order.create({
                    purchase_units: [
                        {
                            description: 'RSVP for ' + this.selectedRSVPName,
                            amount: {
                                value: this.getTotalCost(),
                            },
                        },
                    ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                    // Handle successful payment here
                    // console.log(details);

                    this.saveRSVP(details);

                    });
                },
                onError: (err) => {
                    // Handle errors here
                    console.error(err);

                    this.saveIntermediateRSVP(err);

                },
                })
                .render("#paypal-button-container");
        },
        setAdultsWomens(count){
            this.adultsWomens = count;
        },
        setAdults(){
            let count = this.adults;

            if(count == 3){

                this.kid1Enabled = 'unset';
                this.kid1Opacity = '100%'
                this.kid2Enabled = 'none';
                this.kid2Opacity = '20%'
                this.kid2 = 0;
                this.kid2Age = '';
                this.setKids(1);

            }
            else if(count == 4){
                this.kid1Enabled = 'none';
                this.kid1Opacity = '20%'
                this.kid2Enabled = 'none';
                this.kid2Opacity = '20%'
                this.kid1 = 0;
                this.kid2 = 0;
                this.kid1Age = '';
                this.kid2Age = '';
                this.setKids(1);
            }
            else{
                this.kid1Enabled = 'unset';
                this.kid2Enabled = 'unset';
                this.kid1Opacity = '100%'
                this.kid2Opacity = '100%'
            }

        },
        setKids(count){
            this.kids = this.kid1 + this.kid2;
        },
        setKid1(){

            if( this.adults > 3){return;}

            let age = this.kid1Age;
            if(age > this.rsvp.kidsAgeTo){
                this.successmessage = "Kid's more than " + this.rsvp.kidsAgeTo + " years old are considered adult. Please add them as adult";
                this.showPopup = true;
                this.kid1 = 0;
                this.kid1Age = '';

            }
            else if(age < this.rsvp.kidsAgeFrom){
                this.successmessage = "Entry for Kid's less than " + this.rsvp.kidsAgeFrom + " years old are free.";
                this.showPopup = true;
                this.kid1 = 0;
                this.kid1Age = '';
            }
            else{
                this.showPopup = false;
                this.kid1 = 1;
            }

            this.setKids(1);
            

        },
        setKid2(){

            if( this.adults > 2){return;}

            let age = this.kid2Age;

            if(age > this.rsvp.kidsAgeTo){
                this.successmessage = "Kid's more than " + this.rsvp.kidsAgeTo + " years old are considered adult. Please add them as adult";
                this.showPopup = true;
                this.kid2 = 0;
                this.kid2Age = '';
            }
            else if(age < this.rsvp.kidsAgeFrom){
                this.successmessage = "Entry for Kid's less than " + this.rsvp.kidsAgeFrom + " years old are free.";
                this.showPopup = true;
                this.kid2 = 0;
                this.kid2Age = '';
            }
            else{
                this.showPopup = false;
                this.kid2 = 1;
            }

            this.setKids(1);

        },
        setStudentGrade(grade){
            this.studentGrade = grade;
        },
        setTournament(tournament){
            this.tournament = tournament;
        },
        setCompetiton(comp){
            this.competition = comp;
            // if(comp == 'Dancing'){
            //     this.setParticipants('4');
            // }
            // else{
                this.setParticipants('1');
            // }
            
            this.setAgeGroup('');
        },
        setParticipants(count){
            this.participants = count;
        },
        setAgeGroup(ageGroup){
            this.ageGroup = ageGroup;
        },
        validateWomensEmail(){
            console.log('Email validation-', this.emailWomens);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.emailWomens)) {
                console.log('Email validation-true', this.emailWomens);
                this.emailWomensError = '';
                this.isValid=true;
                this.validateEmailAndPhoneNumber(this.emailWomens, this.phoneNumberYLP);
            } else {
                console.log('Email validation-false', this.emailWomens);
                this.emailWomensError = 'Please enter a valid email address';
                this.isValid=false;
            }
        },
        validateYLPEmail(){
            console.log('Email validation-', this.emailYLP);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.emailYLP)) {
                console.log('Email validation-true', this.emailYLP);
                this.emailError = '';
                this.isValid=true;
                this.validateEmailAndPhoneNumber(this.emailYLP, this.phoneNumberYLP);
            } else {
                console.log('Email validation-false', this.emailYLP);
                this.emailError = 'Please enter a valid email address';
                this.isValid=false;
            }
        },
        validateVolleyEmail(){
            console.log('Email validation-', this.emailVolley);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.emailVolley)) {
                console.log('Email validation-true', this.emailVolley);
                this.emailVolleyError = '';
                this.isValid=true;
                //this.validateEmailAndPhoneNumber(this.emailYLP, this.phoneNumberYLP);
            } else {
                console.log('Email validation-false', this.emailVolley);
                this.emailVolleyError = 'Please enter a valid email address';
                this.isValid=false;
            }
        },
        validateEmail(email) {
            console.log('Email validation-', email);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(email)) {
                return true;
            } else {
               return false;
            }

            
        },
        validateYLPPhoneNumber() {
            console.log('Phone validation-', this.emailYLP);

            const regex = /^\d{10}$/;

            if (regex.test(this.phoneNumberYLP)) {
                console.log('Phone validation-true', this.phoneNumberYLP);
                this.phoneNumberError = '';
                this.isValid=true;
                this.validateEmailAndPhoneNumber(this.emailYLP, this.phoneNumberYLP);
               
            } else {
                console.log('Phone validation-false', this.phoneNumberYLP);
                this.phoneNumberError = 'Please enter a valid Phone number without any special characters';
                this.isValid=false;
            }

            
        },
        validateVolleyPhoneNumber() {
           
            const regex = /^\d{10}$/;

            if (regex.test(this.phoneNumberVolley)) {
                console.log('Phone validation-true', this.phoneNumberVolley);
                this.phoneNumberVolleyError = '';
                this.isValid=true;
               
            } else {
                console.log('Phone validation-false', this.phoneNumberVolley);
                this.phoneNumberVolleyError = 'Please enter a valid Phone number without any special characters';
                this.isValid=false;
            }

            
        },
        validatePhoneNumber(phone) {

            const regex = /^\d{10}$/;

            if (regex.test(phone)) {
                return true;
               
            } else {
                return false;
            }

            
        },
        formatDate(date) {

            if(date == undefined) return '';

            const formattedDate = new Date(date).toUTCString();

            // Split the formatted date into day, month, and year parts
            const [weekday, day, month, year, time, type] = formattedDate.split(" ");

            // Convert the month abbreviation to uppercase
            const capitalizedMonth = month.toUpperCase();

            // Set the formatted date with uppercase month abbrevia tion and desired format
            return `${day} ${capitalizedMonth} ${year}`;
          },
    },
    async mounted() {
        console.log("Mounted");

        const serviceAccount = require('../tamilsneham-key.json');

        await this.loadRsvps();
        
        if(this.$route.params.rsvpId != '' && this.$route.params.rsvpId != undefined){

            let rsvpParam = this.rsvpList.find(x => x.rsvpId == this.$route.params.rsvpId);

            if(rsvpParam != undefined){
                this.selectedRSVPName = rsvpParam.name;
                this.selectedRSVPId = rsvpParam.rsvpId;
                this.rsvpId = rsvpParam.rsvpId;
                
                // this.$refs.rsvpSelect.value = rsvpParam.rsvpId;

                this.cleanAndFetch();

            }
            
        }
        
       
        },
    };
</script>

<style>

.form-control[readonly] {
    background-color: white !important;
}

#ddKid1Age .dropdown-menu, #ddKid2Age .dropdown-menu {
    overflow-y: scroll;
    height: 386px;
}

.dropdown-toggle{
    display: flex;
}

</style>
