<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Who we are !</strong></h1>
			</div>
		</div>
		<div class="row mt-4 justify-content-center">
			<div class="col-10 col-sm-8 col-lg-6">
				<img src="assets/images/sneham-logo.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="400" loading="lazy">
			</div>
			<div class="col-12 col-lg-8">
				<p>
					<br/>
					<br/>
					<strong>Building Friendship and Community in Tampa Bay: The Story of Tamil Sneham</strong> <br/><br/>

<strong>Rooted in Friendship:</strong>  Tamil Sneham builds a community in Tampa Bay with friendships at its core. 

Tamil Sneham, which translates to “Tamil Friendship”, is a non-profit organization has been serving the Tampa Bay Area since 2007. Our mission is to foster friendships and celebrate the social and cultural heritage of people with Indian roots.  <br/><br/>

Originally focussed on the Tamil community, Tamil Sneham has grown to welcome anyone who shares their values. It has extended to others through its many educational, leadership and recreational activities for the youth of the community. Tamil Sneham is now an inclusive organization open to to all who share its mission.<br/><br/>

<strong>From Gathering to Growth:</strong> It all started in 2007 when a group of friends envisioned a space for fun and connection among Tamils in and around Tampa. Our first event, a family picnic in a county park, drew over 100 people and set the stage for future gatherings.<br/><br/>

<strong>Beyond Fun:</strong> Tamil Sneham organizes a variety of events to engage the Tamil community in and around Tampa. Movie screenings, talent shows, seasonal celebration and music concerts provide entertainment, while the  ‘Raagam Sneham’ music group offers a platform for local musicians and vocalists to showcase their talents. For the sporty crowd, “Sports Sneham” organizes men volleyball and women throw ball tournaments every year.<br/><br/>

As Tamil Sneham continued to flourish it added more activities to meet the needs of the community. Noteworthy among them are the Youth Leadership Program (YLP) for the middle and high school students and monthly volunteer opportunities that give back to the local community. <br/><br/>

<strong>Preserving Heritage Through Language:</strong> Nurturing a sense of cultural pride and awareness in our children is a cornerstone of Tamil Sneham's mission. We believe Tamil language is the heart of our heritage, connecting us to our roots and fellow Tamils in onshore and offshore. <br/><br/>

To help families maintain this connection, Tamil Sneham coordinates Tamil language classes. These classes, led by dedicated volunteers, provide children of Tamil descent a foundation in their native language. We equip teachers with resources and encourage families to use Tamil at home.  Fun competitions further motivate children to learn and celebrate their heritage.<br/><br/>

Tamil Sneham was registered (Doc. No. 07000004600) as a non-profit corporation in the State of Florida on May 7, 2007 and was granted Federal tax-exempt status, under section 501 (c) (4) of the Internal Revenue Service Code, on August 31, 2010 (EIN 65- 1301498).<br/><br/>

				</p>
			</div>
		</div>
	</div>
</section>




</div>

  
  
</template>

<script>

export default {
    name: "activity",
    components: {},
    data() {
        return {
        
        };
    },
    methods: {
    }
};

</script>