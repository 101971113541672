<template>
    <div>

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x" style="margin-top: 100px;">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Events</strong></h1>
				
				
			</div>
		</div>
		<div class="container mt-5 justify-content-center">
			<div class="table-responsive">
        <table class="table table-striped">
					<thead>
						<tr>
						<th scope="col">#</th>
						<th scope="col">Event</th>
						<th scope="col">Event Date</th>
            <th scope="col">Event Closing Date</th>
            <th scope="col">Fee (Adult / Kids)</th>
            <th scope="col">Kids Entry Age</th>
						<th scope="col">RSVP link</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in rsvpList" :key="item.number">
							<th scope="row">{{index + 1}}</th>
							<td>{{item.name}}</td>
							<td>{{item.eventDate}}</td>
              <td>{{item.lastDate}}</td>
              <td>
                <p>Member: ${{ item.RSVPType == 'General Event' ? item.entryFeeAdultsMember : (item.RSVPType == 'Women\’s Event' ? item.entryFeeWomensEventMember : (item.RSVPType == 'Sport Event' ? item.entryFeeSportEvent : (item.RSVPType == 'YLP Event' ? item.entryFeeYLPEventMember : (item.RSVPType == 'Talent Event' ? item.entryFeeTalentEvent : (item.RSVPType == 'Volley Ball' ? item.entryFeeVolleyBall : '0'))) )   )}} / ${{ item.RSVPType == 'General Event' ? item.entryFeeKidsMember : '-'}} </p>
                <p>Non-Member: ${{ item.RSVPType == 'General Event' ? item.entryFeeAdultsNonmember : (item.RSVPType == 'Women\’s Event' ? item.entryFeeWomensEventNonmember : (item.RSVPType == 'Sport Event' ? '-' : (item.RSVPType == 'YLP Event' ? item.entryFeeYLPEventNonmember : (item.RSVPType == 'Talent Event' ? '-' : (item.RSVPType == 'Volley Ball' ? '-' : '0'))) )   )}} / ${{ item.RSVPType == 'General Event' ? item.entryFeeKidsNonmember : '-'}} </p>
              </td>
              <td>Kids Age Range: {{ item.kidsAgeFrom }} - {{ item.kidsAgeTo }}</td>
              <td><router-link  :to="'/RSVPRegistration/' + item.rsvpId" class="nav-link link-primary">Register</router-link></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</section>
</div>
</template>

<script>

import firebase from '../Firebase.js'

export default {
    name: "activity",
    components: {},
    data() {
        return {
          rsvpList:[],
        };
    },
    methods: {
      async loadRsvps(){

          const db = firebase.firestore();

          await db.collection('rsvp')
            .where('active', '==', true)
            .orderBy('eventDateTimestamp', 'asc')
            .get()
            .then(docs => {
                docs.forEach(doc => {
                    const rsvp = {
                        rsvpId: doc.id,
                        ...doc.data()
                    };
                    this.rsvpList.push(rsvp);
                    if(rsvp.active && this.rsvpId == ''){
                        this.rsvpId = rsvp.rsvpId;
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching RSVP items:', error);
            });

      },
    },
    async mounted() {
      this.loadRsvps();
    }
};

</script>