<script setup>


</script>

<template>
    <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
        <div class="container pt-lg-md">
            <div class="row ">
                <div class="col-lg-10">
                    <card shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                        class="border-0">

                        <template>
                            <div>
                                <h3>Donation / Other payments</h3>
                            </div>
                            <p>
                                Tamilsneham accepts Donation or payment related to any specific requirements <br/>
                               
                            </p>
                            <form class="form">
                               
                                <div class="d-flex" style="flex-flow: wrap;">
                                    <!-- <base-dropdown class="form-group mb-6 col-lg-6 input-group">
                                        <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Choose Amount"
                                            addon-left-icon="ni ni-single-02" style="background-color: white;" id="type" v-model="membershipType" :error="typeError">
                                        </base-input>
                                        <a class="dropdown-item" href="#" @click="setAmount(0)">0</a>
                                        <a class="dropdown-item" href="#" @click="setAmount(100)">100</a>
                                        <a class="dropdown-item" href="#" @click="setAmount(200)">200</a>
                                    </base-dropdown> -->
                                    <!-- <P class="form-group mb-6 col-lg-6 input-group">OR</P> -->
                                    <base-input class="mb-3 col-lg-6" form-control label="Amount" labelClasses="col-lg-12" type="number" :error="amountError"
                                    id="amount" v-model="amount" required></base-input>
                                    
                                    <br />

                                    <base-input class="mb-3 col-lg-12" label="Notes" form-control labelClasses="col-lg-12"
                                        id="notes" :error="notesError" required>
                                        <textarea class="form-control" name="notes" rows="4" v-model="notes" cols="80"
                                        placeholder="Notes..."></textarea>
                                    </base-input>

                                    <div class="mb-3 col-lg-12">
                                        <h3 style="padding-left:15px; padding-top:32px;">Amount chosen: $ {{ amount }}</h3>
                                    </div>
                                    

                                    <div style="width:100%" id="paypal-button-container"></div>
                                    <!-- <h4>{{ successmessage}}</h4> -->
                                    <!-- <base-button type="submit" @click="importSTP" class="my-4">Import STP</base-button> -->
                                    <!-- <base-button class="mb-3 col-lg-6" type="primary" @click="submitRSVP()">Submit</base-button> -->
                                </div>
                                
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <modal :show="showPopup" @close="closePopup" type="notice">
            <h2>{{ successmessage }}</h2>

            <base-button class="mb-3 col-lg-6" type="primary" @click="closePopup()">Ok</base-button>

        </modal>
    </section>
</template>

<script>

import firebase from '../Firebase.js';
import Modal from '../components/Modal.vue';

export default {

    data() {
        return {
           
            
            amount:'',
            notes:'',
            showPopup: false,
            amountError:'',
            notesError:'',
            successmessage:'',
        };
    },
    methods: {
        
        closePopup(){
            this.showPopup = false;
        },
       
        async saveDonationInfo(details) {
            // Add the data to Firestore
            const db = firebase.firestore();

            let donation = null;

            donation = await db.collection('donation')
                    .add({
                        amount: this.amount,
                        notes: this.notes,
                        paypalId: details.id,
                        payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                        payerEmail: details.payer.email_address,
                        paidDateTime: details.create_time,
                        paidStatus: details.status,
                        paidAmount: details.purchase_units[0].amount.value,
                    });

           
            this.successmessage = 'Thank you, Your amount has been received';
            this.showPopup = true;
            this.resetForm();

            
        },
        async sendEmail(){

            // Get a reference to the Firestore database
            const db = firebase.firestore();

            console.log('Registration ifd ', this.registrationId);
            console.log('Membershiop ifd ', this.membershipId);

            console.log('Membershiop ifd3333 ', this.membershipId);

            const qrCode = new QRCode({
                content: btoa(this.membershipId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
                container: 'svg',
                join: true
             }).svg();

            await db.collection('email').add({
                from: 'contact@tamilsneham.org',
                subject: 'Member Registration Confirmation',
                email: this.email,
                userName: this.fname + ' ' + this.lname,
                qrCode: qrCode,
                registrationId: this.membershipId,
                totalPaid: this.fee,
                member_id: this.member_id,
                year: 2024,
                mailType: 1,
                
            });

            
            return null;
            
        },
        formatDate(date) {

            if(date == undefined) return '';

            const formattedDate = new Date(date).toUTCString();

            // Split the formatted date into day, month, and year parts
            const [weekday, day, month, year, time, type] = formattedDate.split(" ");

            // Convert the month abbreviation to uppercase
            const capitalizedMonth = month.toUpperCase();

            // Set the formatted date with uppercase month abbrevia tion and desired format
            return `${day} ${capitalizedMonth} ${year}`;
        },
        resetForm() {
            // Reset the form fields
           
                this.amount = '';
                this.notes = '';
            
        },
        getNow(isDate){

            const today = new Date();

            const date = today.getFullYear() +'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
            const shortDate = today.getFullYear().toString().slice(-2) +''+ String(today.getMonth()+1).padStart(2, '0') +''+ String(today.getDate()).padStart(2, '0');
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;

            if(isDate){
                return dateTime;
            }
           
            return parseInt(shortDate +''+ today.getMinutes() +''+ today.getMilliseconds());
        },
        initPayPalButton() {
            // eslint-disable-next-line no-undef
            paypal
                .Buttons({
                createOrder: (data, actions) => {
                
                    this.amountError = '';
                    this.notesError = '';
                   
                    this.isValid = true;

                    if(this.amount == ''){this.amountError = 'Please enter the amount'; this.isValid=false}
                    if(this.notes == ''){this.notesError = 'Plesae enter the nores'; this.isValid=false}

                    if(!this.isValid){return;}

                    return actions.order.create({
                    purchase_units: [
                        {
                        amount: {
                            value: this.amount.toString(),
                        },
                        },
                    ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                    // Handle successful payment here
                    console.log(details);

                    this.saveDonationInfo(details);

                    });
                },
                onError: (err) => {
                    // Handle errors here
                    console.error(err);
                },
                })
                .render("#paypal-button-container");
        },
        },
        mounted() {
            console.log("Mounted");
            // this.fetchGrades();
            this.initPayPalButton();
       
        },
};
</script>

<style scoped></style>
