<script setup>


</script>

<template>
    <section>
        <div class="container" style="margin-top: 100px !important;">
            <div class=" ">
                <div class="">
                    <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                        style="background-color: white !important;" class="border-0">

                        <template>
                            <div>
                                <h3>Registration for Tamil School</h3>
                            </div>
                            <br>
                            <div>
                                <h1 style="color: #e91e63;">Registration for the year 2024-2025 is closed. Thanks for your interest.</h1>
                            </div>
                            <br>
                            <form class="form">
                                <div class="dragArea row">
                                    <div>
                                        <p><strong>Are you a Member?</strong></p>
                                        <base-input alternative class="mb-3" placeholder="Membership Id" form-control
                                            style="width: 50%;float: left;" id="user-MembershipId"
                                            v-model="userMembershipId" :error="membershipIdError">
                                        </base-input>
                                        <base-button class="mb-3 col-lg-5"
                                            style="float: right;max-width: 41.66667%;height: 46px;" type="primary"
                                            @click="validateMemberId()">Validate</base-button>
                                    </div>
                                    <div style="clear: both;">
                                        <p style="cursor: pointer;color: blue;" @click="forgotMemberId()">Forgot your Membership Id? </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="name">
                                        <base-input class="col-lg-12" form-control label="Father's Name"
                                            labelClasses="col-lg-12" :error="fatherNameError" id="fatherName"
                                            v-model="stp.fatherName" required></base-input>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="email">
                                        <base-input class="col-lg-12" label="Mother's Name"
                                            aria-placeholder="Mother's Name" form-control labelClasses="col-lg-12"
                                            :error="motherNameError" id="motherName" v-model="stp.motherName"
                                            required></base-input>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="name">
                                        <base-input class="col-lg-12" label="Email" form-control
                                            labelClasses="col-lg-12" :error="emailError" id="email" v-model="stp.email"
                                            required></base-input>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="email">
                                        <base-input class="col-lg-12" label="Phone (For Communication)" form-control
                                            labelClasses="col-lg-12" :error="phoneNumberError" id="phone"
                                            v-model="stp.phone" required></base-input>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="name">
                                        <div class="col-md-12">
                                            <label >Number of Kids<span>*</span></label><br />
                                            <div class="input-group col-md-6 col-sm-12 mb-3">
                                                <select class="form-select" ref="noOfKids" id="noOfKids" aria-label=""
                                                    v-model="numberOfKids">
                                                    <!-- <option selected></option> -->
                                                    <option v-for="item in 4" :key="item.id" :value="item">{{ item }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="name">
                                        <div class="col-md-12">
                                            <label >Location<span>*</span></label><br />
                                            <div class="input-group col-md-6 col-sm-12 mb-3">
                                                <select class="form-select" ref="noOfKids" id="location" aria-label="" :error="locationError"
                                                    v-model="location">
                                                    <!-- <option selected></option> -->
                                                    <option v-for="item in locationList" :key="item.id" :value="item.name">{{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>

                                <div v-for="(item, index) in stp.students.slice(0, numberOfKids)" :key="item.id"
                                    class="col-lg-12 row mb-3">
                                    <h5>Student {{ index + 1 }}</h5>
                                    <div class="col-md-4 col-sm-12 form-group mb-3" data-for="email">
                                        <base-input class="col-lg-12" label="Student's Name" form-control
                                            labelClasses="col-lg-12" :error="item.studentNameError"
                                            :id="'studentName' + index" v-model="item.studentName"
                                            required></base-input>
                                    </div>
                                    <div class="col-md-4 col-sm-12 form-group mb-3" data-for="name">
                                        <base-input class="col-lg-12" label="Date of Birth" form-control
                                            labelClasses="col-lg-12" :error="item.dobError" type="date"
                                            :id="'dob' + index" v-model="item.dob" required></base-input>
                                    </div>
                                    <div class="col-md-4 col-sm-12 form-group " data-for="grade">
                                        <label>Grade<span>*</span></label><br />
                                       
                                        <div class="input-group col-md-6 col-sm-12 mb-3">
                                            <select class="form-select" :ref="'grade' + index" :id="'grade' + index"
                                                aria-label="" :v-model="'grade' + index" @change="setGrade($event, index)">
                                                <option selected value="0">Select Grade</option>
                                                <option v-for="grade in gradeList" :key="grade.id" :value="grade.id + '-' + grade.memberFee + '-' + grade.fee">{{
                                                    grade.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <!-- <base-button type="submit" @click="importSTP" class="my-4">Import STP</base-button> -->

                                <h3 style="padding-left:15px; padding-top:32px;">Fee to be Paid: {{ calculateFee() }}
                                </h3>
                                <br>
                                <div class="d-flex" style="flex-flow: wrap;">
                                    <div style="width:100%;display: none;" id="paypal-button-container"></div>
                                    <h4>{{ successmessage}}</h4>
                                    <!-- <base-button class="mb-3 col-lg-6" type="primary" @click="submitRSVP()">Submit</base-button> -->
                                </div>

                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <modal :show="showForgotPopup" @close="closeForgotPopup" type="notice">
                <p style="font-weight: 600;">Please provide your registered Email</p>

                <base-input alternative class="mb-3" placeholder="Email" type="email"
                     id="forgot-email" v-model="forgotEmail" :error="forgotEmailError">
                </base-input>

                <div>
                    <base-button style="float: right;" class="mb-3 col-lg-3" type="primary" @click="sendForgotEmail()">Send</base-button>
                    <base-button style="float: right;margin-right: 20px;" class="mb-3 col-lg-3" type="primary" @click="closeForgotPopup()">Cancel</base-button>
                </div>
                
                

            </modal>
    </section>
</template>

<script>

import firebase from '../Firebase.js'
const QRCode = require('qrcode-svg');
import Modal from '../components/Modal.vue';

export default {

    data() {
        return {
            stp: {
                fatherName: '',
                motherName: '',
                email: '',
                phone: '',
                students:[{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},
                {studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0}],
            },
            kids:[],
            numberOfKids:1,
            gradeList:[],
            locationList:[],
            grade:'',
            location:'Community Development Center',
            fee:'',
            registrationType:'',
            isValid:true,
            fatherNameError: '',
            motherNameError:'',
            emailError:'',
            phoneNumberError:'',
            studentNameError1:'',
            numberOfKidsError:'',
            membershipIdError:'',
            membershipId:'',
            member:false,
            userMembershipId:'',
            dobError:'',
            gradeError:'',
            regTypeError:'',
            successmessage:'',
            registrationId:'',
            gradeId:'',
            grade0:'',
            grade1:'',
            grade2:'',
            grade3:'',
            showForgotPopup:false,
            forgotEmail:'',
            forgotEmailError:'',
            locationError:'',
        };
    },
    methods: {
        forgotMemberId(){
            this.showForgotPopup = true;
        },
        closeForgotPopup(){

            this.showForgotPopup = false;
        },
        async sendForgotEmail(){
            
            const db = firebase.firestore();

            let querySnapshot = null;

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.forgotEmail)) {

                querySnapshot = await db.collection('members')
                .where('email', '==', this.forgotEmail.toLowerCase())
                .where('status', '==', '1')
                .get();

                if(!querySnapshot.empty){
                    this.forgotenMembershipId = querySnapshot.docs[0].data().member_id;
                    // this.membershipEmail = querySnapshot.docs[0].data().email;

                    this.sendMembershipId();
                }
                else{
                    this.forgotEmailError = "The given Email doesnot exists in the system";
                }
            }
            else{
                this.forgotEmailError = "Please enter a valid email address";
            }
            

        },
        async sendMembershipId(){

            const db = firebase.firestore();

            await db.collection('email').add({
                from: 'contact@tamilsneham.org',
                subject: 'Membership Id',
                email: this.forgotEmail,
                member_id: this.forgotenMembershipId,
                mailType: 4,
                
            });

            this.closeForgotPopup();
            this.successmessage = "Retreived Membership Id has been sent to your registered Email.";
            this.showPopup = true;

        },
        importSTP(){

            var detail = {}

            this.submitSchoolRegistration(detail);
        },
        async submitSchoolRegistration(details) {
            // Add the RSVP data to Firestore
            const db = firebase.firestore();

            let reg = null;

            reg = await db.collection('schoolRegistration')
                .add({
                    fatherName: this.stp.fatherName,
                    motherName: this.stp.motherName,
                    email: this.stp.email,
                    phone: this.stp.phone,
                    students: this.stp.students,
                    paypalId: '', //details.id,
                    payerName: '', //details.payer.name.given_name + ' ' + details.payer.name.surname,
                    payerEmail: '', //details.payer.email_address,
                    paidDateTime: '', //details.create_time,
                    paidStatus: '', //details.status,
                    paidAmount: 350, //details.purchase_units[0].amount.value,
                    location: this.location
                })
                .then((result) => {
                console.log('School registration saved successfully!');
                // Reset the form after successful submission

                this.registrationId = result.id;

                this.sendEmail();
                this.resetForm();
                this.successmessage = 'The Registration is successful, confirmation mail has been sent to your Email';
                })
                .catch(error => {
                console.error('Error saving School registration:', error);
                });

                

            
        },
        async validateMemberId(){

            const db = firebase.firestore();

            console.log(this.userMembershipId);

            const result = await db.collection('members')
                .where('member_id', '==', this.userMembershipId == "" ? 1111 : parseInt(this.userMembershipId))
                .where('status', '==', '1')
                .get();

            if(!result.empty){

                let member = result.docs[0].data();

                this.member = true;
                this.membershipId = this.userMembershipId;
                // this.firstName = member.fname;
                // this.lastName = member.lname;
                // this.email = member.email;
                // this.phoneNumber = member.telephone;
                this.membershipIdError = 'Membership Validated';

                this.numberOfKids = 0;

                setTimeout(() => {
                        this.numberOfKids = 1;
                }, 500);
            }
            else{
                this.member = false;
                // this.firstName = '';
                // this.lastName = '';
                // this.email = '';
                // this.phoneNumber = '';
                this.membershipIdError = "Membership does not exists";
            }

        },
        async sendEmail(){

            // Get a reference to the Firestore database
            const db = firebase.firestore();

            const qrCode = new QRCode({
                content: btoa(this.registrationId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
                container: 'svg',
                join: true
             }).svg();

            await db.collection('email').add({
                from: 'contact@tamilsneham.org',
                subject: 'School Registration Confirmation',
                email: this.stp.email,
                phone: this.stp.phone,
                fatherName: this.stp.fatherName,
                motherName: this.stp.motherName,
                students: this.stp.students.slice(0, this.numberOfKids),
                registrationId: this.registrationId,
                location: this.location,
                qrCode: qrCode,
                mailType: 3,
            });

        },
        resetForm() {
            // Reset the form fields
            this.stp = {
                fatherName: '',
                motherName: '',
                email: '',
                phone: '',
                students:[{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},
                {studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0},{studentName: '',dob: '',grade: '',studentNameError:'',dobError:'',gradeError:'', fee:0}],
            };
            this.numberOfKids = 1;
            this.location = '';
            this.showForgotPopup = false;
        },
        calculateFee(){

            let totalFee = 0;

            this.stp.students.slice(0, this.numberOfKids).forEach(element => {
                totalFee = totalFee + element.fee;
            });

            return totalFee;
        },
        setGrade(event, index){

            let value = event.currentTarget.selectedOptions[0].value;
            let name = event.currentTarget.selectedOptions[0].text;

            console.log(value, name);

            let item = value.split('-');

            let gradeId = item[0];
            let memberFee = parseInt(item[1]);
            let fee = parseInt(item[2]);

            console.log(gradeId);
            let grade = this.gradeList.find(x => x.gradeId = gradeId);

            console.log(grade.name);

            this.stp.students[index].grade = name;
            this.stp.students[index].fee = this.member ? memberFee : fee;
            

            if(name == 'ADV KG'){
                alert('"Ages 8 and above". The syllabus includes KG and Grade.After finishing Adv KG student will go to Grade 2.');
            }
            else if(name == 'Evaluation'){
                alert('Only for new kids who already know Tamil or studied in some other Tamil school or coming from a different state or country');
            }

        },
        setRegistrationType(type){

            this.stp.registrationType = type
            this.registrationType = type;
        },
        fetchGrades(){

            const db = firebase.firestore();

            db.collection('grades')
            .orderBy('id')
            .get()
            .then(querySnapshot => {
            const gradeList = [];
            querySnapshot.forEach(doc => {
                const grade = {
                gradeId: doc.id,
                ...doc.data()
                };
                gradeList.push(grade);
            });
            this.gradeList = gradeList;

            })
            .catch(error => {
            console.error('Error fetching grade list:', error);
            });
        },
        fetchLocations(){

            const db = firebase.firestore();

            db.collection('locations')
            .orderBy('name')
            .get()
            .then(querySnapshot => {
            const locationList = [];
            querySnapshot.forEach(doc => {
                const location = {
                locationId: doc.id,
                ...doc.data()
                };
                locationList.push(location);
            });
            this.locationList = locationList;

            })
            .catch(error => {
            console.error('Error fetching location List:', error);
            });
        },
        initPayPalButton() {
            // eslint-disable-next-line no-undef
            paypal
                .Buttons({
                createOrder: async (data, actions) => {
                
                    this.fatherNameError = '';
                    this.motherNameError = '';
                    this.phoneNumberError = '';
                    this.emailError = '';
                    this.locationError = '';

                   
                    this.stp.students[0].studentNameError = '';
                    this.stp.students[0].dobError = '';
                    this.stp.students[0].gradeError = '';

                    if(this.numberOfKids > 1){
                        this.stp.students[1].studentNameError = '';
                        this.stp.students[1].dobError = '';
                        this.stp.students[1].gradeError = '';
                    }
                    
                    if(this.numberOfKids > 2){
                        this.stp.students[2].studentNameError = '';
                        this.stp.students[2].dobError = '';
                        this.stp.students[2].gradeError = '';
                    }

                    if(this.numberOfKids > 3){
                        this.stp.students[3].studentNameError = '';
                        this.stp.students[3].dobError = '';
                        this.stp.students[3].gradeError = '';
                    }

                    
                    this.isValid = true;

                    if(this.stp.fatherName == ''){this.fatherNameError = 'Father name is required'; this.isValid=false}
                    if(this.stp.motherName == ''){this.motherNameError = 'Mother name is required'; this.isValid=false}
                    if(this.stp.phone == ''){this.phoneNumberError = 'Phone number is required'; this.isValid=false}
                    if(this.stp.email == ''){this.emailError = 'Email is required'; this.isValid=false}
                    if(this.location == ''){this.locationError = 'Location is required'; this.isValid=false}


                    if( this.stp.students[0].studentName == ''){ this.stp.students[0].studentNameError = 'Student name is required'; this.isValid=false}
                    if( this.stp.students[0].dob == ''){ this.stp.students[0].dobError = 'Date of birth is required'; this.isValid=false}
                    if( this.stp.students[0].grade == ''){ this.stp.students[0].gradeError = 'Grade is required'; this.isValid=false}

                    if(this.numberOfKids > 1){
                        if( this.stp.students[1].studentName == ''){ this.stp.students[1].studentNameError = 'Student name is required'; this.isValid=false}
                        if( this.stp.students[1].dob == ''){ this.stp.students[1].dobError = 'Date of birth is required'; this.isValid=false}
                        if( this.stp.students[1].grade == ''){ this.stp.students[1].gradeError = 'Grade is required'; this.isValid=false}
                    }

                    if(this.numberOfKids > 2){
                        if( this.stp.students[2].studentName == ''){ this.stp.students[2].studentNameError = 'Student name is required'; this.isValid=false}
                        if( this.stp.students[2].dob == ''){ this.stp.students[2].dobError = 'Date of birth is required'; this.isValid=false}
                        if( this.stp.students[2].grade == ''){ this.stp.students[2].gradeError = 'Grade is required'; this.isValid=false}
                    }

                    if(this.numberOfKids > 3){
                        if( this.stp.students[3].studentName == ''){ this.stp.students[3].studentNameError = 'Student name is required'; this.isValid=false}
                        if( this.stp.students[3].dob == ''){ this.stp.students[3].dobError = 'Date of birth is required'; this.isValid=false}
                        if( this.stp.students[3].grade == ''){ this.stp.students[3].gradeError = 'Grade is required'; this.isValid=false}
                    }

                //    await this.stp.students.forEach(element => {

                //         if(element.studentName == ''){element.studentNameError = 'Student name is required'; this.isValid=false}
                //         if(element.dob == ''){element.dobError = 'Date of birth is required'; this.isValid=false}
                //         if(element.grade == ''){element.gradeError = 'Grade is required'; this.isValid=false}
                        
                //     });


                    console.log(this.isValid);

                    if(!this.isValid){return;}

                    console.log(this.isValid);

                    return actions.order.create({
                    purchase_units: [
                        {
                        amount: {
                            description: 'School Registration',
                            value: this.calculateFee().toString(),
                        },
                        },
                    ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                    // Handle successful payment here
                    console.log(details);

                    this.submitSchoolRegistration(details);

                    });
                },
                onError: (err) => {
                    // Handle errors here
                    console.error(err);
                },
                })
                .render("#paypal-button-container");
        },
        async importData(){

            const db = firebase.firestore();

            // Read data from the JSON file
            const data = require('../data.json');

            // Function to import data into Firestore
            const importData1 = async () => {
            try {
                // Loop through each document in the JSON data array
                for (const document of data) {
                // Add the document to Firestore collection

                document.email = document.email.toLowerCase();
                document.spouseemail = document.spouseemail.toLowerCase();

                await db.collection('members').add(document);
                }

                console.log('Data import completed.');
            } catch (error) {
                console.error('Error importing data:', error);
            }
            };

            importData1();

            // const db = firebase.firestore();

            // try {

            // // Query the members collection based on the email or phone number
            // const querySnapshot = await db.collection('members')
            //     .get();

            // if(!querySnapshot.empty){
            
            //     let count = 0;
            //     let totalAdults = 0;
            //     let totalKids = 0;
            //     let totalMembers = 0;
            //     let totalNonMembers = 0;

            //     let existingMember =[2773, 2774,2775,2776,2777,2778,2780,2781,2782,2783,2785,2786, 2787,2788,2789,2790,2791,2792,2793,2794,2796,2797];

            //     querySnapshot.forEach(async doc => {
            //     // Extract the RSVP data from each document
            //         count++;

            //         const rsvp = {
            //             ...doc.data()
            //             };
                    
                        
                       
            //             // rsvp.status = '0';

            //             // console.log(rsvp.email + '===' + rsvp.member_id + '===' + rsvp.status + '===' + doc.id);

            //             if(existingMember.includes(rsvp.member_id) ){
            //                 console.log(rsvp.member_id);
            //                 // console.log(rsvp.email + '===' + rsvp.member_id + '===' + rsvp.status + '===' + doc.id);
            //                 // console.log('Member exists');
            //             }
            //             // else if(rsvp.status == '1'){
                        
            //             //     console.log(rsvp);
            //             //     rsvp.status = '0';
            //             //     console.log(rsvp.email + '===' + rsvp.member_id + '===' + rsvp.status + '===' + doc.id);

            //             //     await db.collection('members')
            //             //     .doc(doc.id)
            //             //     .update(rsvp)
            //             //     .then(() => {
            //             //         console.log('RSVP updated successfully!');
            //             //     })
            //             //     .catch(error => {
            //             //         console.error('Error updating RSVP:', error);
            //             //     });
            //             // }
                    
            //         });

                
            //     return true;
            // }
            // else{
            
            //     return false;
            // }

            // } catch (error) {
            //     console.error('Error checking member registration:', error);
            //     return false;
            //     }

        }
        },
        mounted() {
        console.log("Mounted");

        this.fetchGrades();
        this.fetchLocations();
        this.initPayPalButton();
       
        },
};
</script>

<style scoped></style>
