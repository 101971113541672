import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

//Test (app)
// const firebaseConfig = {
//     apiKey: "AIzaSyBA2fn83h7Ark0anBDhhYdDf6dF4hSyNyI",
//     authDomain: "tamilsneham-7d2fd.firebaseapp.com",
//     projectId: "tamilsneham-7d2fd",
//     storageBucket: "tamilsneham-7d2fd.appspot.com",
//     messagingSenderId: "646459272041",
//     appId: "1:646459272041:web:2d811bb1f18926f305ebf8",
//     measurementId: "G-NQDQBN7EL7"
// };


//Prod
const firebaseConfig = {
    apiKey: "AIzaSyDQCsWD4QQ8noBi83YSJROVYXk7CP50xgo",
    authDomain: "tamilsneham-prod.firebaseapp.com",
    projectId: "tamilsneham-prod",
    storageBucket: "tamilsneham-prod.appspot.com",
    messagingSenderId: "534980210305",
    appId: "1:534980210305:web:88ed7802acce72dd946780",
    measurementId: "G-VL9607670L"
  };

  //Test

  // const firebaseConfig = {
  //   apiKey: "AIzaSyC-DVZE3y9SFPO00aUrY2X7oWClfjVOetk",
  //   authDomain: "mynewview-39339.firebaseapp.com",
  //   databaseURL: "https://mynewview-39339.firebaseio.com",
  //   projectId: "mynewview-39339",
  //   storageBucket: "mynewview-39339.appspot.com",
  //   messagingSenderId: "911270074304",
  //   appId: "1:911270074304:web:8e73d9eec97184b1f75a25"
  // };


firebase.initializeApp(firebaseConfig);

export default firebase;