<template>
    <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
       
         <div class="pt-lg-md" style="margin-top: 60px;">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <!-- <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0"> -->
                            <h2 class="heading">School Registration List</h2>


                            <ejs-grid ref='registrationList' id='registrationList' :dataSource="studentList" :allowFiltering='true' :filterSettings='filterSettings' :allowSorting='true' :allowPdfExport='true'  :allowExcelExport='true' 
                                :toolbar='toolbar' :allowPaging="true" :pageSettings='pageSettings' :toolbarClick='toolbarClick'  :enableHover='false' :enableHeaderFocus='true' :autoFit='true'>
                                  <e-columns>

                                      <e-column field='studentName' headerText='Student Name'></e-column>
                                      <e-column field='grade' headerText='Grade'></e-column>
                                      <e-column field='dob' headerText='DOB'></e-column>
                                      <e-column field='fee' headerText='Fee'></e-column>
                                      <e-column field='fatherName' headerText='Father Name'></e-column>
                                      <e-column field='motherName' headerText='Mother Name'></e-column>
                                      <e-column field='email' headerText='Email'></e-column>
                                      <e-column field='phone' headerText='Phone'></e-column>
                                      <e-column field='location' headerText='Location'></e-column>
                                      <!-- <e-column field='paidAmount' headerText='Paid Amount'></e-column> -->

                                      <!-- <e-column headerText='Send' :commands='commands'></e-column> -->
                                      <!-- <e-column field='paidStatus' headerText='Paid Status'></e-column> -->
                                  </e-columns>
                              </ejs-grid> 

                            
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import firebase from '../Firebase.js'
import { GridPlugin, VirtualScroll, Sort, GridComponent, Column, ColumnsDirective, PdfExport, ExcelExport, Page, Toolbar, Filter, FilterType, CommandColumn } from "@syncfusion/ej2-vue-grids";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default {
  components: {
    'ejs-grid' : GridComponent,
    'e-columns' : ColumnsDirective,
    'e-column' : ColumnsDirective,
  },
  data() {
    return {
      theme: theme,
      pageSettings: { pageCount: 10},
      toolbar: ['ExcelExport', 'PdfExport'],
      tooltip: {
        enable: true,
      },
      commands: [{  buttonOption: { iconCss: 'e-icons e-send', isPrimary: true, cssClass: 'e-flat email'} },],
      zoom:
        {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
        },
      legend: {enableHighlight : true, position: 'Top'},
      filterSettings: { type: 'Menu' },
      stpList: [],
      studentList:[],
    };
  },
  provide: {
    grid: [Sort, VirtualScroll, PdfExport, ExcelExport, Page, Toolbar, Filter, CommandColumn]
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'rsvpList' property
    this.fetchSTPList();
  },
  methods: {
    fetchSTPList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('schoolRegistration')
        .get()
        .then(querySnapshot => {
          const stpList = [];
          querySnapshot.forEach(doc => {
            // Extract the RSVP data from each document
            const stp = {
              stppId: doc.id,
              ...doc.data()
            };
            stpList.push(stp);

            stp.students.forEach(obj =>{

              if(obj.studentName != ''){
                  const student = {
                  ...obj,
                  fatherName: stp.fatherName,
                  motherName: stp.motherName,
                  email: stp.email,
                  phone: stp.phone,
                  location: stp.location
                }

                this.studentList.push(student);
              }

              

            });

          });
          this.stpList = stpList;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getNow(isDate){

        const today = new Date();
        const date = today.getFullYear()+'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date +' '+ time;

        if(isDate)
        {
          return date;
        }
        return dateTime;
    },
    toolbarClick(args) {
        switch (args.item.id) {
           
            case 'registrationList_excelexport':
              
                this.$refs.registrationList.excelExport(this.getExcelExportProperties());
                break;
            case 'registrationList_pdfexport':
               
                this.$refs.registrationList.pdfExport(this.getPdfExportProperties());
                break;
        }
        
    },
    getExcelExportProperties: function() {
        return {
            header: {
                headerRows: 7,
                rows: [
                    {
                        index: 1,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 5, value: this.selectedRSVPName, style: { fontColor: '#C25050', fontSize: 25, hAlign: 'Center', bold: true } }
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 2, value: 'Tamil sneham', style: { fontColor: '#C67878', fontSize: 15, bold: true } },
                            { index: 5, value: 'DATE', style: { fontColor: '#C67878', bold: true }, width: 150 }
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, colSpan: 2, value: 'Tampa' },
                            { index: 5, value: this.getNow(false), width: 150 }
                        ]
                    },
                    // {
                    //     index: 5,
                    //     cells: [
                    //         { index: 1, colSpan: 2, value: 'Tel +1 888.936.8638 Fax +1 919.573.0306' },
                    //         { index: 4, value: 'CUSOTMER ID', style: { fontColor: '#C67878', bold: true } },
                    //         { index: 5, value: 'TERMS', width: 150, style: { fontColor: '#C67878', bold: true } }
                    //     ]
                    // },
                    // {
                    //     index: 6,
                    //     cells: [
                    //         { index: 4, value: 564 },
                    //         { index: 5, value: 'Net 30 days', width: 150 }
                    //     ]
                    // }
                ]
            },

            footer: {
                footerRows: 5,
                rows: [
                    /* tslint:disable-next-line:max-line-length */
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] },
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] }
                ]
            },
            
            fileName: "exceldocument.xlsx"
        };
    },
    getPdfExportProperties: function() {
        return {
          pageOrientation: 'Landscape',
            header: {
                fromTop: 0,
                height: 120,
                contents: [
                    {
                        type: 'Text',
                        value: 'School Registration',
                        position: { x: 280, y: 0 },
                        style: { textBrushColor: '#C25050', fontSize: 25 },
                    },
                    {
                        type: 'Text',
                        value: 'PRINT DATE',
                        position: { x: 500, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: this.getNow(false),
                        position: { x: 600, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: 'Tamil sneham',
                        position: { x: 20, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 20 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '2501 Aerial Center Parkway',
                    //     position: { x: 20, y: 65 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                    // {
                    //     type: 'Text',
                    //     value: 'Tel +1 888.936.8638 Fax +1 919.573.0306',
                    //     position: { x: 20, y: 80 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                ]
            },
            footer: {
                fromBottom: 50,
                height: 100,
                contents: [
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 250, y: 20 },
                        style: { textBrushColor: '#C67878', fontSize: 14 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '! Visit Again !',
                    //     position: { x: 300, y: 45 },
                    //     style: { textBrushColor: '#C67878', fontSize: 14 }
                    // }
                ]
            },
            
            fileName: "pdfdocument.pdf"
        };
    }, 
  },
};
</script>

<style>
    .table{
        background-color:white !important;
    }
</style>