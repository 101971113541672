<template>
    <footer class="footer has-cards">
        <section data-bs-version="5.1" class="footer1 programm5 cid-tJS9NNcTLZ" once="footers" id="footer03-1q">
    

<div class="container">
    <div class="">
        <hr>
        <!-- <div class="row-links mb-4">
            <ul class="header-menu">
            <li class="header-menu-item mbr-fonts-style display-5"><router-link to="/Home" class="text-danger">About</router-link></li><li class="header-menu-item mbr-fonts-style display-5"><router-link to="/Home" class="text-danger text-primary">Team</router-link></li><li class="header-menu-item mbr-fonts-style display-5"><router-link to="/Home" class="text-danger text-primary">Contact</router-link></li><li class="header-menu-item mbr-fonts-style display-5"><router-link to="/Home" class="text-danger text-primary">Activities</router-link></li></ul>
          </div> -->

        <div class="col-12" style="text-align: center;">
            <div>
                <a href="http://www.facebook.com/tampatamil.sneham" target="_blank" class="fa fa-facebook"></a>
                <!-- <a href="#" class="fa fa-twitter"></a> -->
                <a href="http://www.youtube.com/@TamilSneham" target="_blank" class="fa fa-youtube"></a>
                <!-- <a href="#" class="fa fa-instagram"></a> -->
            </div>
           
            <!-- <div class="social-row">
                <div class="soc-item">
                    <a href="" target="_blank">
                        <span class="mbr-iconfont socicon socicon-facebook display-7"></span>
                    </a>
                </div>
                <div class="soc-item">
                    <a href="" target="_blank">
                        <span class="mbr-iconfont socicon-twitter socicon"></span>
                    </a>
                </div>
                <div class="soc-item">
                    <a href="" target="_blank">
                        <span class="mbr-iconfont socicon-instagram socicon"></span>
                    </a>
                </div>
                <div class="soc-item">
                    <a href="" target="_blank">
                        <span class="mbr-iconfont socicon-tiktok socicon"></span>
                    </a>
                </div>
                <div class="soc-item">
                    <a href="" target="_blank">
                        <span class="mbr-iconfont socicon-youtube socicon"></span>
                    </a>
                </div>
            </div> -->
        </div>
        <div class="col-12 mt-5">
            <p class="mbr-fonts-style copyright display-7">
                © Copyright 2024 Tamilsneham - All Rights Reserved
            </p>
        </div>
    </div>
</div>
</section>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>

.fa {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}



</style>
