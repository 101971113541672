<template>
  <section class="" style="margin-top: 100px !important;">
    <div class="pt-lg-md" style="margin-top: 60px;">
      <!-- <div class="col-lg-12">
        <div>
          <base-button class="" type="primary" @click="openCreatePopup()">Add Activity</base-button>
        </div>
      </div> -->
      <div class="row justify-content-center">
       
        <div class="col-lg-11">
          <div >
            <base-button class="" style="float:right;" type="primary" @click="openCreatePopup()">Add Activity</base-button>
          </div>
          <h1 class="heading">Activity List</h1>
          <ejs-grid ref='activityList' id='activityList' :dataSource="activityList" :allowFiltering='true' :filterSettings='filterSettings' :allowSorting='true' :allowPdfExport='true'  :allowExcelExport='true'
            :toolbar='toolbar' :pdfExportComplete='pdfExportComplete' :allowPaging="true" :commandClick='commandClick' :pageSettings='pageSettings' :rowDataBound='rowDataBound' :toolbarClick='toolbarClick'  :enableHover='false' :enableHeaderFocus='true' :autoFit='true'>
              <e-columns>
                  <e-column  v-if='false == true' field='activityId' headerText='Id'></e-column>
                  <e-column field='activityName' headerText='Name'></e-column>
                  <e-column field='activityDate' headerText='Event Date'></e-column>
                  <e-column headerText='Edit' :commands='commands'></e-column>
              </e-columns>
          </ejs-grid>
          <!-- <div class="table-responsive ">
            <table class="vehicle-table table table-striped align-middle">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Event Date</th>
                  <th>Last Date</th>
                  <th>Entry Fee</th>
                  <th>Kids Age Range</th>
                  <th>RSVPs</th>
                  <th>Entries</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rsvp in activityList" :key="rsvp.rsvpId" :style="!rsvp.active ? 'color:red' : ''" >
                  <td>{{ rsvp.name }}</td>
                  <td>{{ rsvp.rsvpId }}</td>
                  <td>{{ rsvp.eventDate }}</td>
                  <td>{{ rsvp.lastDate }}</td>
                  <td>Member - Adults: {{ rsvp.entryFeeAdultsMember }}, Kids: {{ rsvp.entryFeeKidsMember }}<br/>
                  Non Member - Adults: {{ rsvp.entryFeeAdultsNonmember }}, Kids: {{ rsvp.entryFeeKidsNonmember }}</td>
                  <td>{{ rsvp.kidsAgeFrom }} - {{ rsvp.kidsAgeTo }}</td>
                  <td>RSVPs: {{ rsvp.totalReg }} <br/>
                      Members : {{ rsvp.totalMember }} | Non Members : {{ rsvp.totalNonMember }}<br/>
                      Adults : {{ rsvp.totalAdults }} |  Kids : {{ rsvp.totalKids }}<br/>
                  </td>
                  <td>Entries: {{ rsvp.totalEntry }} <br/>
                      Adults : {{ rsvp.totalAdultsEntry }} |  Kids : {{ rsvp.totalKidsEntry }}<br/>
                  </td>
                  <td>
                    <base-button class="mb-3 col-lg-5" type="primary" @click="openEditPopup(rsvp)" size="sm" iconOnly
                      icon="fa fa-pencil"></base-button>
                    <base-button class="mb-3 col-lg-5" type="danger" @click="deleteItem(rsvp.rsvpId)" size="sm" iconOnly
                      icon="fa fa-trash"></base-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <!-- </card> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from '../Firebase.js'
import Modal from '../components/Modal.vue';
import { GridPlugin, VirtualScroll, Sort, GridComponent, Column, ColumnsDirective, PdfExport, ExcelExport, Page, Filter, FilterType, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');


export default {

  components: {
    'ejs-grid' : GridComponent,
    'e-columns' : ColumnsDirective,
    'e-column' : ColumnsDirective,
  },
  data() {
    return {
      theme: theme,
      pageSettings: { pageCount: 10},
      toolbar: ['ExcelExport', 'PdfExport'],
      tooltip: {
        enable: true,
      },
      commands: [{  buttonOption: { iconCss: 'e-icons e-delete', isPrimary: true, cssClass: 'e-flat delete'} },],
      zoom:
        {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
        },
      legend: {enableHighlight : true, position: 'Top'},
      filterSettings: { type: 'Menu' },
      entryFeeTemplate: function () {
          return {
              template: Vue.component('columnTemplate', {
                  template: `<div>Member - Event: </div>`,
                  data: function () { return { data: {} }; }
              })
          }
      },
      showCharCount: true,
      enableTabKey : true,
      myCodeMirror: '',
      hostUrl:'https://www.tamilsneham.com/',
      fileManagerSettings: {
          enable: true,
          path: '/Pictures/Food',
          ajaxSettings: {
              url: this.hostUrl + 'api/FileManager/FileOperations',
              getImageUrl: this.hostUrl + 'api/FileManager/GetImage',
              uploadUrl: this.hostUrl + 'api/FileManager/Upload',
              downloadUrl: this.hostUrl + 'api/FileManager/Download'
          }
      },
      quickToolbarSettings: {
          table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles'],
          showOnRightClick: true,
      },
      toolbarSettings: {
          items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'SuperScript', 'SubScript', '|',
          'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
          'LowerCase', 'UpperCase', '|',
          'Formats', 'Alignments', '|', 'NumberFormatList', 'BulletFormatList', '|',
          'Outdent', 'Indent', '|', 'CreateLink', 'Image', 'FileManager', 'Video', 'Audio', 'CreateTable', '|', 'FormatPainter', 'ClearFormat',
          '|', 'EmojiPicker', 'Print', '|',
          'SourceCode', 'FullScreen', '|', 'Undo', 'Redo']
      },
      value:'TEast',
      activityList: [],
      showPopup: false, // Flag to show/hide the popup
      isEditing: false, // Flag to determine if the popup is in edit mode
      nameError: '',
      eventDateError: '',
      lastDateError: '',
      instructionError: '',
      inactiveMessageError:'',
      activityNameError:'',
      activityName:'',
      activityDate:'',
      activityDateError:'',
      RSVPTypeError:'',
      isValid: true,
      rsvp: {
        name: '',
        eventDate: '',
        eventDateTimestamp: '',
        lastDate: '',
        freeEvent: false,
        entryFeeAdultsMember: 0,
        entryFeeKidsMember: 0,
        entryFeeAdultsNonmember: 0,
        entryFeeKidsNonmember: 0,
        kidsAgeFrom: 0,
        kidsAgeTo: 0,
        instructions: '',
        contactNumber2: '',
        active:true,
        RSVPType:'General Event',
      }
    };
  },
  provide: {
    grid: [Sort, VirtualScroll, PdfExport, ExcelExport, Page, Toolbar, Filter, CommandColumn],
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'activityList' property
    this.fetchactivityList();
  },
  methods: {
    async commandClick(args) {
        const db = firebase.firestore();

        let command = args.target.ej2_instances[0].properties.cssClass;

        if(command.includes('delete')){

          this.deleteItem(args.rowData.activityId);

          // this.$router.push('/ActivityAdmin/' + args.rowData.activityId);

        }
    },
    fetchactivityList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('activity')
        .where('status', '==', '1')
        .orderBy('activityDateTimestamp', 'desc')
        .get()
        .then(querySnapshot => {
          const activityList = [];
          querySnapshot.forEach(doc => {
            // Extract the RSVP data from each document
            const activity = {
              activityId: doc.id,
              ...doc.data()
            };
            activityList.push(activity);
          });
          this.activityList = activityList;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setEvent(value){

      this.rsvp.RSVPType = value;

    },
    openCreatePopup() {
      // this.isEditing = false; // Set the popup mode to create
      // this.rsvp.RSVPType = 'General Event';
      // this.showPopup = true; // Show the popup
      this.$router.push('/ActivityAdmin');
    },
    openEditPopup(rsvp) {
      this.isEditing = true; // Set the popup mode to edit
      this.rsvp = { ...rsvp }; // Copy the RSVP object to the editing form
      this.showPopup = true; // Show the popup
    },
    closePopup() {
      this.showPopup = false; // Hide the popup
      this.isEditing = false; // Reset the edit mode
      this.rsvp = {}; // Reset the RSVP object
    },
    submitRSVP() {
      // Save the RSVP to Firestore
      const db = firebase.firestore();

      this.nameError = '';
      this.eventDateError = '';
      this.lastDateError = '';
      this.instructionError = '';
      this.isValid = true;

      if (this.rsvp.name == '') { this.nameError = 'Event Name required'; this.isValid = false }
      if (this.rsvp.eventDate == '') { this.eventDateError = 'Event Date required'; this.isValid = false }
      if (this.rsvp.lastDate == '') { this.lastDateError = 'Last Date required'; this.isValid = false }
      if (this.rsvp.instructions == '') { this.instructionError = 'Instructions required'; this.isValid = false }
      if (this.rsvp.RSVPType == '') { this.RSVPTypeError = 'RSVP Type required'; this.isValid = false }

      if (!this.isValid) { return; }

      this.rsvp.eventDateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(this.rsvp.eventDate));

      if (this.isEditing) {
        // Update existing RSVP
        db.collection('rsvp')
          .doc(this.rsvp.rsvpId)
          .update(this.rsvp)
          .then(() => {
            console.log('RSVP updated successfully!');
            this.closePopup();
            this.fetchactivityList();
          })
          .catch(error => {
            console.error('Error updating RSVP:', error);
          });
      } else {
        // Create new RSVP
        db.collection('rsvp')
          .add(this.rsvp)
          .then(() => {
            console.log('RSVP created successfully!');
            this.closePopup();
            this.fetchactivityList();
          })
          .catch(error => {
            console.error('Error creating RSVP:', error);
          });
      }
    },
    setFreeEvent(value) {

      if(typeof(value) == 'object'){return;}

      this.rsvp.freeEvent = value;

    },
    setInactive(value){
      if(typeof(value) == 'object'){return;}

      this.rsvp.active = value;
    },
    async deleteItem(activityId) {

      const db = firebase.firestore();

      await db.collection('activity')
        .doc(activityId)
        .delete()
        .then(() => {
          console.log('Activity deleted successfully!');
        })
        .catch(error => {
          console.error('Error deleting RSVP:', error);
        });

        this.fetchactivityList();

    },
    rowDataBound: function(args) {

      if (args.data['entry']) {
          args.row.classList.add('entryBackgroud');
      } 

      if (args.data['membershipId']) {
          args.row.classList.add('blueText');
      } 
    },
    toolbarClick(args) {
        switch (args.item.id) {
           
            case 'registrationList_excelexport':
              
                this.$refs.registrationList.excelExport(this.getExcelExportProperties());
                break;
            case 'registrationList_pdfexport':
                this.$refs.registrationList.getColumns()[6].visible = false;
                this.$refs.registrationList.getColumns()[9].visible = false;
                this.$refs.registrationList.pdfExport(this.getPdfExportProperties());
                break;
        }
        
    },
    pdfExportComplete(args) {
      this.$refs.registrationList.getColumns()[6].visible = true;
      this.$refs.registrationList.getColumns()[9].visible = true;
    },
    getDate(){
        let date = '';
        date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
        return date += '/' + ((new Date()).getFullYear().toString());
    },
    getExcelExportProperties: function() {
        return {
            header: {
                headerRows: 7,
                rows: [
                    {
                        index: 1,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 5, value: this.selectedRSVPName, style: { fontColor: '#C25050', fontSize: 25, hAlign: 'Center', bold: true } }
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 2, value: 'Tamil sneham', style: { fontColor: '#C67878', fontSize: 15, bold: true } },
                            { index: 5, value: 'DATE', style: { fontColor: '#C67878', bold: true }, width: 150 }
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, colSpan: 2, value: 'Tampa' },
                            { index: 5, value: this.getNow(false), width: 150 }
                        ]
                    },
                    // {
                    //     index: 5,
                    //     cells: [
                    //         { index: 1, colSpan: 2, value: 'Tel +1 888.936.8638 Fax +1 919.573.0306' },
                    //         { index: 4, value: 'CUSOTMER ID', style: { fontColor: '#C67878', bold: true } },
                    //         { index: 5, value: 'TERMS', width: 150, style: { fontColor: '#C67878', bold: true } }
                    //     ]
                    // },
                    // {
                    //     index: 6,
                    //     cells: [
                    //         { index: 4, value: 564 },
                    //         { index: 5, value: 'Net 30 days', width: 150 }
                    //     ]
                    // }
                ]
            },

            footer: {
                footerRows: 5,
                rows: [
                    /* tslint:disable-next-line:max-line-length */
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] },
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] }
                ]
            },
            
            fileName: "exceldocument.xlsx"
        };
    },
    getPdfExportProperties: function() {
        return {
          pageOrientation: 'Landscape',
            header: {
                fromTop: 0,
                height: 120,
                contents: [
                    {
                        type: 'Text',
                        value: this.selectedRSVPName,
                        position: { x: 280, y: 0 },
                        style: { textBrushColor: '#C25050', fontSize: 25 },
                    },
                    {
                        type: 'Text',
                        value: 'PRINT DATE',
                        position: { x: 500, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: this.getNow(false),
                        position: { x: 600, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: 'Tamil sneham',
                        position: { x: 20, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 20 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '2501 Aerial Center Parkway',
                    //     position: { x: 20, y: 65 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                    // {
                    //     type: 'Text',
                    //     value: 'Tel +1 888.936.8638 Fax +1 919.573.0306',
                    //     position: { x: 20, y: 80 },
                    //     style: { textBrushColor: '#000000', fontSize: 11 }
                    // },
                ]
            },
            footer: {
                fromBottom: 50,
                height: 100,
                contents: [
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 250, y: 20 },
                        style: { textBrushColor: '#C67878', fontSize: 14 }
                    },
                    // {
                    //     type: 'Text',
                    //     value: '! Visit Again !',
                    //     position: { x: 300, y: 45 },
                    //     style: { textBrushColor: '#C67878', fontSize: 14 }
                    // }
                ]
            },
            
            fileName: "pdfdocument.pdf"
        };
    } 
  }

};
</script>

<style>
.table {
  background-color: white !important;
}

.popup {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup h2 {
  margin-top: 0;
}
</style>